var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.getClass("ripple") }, [
    _c("div", { class: _vm.getClass("ripple__circle") }),
    _vm._v(" "),
    _c("div", {
      class:
        _vm.getClass("ripple__circle ") + _vm.getClass("ripple__inner-circle"),
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }