//#region Imports Packages and Libs

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { MessageStructureComponent, MessageContentSettings } from "../types";
import { __vueEvent } from "../constant";
import { getUniqId } from "../helpers";
import { IFormInput } from "../interfaces/form_input";
import { store } from "../store/store";
import { STORE_MUTATION } from "../enums/store_mutation";

//#endregion

//#region Enums

/**
 * Enum for Modal Load Behavior
 */
enum LoadBehaviorEnum {
    PLACEHOLDER_ONLY = 'placeholder_only',
    AUTO_OPEN_WITH_PLACEHOLDER = 'auto_open_with_placeholder',
    AUTO_OPEN_WITHOUT_PLACEHOLDER = 'auto_open_without_placeholder',
}

//#endregion

//#region Define the component in class-style

declare var marked;
@Component({
    props: {
        component: Object,
        structure: Object,
        messageContentsettings: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: mapState(['prefixClassName'])
})
export default class FormInput extends Vue {
    //#region Class properties will be component data

    component!: MessageStructureComponent;
    messageContentsettings!: MessageContentSettings
    fieldId = getUniqId()
    fieldValue: string = '';
    timeoutId: ReturnType<typeof setTimeout> | undefined;

    //#endregion

    //#region Modal Lifecycle Hooks

    /**
     * This computed prop is used to get the icon image style.
     */
    get rows() {
        try {
            const rows = (this.component as any).rows;

            // If rows is undefined, null, empty string,
            // , not a number or parsed value is not a number, then return 1.
            if (!rows || isNaN(rows) || isNaN(parseInt(rows))) {
                return 1;
            }

            // If rows is negative number, 1, or 0, then return 1.
            return (!rows || rows < 2) ? 1 : Number(rows);
        } catch (ex) {
            return 1;
        }
    }

    /**
     * The function `onFieldValueChanged` updates the form input value and commits it to the store.
     * @param value - The value parameter represents the new value of the field that has been changed.
     */
    onFieldValueChanged(value) {
        try {
            const input: IFormInput = {
                fieldId: this.fieldId,
                fieldName: this.component.name || 'field_name',
                fieldValue: value || '',
            };

            store.commit(STORE_MUTATION.SetFormInput, input);
        } catch (ex) {
            console.log("[form_input.ts] An error has occurred \n-> Inside onFieldValueChanged -> Error: ", ex);
        }
    }

    @Watch('fieldValue', { immediate: true })
    onPropertyChanged(value: string) {
        try {
            // Clear any previous timer
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            // Set a new timer to run the logic after 500ms
            this.timeoutId = setTimeout(() => {
                this.onFieldValueChanged(value);
            }, 500);

        } catch (ex) {
            console.log("[form_input.ts] An error has occurred \n-> Inside onPropertyChanged -> Error: ", ex);
        }
    }

    //#endregion

}

//#endregion