import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { STORE_MUTATION } from "../enums/store_mutation";
import { __oneMinute, __vueEvent, __onTransitionEnd, __hideInputContainerAlways } from "../constant";
import { onExceptionOccured } from "../helpers/on_exception_occured";
import { isMobile } from "../helpers/is_mobile";
import { EVENTS } from "../enums/events";
import { IConfig } from "../interfaces/config";
import InfoPanel from "../components/info_panel.vue";
import { addUserMessage, sendAdminMessage, StorageHelper } from "../helpers";
import { getQueryStringValue } from "../helpers/get_querystring_value";
import { mapState } from "vuex";
import { MessageContent } from "../types";
import { IMessage } from "../interfaces";
import { MESSAGE_TYPE, SIGNALR_STATUS, ADMIN_TRIGGER, ADMIN_MESSAGE_TYPE } from "../enums";
import { stringify } from "querystring";
import { ChatService } from "../service/chat_service";
import { store } from "../store/store";
import { Util } from "../util";
import { UserService } from '../service/user_service';

@Component({
    components: { InfoPanel },
    computed: mapState(['showInfoPanel', 'messages', 'disableInput', 'isAdminMode'])
})
export default class Input extends Vue {

    inputData = "";
    inputPlaceHolder = "";
    showCameraBtn = false;
    showInfoBtn = false;

    lastMessageTime = new Date("10/02/1998");

    firstMessageTime: Date;

    typingMessageSecondsIntervalLocalTimestamp = 0;

    isMaintainState = false;

    // We'll be resizing the textarea based on it's scrollHeight,
    // so starting with nothing is as good a baseline as any.
    inputHeight: string = 'auto';
    inputOverflow: string = 'hidden';

    windowInnerHeight = window.innerHeight;
    windowinnerHeightChange = 0;
    isMessageSentButtonClicked = false;

    // We have some padding on top & bottom and 1 rem = 16px
    inputPadding: number = 0.65 * 16;

    created() {
        this.autoResize = Util.throttle(this.autoResize, 80);
    }

    mounted() {
        this.listenEvents();
    }

    @Watch('inputData')
    onPropertyChanged(value: string, oldValue: string) {
        this.$nextTick(() => {
            this.autoResize();
        });
    }

    @Watch('disableInput')
    onDisableInputPropertyChanged(value) {
        try {
            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles) {
                // Check if the `--chatFooterDefaultHeight` var is present
                let chatFooterHeight = compStyles.getPropertyValue('--chatFooterDefaultHeight');
                if (!Util.isNullOrEmpty(chatFooterHeight)) {
                    document.documentElement.style.setProperty('--chatFooterDefaultHeight', !!value ? '0px' : '45px');
                }
            }
            if (!value) {
                // Incase, "Hide Input Container" option was true and user holds the header to get the input container
                // then we need to trim the input placeholder text so that it doesn't go multi-line.
                this.$nextTick(() => {
                    this.inputPlaceHolder = this.inputPlaceHolder || '';
                    this.inputPlaceHolder = this.inputPlaceHolder.length > 0
                        ? this.getTrimmedPlaceHolderText(this.inputPlaceHolder) : 'Ask a question';
                });
            }

        } catch (ex) {
            var errorObj = { Page: 'footer.ts', MethodName: 'onDisableInputPropertyChanged', Message: ex.message };
            //console.log(errorObj);
        }
    }

    @Watch('isAdminMode')
    onIsAdminModePropertyChanged(value: string) {
        try {
            // SD-3578
            // Admin mode indicator. Once a client user is logged into the chat page in "Admin mode"
            // we need to persist some kind of visual indicator in the chat window to show the user they are in admin mode.
            if (!value) return;
            this.$nextTick(() => {
                this.inputData = "";
                this.inputPlaceHolder = this.inputPlaceHolder || '';
                this.inputPlaceHolder = `${this.inputPlaceHolder} *`;
            });
        } catch (ex) {
            console.log("[footer.ts] An error has occurred \n-> Inside onIsAdminModePropertyChanged -> Error: ", ex);
        }
    }

    // Add a computed property to get the new textarea height
    get inputStyle() {
        return {
            'min-height': this.inputHeight,
            'overflow': this.inputOverflow
        }
    }

    /**
     * This method is used to increase/decrease the height of the textarea,
     * based on the length of the text entered by the user.
     */
    autoResize() {
        try {
            // Actively perform a runtime check
            if (this.$refs.shadow && this.$refs.shadow instanceof HTMLElement) {
                const input = <HTMLElement>(this.$refs.shadow);
                this.inputHeight = 'auto';
                this.inputOverflow = 'hidden';

                let textareaMaxHeight = this.getTextareaMaxHeight();
                let footerHeight = 0;
                let scrollHeight = 0;

                // Autosize textarea with max N line limit, then show scrollbar
                if (input.scrollHeight != null) {
                    scrollHeight = input.scrollHeight + this.inputPadding;
                }
                if (scrollHeight <= textareaMaxHeight) {
                    footerHeight = scrollHeight;
                } else {
                    footerHeight = textareaMaxHeight;
                    this.inputOverflow = 'auto';

                    // This logic is used to keep overflow div scrolled to bottom as user types more text
                    let textarea = this.getTextareaControl(this.$refs.input);
                    if (textarea) textarea.scrollTop = scrollHeight;
                }
                this.inputHeight = `${footerHeight}px`;
                this.updateChatFooterHeight(`${footerHeight < 45 ? 45 : footerHeight}px`);
                this.onChatTextBoxFocus();
            }
        } catch (ex) {
            var errorObj = { Page: 'footer.ts', MethodName: 'autoResize', Message: ex.message };
            //console.log(errorObj);
        }
    }

    /**
     * This method is used to get the DOM node for the actual Textarea
     */
    getTextareaControl(input) {
        let control = null;
        try {
            if (input && input instanceof HTMLElement) {
                control = <HTMLElement>(input);
            }
        } catch (error) { }
        return control;
    }

    /**
     * This method is used to update the css variable named `--chatFooterHeight`,
     * so that we can properly position the tagline "Powered by Satisfi Labs"
     * based on the current height of the textarea and the footer.
     */
    updateChatFooterHeight(inputHeight: string) {
        try {
            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles) {
                // Check if the `--chatFooterHeight` var is present
                let chatFooterHeight = compStyles.getPropertyValue('--chatFooterHeight');
                if (!Util.isNullOrEmpty(chatFooterHeight)) {
                    document.documentElement.style.setProperty('--chatFooterHeight', inputHeight);
                }
            }
        } catch (ex) {
            var errorObj = { Page: 'footer.ts', MethodName: 'updateChatFooterHeight', Message: ex.message };
            //console.log(errorObj);
        }
    }

    /**
     * Get the Max Height for the textarea which is allowed, based on css variable `--textareaMaxLineLimit`
     */
    getTextareaMaxHeight() {
        let defaultHeight = 180;
        try {

            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles) {
                // Check if the `--textareaMaxLineLimit` var is present
                let textareaMaxLineLimit = compStyles.getPropertyValue('--textareaMaxLineLimit');
                if (!Util.isNullOrEmpty(textareaMaxLineLimit) && Number(textareaMaxLineLimit) > 0) {
                    defaultHeight = (Number(textareaMaxLineLimit) * 25) + this.inputPadding;
                }
            }

        } catch (ex) {
            var errorObj = { Page: 'footer.ts', MethodName: 'getTextareaMaxHeight', Message: ex.message };
            //console.log(errorObj);
        }
        return defaultHeight;
    }

    /**
     * This method is used to handle enter key on mobile logic
     */
    async onEnterPressHandler(evt) {
        try {

            // When a user is on desktop and presses enter, send the message.
            if (!isMobile.any()) {
                await this.sendMessage();
            } else {
                // When a user is on mobile and presses enter, add a new line.
                this.pasteIntoInput(evt.target, "\n");
                this.inputData = evt.target.value;
            }

        } catch (ex) {
            var errorObj = { Page: 'footer.ts', MethodName: 'onEnterPressHandler', Message: ex.message };
            //console.log(errorObj);
        }
    }

    pasteIntoInput(el, text: string) {
        try {
            //let el = this.getTextareaControl(this.$refs.input);
            if (el && typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
                var val = el.value;
                var selStart = el.selectionStart;
                el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd);
                el.selectionEnd = el.selectionStart = selStart + text.length;
            }

        } catch (ex) {
            var errorObj = { Page: 'footer.ts', MethodName: 'pasteIntoInput', Message: ex.message };
            //console.log(errorObj);
        }
    }

    // In case, we have large text for the placeholder, then we need to trim it
    // so that it is not displayed in multiline
    getTrimmedPlaceHolderText(originalText) {
        let formattedText = originalText;
        try {
            // Get width of footer textarea
            let textareaWidth = 0, textLength = 0;
            let textarea = this.getTextareaControl(this.$refs.input);
            if (textarea) {
                textareaWidth = textarea.offsetWidth;
                textLength = Math.floor((textareaWidth / 8));
                if (textLength > 0) {
                    formattedText = formattedText.slice(0, textLength);
                }
            }

        } catch (error) { }
        return formattedText;
    }

    listenEvents() {
        __vueEvent.$once(EVENTS.SetConfig, (config: IConfig) => {
            this.showCameraBtn = config.showCameraBtn;
            this.showInfoBtn = config.showInfoBtn;

            this.isMaintainState = config.maintainState;
            if (config.maintainState) {
                if (StorageHelper.get(__hideInputContainerAlways + this.$store.state.pageId) === "false") {
                    this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, false);
                } else if (StorageHelper.get(__hideInputContainerAlways + this.$store.state.pageId) === "true") {
                    this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, true);
                } else {
                    this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, config.hideInputContainer);
                }
            } else {
                this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, config.hideInputContainer);
                if (StorageHelper.get(__hideInputContainerAlways + this.$store.state.pageId)) {
                    StorageHelper.remove(__hideInputContainerAlways + this.$store.state.pageId);
                }
            }

            this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, config.hideInputContainer);
            this.$store.commit(STORE_MUTATION.SetDisableInput, config.hideInputContainer);

            // This is done so that when info and camera buttons are actually rendered
            // in the UI we can then get the actual width of the textarea and trim placeholder
            this.$nextTick(() => {
                this.inputPlaceHolder = config.inputPlaceHolder.length > 0
                    ? this.getTrimmedPlaceHolderText(config.inputPlaceHolder || '') : 'Ask a question';
            });

        }).$on(EVENTS.MessageFromMaintainState, this.checkTimeStampForNewMessage)
            .$on(EVENTS.NewMessageSent, this.checkTimeStampForNewMessage)
            .$on(EVENTS.PrependMessage, this.checkTimeStampForPrependMessage)
            .$on([EVENTS.CheckForDisableInput, EVENTS.NewMessage], (value: IMessage) => {
                this.checkforDisableInputSetting(value.contents);
            }).$on(EVENTS.ButtonClick, () => {
                if (this.$store.state.disableInput && !this.$store.state.defaulthideInputContainerSetting) {
                    this.$store.commit(STORE_MUTATION.SetDisableInput, false);
                }
            }).$once(SIGNALR_STATUS.Connected, this.checkInputFromUrl)
            .$on(EVENTS.FocusInputArea, this.focusInputArea)
            .$on(EVENTS.ClearAdminModeInput, this.clearAdminModeInput);

        try {
            let textarea = this.getTextareaControl(this.$refs.input);
            if (textarea) {
                textarea.addEventListener('keyup', (event: any) => {
                    try {
                        if (event.key === 'Tab' && store.state.enableMessageNavigation) {
                            textarea.classList.add(this.getClassName('keyboard-focus'));
                        }
                    } catch (ex2) {
                        //onExceptionOccured("textarea keyup event", ex2);
                    }
                });
                textarea.addEventListener('blur', () => {
                    textarea.classList.remove(this.getClassName('keyboard-focus'));
                });
            }
        } catch (ex) {
            //onExceptionOccured("textarea event listener", ex);
        }
    }

    /**
     * The function returns a class name by concatenating a prefix with a given value.
     * @param value - The value parameter is a string representing the class name that you want to
     * append to the prefixClassName.
     * @returns the concatenation of the value parameter and the prefixClassName property of the
     * store.state object.
     */
    getClassName(value) {
        return store.state.prefixClassName + value;
    }

    timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    focusInputArea() {
        const input = <HTMLInputElement>(this.$refs.input);
        if (!isMobile.any()) {
            // SD-1889
            // Focus to input element without scrolling the whole page.
            // This is mainly done for the Tiles.
            input.focus({ preventScroll: true });
        }
    }

    async sendMessage() {
        if (store.state.isAnchorTagClickedSametabNewtab && !store.state.requestResponseReceived) {
            store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
        }
        if (!store.state.requestResponseReceived) return;
        this.isMessageSentButtonClicked = true;
        let msg = this.inputData || '';

        // We need to decode the <> tags here first and also remove any script tags
        msg = Util.removeScriptTags(msg);
        this.inputData = msg;
        msg = Util.htmlEscape(msg);

        // Remove all new lines from start and end of string
        msg = msg.replace(/^\s+|\s+$/g, '');

        if (msg.trim().length > 0) {
            try {

                // SD-3578
                // Keyword capture at the input box in chat for Admin login
                const keywordsToCheck = [ADMIN_TRIGGER.Login, ADMIN_TRIGGER.Logout];
                const isKeywordPresent = this.checkKeywordPresence(msg.trim(), keywordsToCheck);

                // Check if the admin login modal is already open or not
                if (store.state.showAdminLoginModal) {
                    //console.log("Admin login modal is already open");
                    return;
                } else {
                    //console.log("Admin login modal is not open");
                    store.commit(STORE_MUTATION.SetShowAdminLoginModal, false);
                }

                // Check if the keyword is present in the input value
                // and if the chat page Admin mode flag is enabled and MFA is also enabled for the company
                if (isKeywordPresent && store.state.enableAdminMode && store.state.isMFAEnabled) {
                    // console.log("A matching string is present in the input value!");
                    // Perform any additional actions or logic here
                    await this.handleKeywordCapture(msg.trim());
                    await this.timeout(100);
                    this.autoResize();

                } else {
                    //console.log("No matching string is present in the input value.");
                    this.inputData = "";

                    // Call autoResize after 100ms, to reset the size of the footer if expanded
                    await this.timeout(100);
                    this.autoResize();

                    await addUserMessage(msg);
                }
            }
            catch (ex) {
                onExceptionOccured("sendMessage", ex);
            }
        }
        //focus input area
        this.focusInputArea();
    }

    /**
     * This method is used to handle the keyword capture at the input box in chat
     * @param msg - The input message from the user
     */
    async handleKeywordCapture(msg = "") {
        try {
            // console.log("handleKeywordCapture: " + msg);
            switch (msg.toLowerCase()) {
                case ADMIN_TRIGGER.Login:

                    // If the user is not logged in, then show the admin login modal
                    if (Util.isNullOrEmpty(store.state.authToken)) {

                        // We need to check if user is already logged in another tab or not
                        // If the user is already logged in another tab,
                        // then User should be auto-logged in.
                        const extUserId = new UserService().getExtUserId();
                        const deviceId = StorageHelper.get("browser_id") || "";
                        const botId = this.$store.state.extBotId || 0;
                        const pageId = this.$store.state.pageId || "";
                        const adminModeValue = StorageHelper.get(`swc_is_adm_${pageId}_${extUserId}`) || "";
                        const decodedString = window.atob( adminModeValue );
                        const isAdminModeEnabled = decodedString == "1";

                        if (isAdminModeEnabled) {
                            //console.log("User is already logged in another tab");
                            if (!Util.isNullOrEmpty(extUserId) && !Util.isNullOrEmpty(deviceId)
                                && !Util.isNullOrEmpty(botId) && isAdminModeEnabled) {
                                // Yes, we have some data saved in the local storage
                                // Make a call to the server to check if the user is actually logged in
                                const response = await new UserService().checkUserStatus(botId, deviceId, extUserId);
                                if (response && !response.Status) {
                                    store.commit(STORE_MUTATION.SetShowAdminLoginModal, true);
                                }
                            } else {
                                store.commit(STORE_MUTATION.SetShowAdminLoginModal, true);
                            }
                        } else {
                            //console.log("User is not logged in another tab");
                            store.commit(STORE_MUTATION.SetShowAdminLoginModal, true);
                        }
                    } else {
                        // If the user is already logged in, then clear the input box
                        this.inputData = "";
                    }

                    break;
                case ADMIN_TRIGGER.Logout:

                    // Send a message to the EBM to indicate that the user has logged out
                    // but we need to check first if the user is already logged in or not.
                    if (store.state.isAdminMode) {

                        store.commit(STORE_MUTATION.SetShowAdminLoginModal, true);
                        this.$nextTick(function () {
                            __vueEvent.$emit(EVENTS.UpdateAdminModalStep, 7); // 7 is the step number for LOGOUT_SUCCESS
                        });

                        try {
                            await this.logoutUserAll();
                        } catch (ex2) {
                            console.log("[footer.ts] An error has occurred \n-> Inside handleKeywordCapture -> Error: ", ex2);
                        }

                        // This is done to stop the API calls to auto-refresh of the JWT token
                        const refreshTask = store.state.refreshTask;
                        if (refreshTask) {
                            clearTimeout(refreshTask);
                            store.commit(STORE_MUTATION.SetRefreshTask, null);
                        }

                        // Set the is admin mode to true.
                        this.clearAdminModeDetails();
                        store.commit(STORE_MUTATION.SetIsAdminMode, false);
                        store.commit(STORE_MUTATION.SetAuthToken, "");
                    } else {
                        // SD-3647: Logout Confirmation
                        // If the user is not logged in, then process message like normal
                        this.inputData = "";
                        this.inputPlaceHolder = this.inputPlaceHolder.replace(/ \*$/, '');
                        await this.timeout(100);
                        this.autoResize();
                        await addUserMessage(msg);
                    }

                    break;
                default:
                    break;
            }

        } catch (ex) {
            console.log("handleKeywordCapture: " + ex);
        }
    }

    /**
     * This method is used to clear the admin mode details from the storage.
     */
    clearAdminModeDetails() {
        try {
            var encodedString = window.btoa( "0" );
            const pageId = store.state.pageId || "";
            const extUserId = new UserService().getExtUserId();
            StorageHelper.set(`swc_is_adm_${pageId}_${extUserId}`, encodedString);
        } catch (ex) {
            //console.log("[footer.ts] An error has occurred \n-> Inside clearAdminModeDetails -> Error: ", ex);
        }
    }

    /**
     * This method is used to clear the input box when the user logs out from the Admin mode.
     */
    clearAdminModeInput(){
        try {
            this.inputData = "";

            // Remove the space and "*" symbol at the end of the inputPlaceHolder
            this.inputPlaceHolder = this.inputPlaceHolder.replace(/ \*$/, '');
        } catch (ex) {
            console.log("clearAdminModeInput: " + ex);
        }
    }

    /**
     * The function `logoutUserAll` logs out the user from the application and sends an admin message
     * for logout.
     * @returns The function `logoutUserAll` returns an object with two properties: `apiResponse` and
     * `ebmResponse`.
     */
    async logoutUserAll() {
        // We are using two try-catch blocks here because we want to execute the second promise
        // even if the first promise fails.
        const response = { apiResponse: null, ebmResponse: null };
        try {
            const pageId = store.state.pageId || "";
            const extUserId = new UserService().getExtUserId();
            const deviceId = StorageHelper.get("browser_id") || "";
            response.apiResponse = await new UserService().userLogout(pageId, extUserId, deviceId);
            //console.log("Promise 1 resolved with value:", result1);
        } catch (ex1) {
            console.log("[footer.ts] An error has occurred \n-> Inside logoutUserAll > userLogout -> Error: ", ex1);
        }

        try {
            response.ebmResponse = await sendAdminMessage(ADMIN_MESSAGE_TYPE.Logout);
            //console.log("Promise 2 resolved with value:", result2);
        } catch (ex2) {
            console.log("[footer.ts] An error has occurred \n-> Inside logoutUserAll > sendAdminMessage -> Error: ", ex2);
        }

        // Return the response object
        return response;
    }

    /**
     * This method checks if the message contains any of the keywords
     * @param message - The input message from the user
     * @param keywordsToCheck - The keywords to check for
     */
    checkKeywordPresence(message = "", keywordsToCheck = []) {
        try {

            const inputText = message.toLowerCase();
            for (let i = 0; i < keywordsToCheck.length; i++) {
                if (inputText.includes(keywordsToCheck[i].toLowerCase())) {
                    return true;
                }
            }
            return false;

        } catch (ex) {
            console.log("checkKeywordPresence: " + ex);
            return false;
        }
    }

    sendTypingIndicator() {
        try {
            const input = <HTMLInputElement>(this.$refs.input);
            if (this.$store.state.messages.length > 1 && input.value.length > 0) {
                if (this.typingMessageSecondsIntervalLocalTimestamp == 0) {

                    console.log("Time interval for typing: " + new Date().getSeconds());

                    this.typingMessageSecondsIntervalLocalTimestamp = new Date().getTime();
                    new ChatService().typingIndicatorChat();
                }
                else if ((new Date().getTime() - this.typingMessageSecondsIntervalLocalTimestamp) > this.$store.state.typingMessageSecondsInterval) {

                    console.log("Time interval for typing: " + new Date().getSeconds());

                    new ChatService().typingIndicatorChat();
                    this.typingMessageSecondsIntervalLocalTimestamp = new Date().getTime();
                }
            }
        }
        catch (ex) {
            console.log("sendTypingIndicator: " + ex);
        }

    }

    checkforDisableInputSetting(value: MessageContent) {
        if (value.settings) {
            if (value.settings.hideInputContainerAlways === false || Number(value.settings.hideInputContainerAlways) === 0) {
                this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, false);
                if (this.isMaintainState) {
                    StorageHelper.set(__hideInputContainerAlways + this.$store.state.pageId, 'false');
                }
            } else if (value.settings.hideInputContainerAlways === true || Number(value.settings.hideInputContainerAlways) === 1) {
                this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, true);
                if (this.isMaintainState) {
                    StorageHelper.set(__hideInputContainerAlways + this.$store.state.pageId, 'true');
                }
            }
            if (!this.$store.state.defaulthideInputContainerSetting) {
                this.$store.commit(STORE_MUTATION.SetDisableInput, value.settings.hideInputContainer ? true : false);
            } else {
                if (value.settings.hideInputContainer === false || Number(value.settings.hideInputContainer) === 0) {
                    this.$store.commit(STORE_MUTATION.SetDisableInput, false);
                } else {
                    this.$store.commit(STORE_MUTATION.SetDisableInput, true);
                }
            }
        }
        else {
            this.$store.commit(STORE_MUTATION.SetDisableInput, this.$store.state.defaulthideInputContainerSetting);
        }
    }

    showInfo() {
        this.$store.commit(STORE_MUTATION.SetShowInfoPanel, true);
    }


    checkTimeStampForNewMessage(msg: IMessage) {
        if (msg.type === MESSAGE_TYPE.User) {
            const date = msg.contents.recordInfo.messageDateTime;
            const timeDiffInMilliSecond = date.getTime() - this.lastMessageTime.getTime();
            if (timeDiffInMilliSecond >= 60000) {
                if (this.lastMessageTime.getFullYear() === 1998) {
                    this.firstMessageTime = date;
                }
                date.setSeconds(0); date.setMilliseconds(0);
                this.lastMessageTime = date;
                this.$store.commit(STORE_MUTATION.AddGeneralTimeStamp, { date, msg });
            }
        }

    }

    onChatTextBoxFocus() {
        if (isMobile.Android()) {
            setTimeout(() => {
                this.windowinnerHeightChange = this.windowInnerHeight - window.innerHeight;
                document.getElementById('divChatBody').scrollTop =
                    (document.getElementById('divChatBody').scrollTop + this.windowinnerHeightChange);
            }, 300);
        }
    }

    onChatTextBoxBlur() {
        if (isMobile.Android()) {
            if (!this.isMessageSentButtonClicked)
                document.getElementById('divChatBody').scrollTop =
                    (document.getElementById('divChatBody').scrollTop - this.windowinnerHeightChange);
        }
    }

    checkTimeStampForPrependMessage(msg: IMessage, nextMsg: IMessage) {
        if (msg.type === MESSAGE_TYPE.User) {
            const date = msg.contents.recordInfo.messageDateTime;
            if (this.firstMessageTime && date.getTime()) {
                const timeDiffInMilliSecond = this.firstMessageTime.getTime() - date.getTime();
                if (timeDiffInMilliSecond >= 60000) {
                    this.firstMessageTime = date;
                    this.$store.commit(STORE_MUTATION.PrependTimeStamp, { date, spliceIndex: 0 });
                }
            }
        } else if (msg.contents.recordInfo.senderType === 'system') {
            const nextMsg: IMessage = this.$store.state.messages[1];
            if (nextMsg.type !== MESSAGE_TYPE.TimeStamp && nextMsg.contents.recordInfo.messageDateTime.getTime()) {
                this.firstMessageTime = nextMsg.contents.recordInfo.messageDateTime;
                this.$store.commit(STORE_MUTATION.PrependTimeStamp, { date: nextMsg.contents.recordInfo.messageDateTime, spliceIndex: 1 });
            }
        }

        //focus input area
        //this.focusInputArea();
    }

    checkInputFromUrl = () => {
        const inputFromUrl = getQueryStringValue('input').trim();
        if (inputFromUrl.length > 0) {
            const inputTime = getQueryStringValue('inputTime').trim();
            if (inputTime.length > 0) {
                const times = JSON.parse(StorageHelper.get('lastInputTime'));
                if (times && times[inputFromUrl] && times[inputFromUrl].length > 0) {
                    if (times[inputFromUrl] !== inputTime) {
                        this.sendMessageFromUrlInput(inputFromUrl);
                        StorageHelper.set('lastInputTime', JSON.stringify({ ...times, [inputFromUrl]: inputTime }));
                    }
                } else {
                    this.sendMessageFromUrlInput(inputFromUrl);
                    StorageHelper.set('lastInputTime', JSON.stringify({ ...times, [inputFromUrl]: inputTime }));
                }
            } else {
                this.sendMessageFromUrlInput(inputFromUrl);
            }
        }

        //focus input area
        //this.focusInputArea();
    }

    sendMessageFromUrlInput = (inputString: string) => {
        const isHiddenInput = getQueryStringValue('inputHidden').trim();
        isHiddenInput.length > 0 ? addUserMessage(inputString, true, isHiddenInput === '1') : addUserMessage(inputString);
    }
}
