import { STORE_MUTATION } from "../enums/store_mutation";
import { StateOption } from "../types/state_option";
import { IMessage } from "../interfaces/message";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";
import {
    MESSAGE_COMPONENT_TYPE,
    MESSAGE_STRUCTURE_TYPE,
    MESSAGE_TYPE
} from "../enums";
import { urlify } from "../helpers";
import { removeButtonsFromPayload } from "../helpers/remove_buttons_from_payload";
import { validateAndModify } from "../helpers/validate_and_modify";
import { MessageContent, MessageOptions, MessageStructure } from "../types";
import { getTimeStamp } from "../helpers";
import { IFormInput } from "../interfaces/form_input";

export const mutations = {
    [STORE_MUTATION.AddMessage]: (state: StateOption, value: IMessage, isWelcome: boolean = false) => {
        try {
            if (state.messages.length > 1) {
                __vueEvent.$emit(EVENTS.ScrollChatToBottom, false);
            }
            __vueEvent.$emit(EVENTS.RemoveAllButtons);

            console.log("1");
            value = validateAndModify(value);
            console.log("Value:" + JSON.stringify(value));

            if (isWelcome) {
                state.messages = [value];
            }
            else {
                // Check if maintain state is on
                if (!state.maintainState) {
                    state.messages.push(value);
                } else {

                    /**
                     * Maintain state ON logic, as discussed on skype
                     *   1. Fresh Page show welcome message
                     *   2. If some chat which is less then 25 no of chat then show welcome message
                     *   3. if chat is more then 25 then no need to show welcome message
                     */

                    // Check if it is a welcome message,
                    // if true add the welcome message at the top of the array
                    let isWelcomeMessage = false;
                    let messageCacheId = "";
                    if (value && value.contents && value.contents.recordInfo && value.contents.recordInfo.messageCacheId
                        && value.contents.recordInfo.messageCacheId === "1") {
                        isWelcomeMessage = true;
                        messageCacheId = "1";
                    }

                    // Check if the welcome message is already in the store
                    const MAX_MESSAGES = 25;
                    const isMessageAlreadyInStore = state.messages.some(m => m.id === messageCacheId);
                    if (isWelcomeMessage) {
                        if (state.messages.length === 0 || (state.messages.length < MAX_MESSAGES - 1 && !isMessageAlreadyInStore)) {
                            // Add the welcome message to the top of the chat screen
                            state.messages.unshift(value);
                        }
                    } else {
                        // Check if we have a message from maintain state
                        // meaning from the `fetchMaintainStateMessages` api call
                        if (state.isMaintainStateMessages && state.messages.length >= MAX_MESSAGES) {
                            // Remove the welcome message if present
                            state.messages = state.messages.filter(message => message.id !== "1");
                        }

                        // Add the message to the chat screen
                        state.messages.push(value);
                    }
                }
            }

            console.log("Pushed");
            if (value && value.contents && value.contents.settings && value.contents.settings.clearScreen) {
                __vueEvent.$emit(EVENTS.ScrollChatToTop);
            } else if (state.messages.length > 1) {
                console.log("scroll to New Message Top");
                __vueEvent.$emit(EVENTS.ScrollToNewMessageTop);
            }
            console.log("New Message");
            __vueEvent.$emit(EVENTS.NewMessage, value);

            // Check if the new message is a modal response
            if (value && value.contents && value.contents.structures && value.contents.structures.length > 0
                && value.contents.structures[0].type === MESSAGE_STRUCTURE_TYPE.Modal) {

                // Find the new message in the state
                const msg = state.messages.find(message => message.id === value.id);
                if (msg != null && msg.contents && msg.contents.structures && msg.contents.structures.length > 0) {
                    const structure = msg.contents.structures[0];
                    //structure.showModal = structure.settings.autoOpen;
                    structure.showModal = true;
                }
            }

        } catch (ex) {
            console.log("invalid message", value.contents);
        }
    },
    [STORE_MUTATION.UpdateMessageStatus]: (
        state: StateOption,
        value: IMessage
    ) => {
        const msg = state.messages.find(message => message.id === value.id);
        if (msg != null) {
            msg.status = value.status;
        }
    },
    [STORE_MUTATION.UpdateMessageCacheID]: (
        state: StateOption,
        value: IMessage
    ) => {
        const msg = state.messages.find(message => message.id === value.id);
        if (msg != null) {
            msg.id = value.newId;
        }
    },
    [STORE_MUTATION.UpdateMessageStructure]: (
        state: StateOption,
        value: IMessage
    ) => {
        const msg = state.messages.find(message => message.id === value.id);
        if (msg != null) {
            msg.buttonMessageStructure = value.buttonMessageStructure;
        }
    },
    [STORE_MUTATION.SetIsShowHeaderInIframe]: (state: StateOption, value: boolean) => {
        state.isShowHeaderInIframe = value;
    },
    [STORE_MUTATION.SetIsHideTitleBar]: (state: StateOption, value: boolean) => {
        state.isHideTitleBar = value;
    },
    [STORE_MUTATION.SetUserId]: (state: StateOption, value: string) => {
        state.userId = value;
    },
    [STORE_MUTATION.SetUserIdSourceTypeId]: (state: StateOption, value: number) => {
        state.userIdSourceTypeId = value;
    },
    [STORE_MUTATION.SetIsBrowseUrlOnSametab]: (state: StateOption, value: boolean) => {
        state.isBrowseUrlOnSametab = value;
    },
    [STORE_MUTATION.SetIsAnchorTagClickedSametabNewtab]: (state: StateOption, value: boolean) => {
        state.isAnchorTagClickedSametabNewtab = value;
    },
    [STORE_MUTATION.SetIsConfigLoaded]: (state: StateOption, value: boolean) => {
        state.isConfigurationLoaded = value;
    },
    [STORE_MUTATION.SetBotId]: (state: StateOption, value: string) => {
        state.extBotId = Number(value);
    },
    [STORE_MUTATION.SetCompanyId]: (state: StateOption, value: string) => {
        state.companyId = Number(value);
    },
    [STORE_MUTATION.SetCompanyToken]: (state: StateOption, value: string) => {
        state.companyToken = value;
    },
    [STORE_MUTATION.SetSendMsgUrl]: (state: StateOption, value: string) => {
        state.sendMsgUrl = value;
    },
    [STORE_MUTATION.SetMaintainState]: (state: StateOption, value: boolean) => {
        state.maintainState = value;
    },
    [STORE_MUTATION.SetMessages]: (state: StateOption, value: IMessage[]) => {
        state.messages = value;
    },
    [STORE_MUTATION.SetShowInfoPanel]: (state: StateOption, value: boolean) => {
        state.showInfoPanel = value;
    },
    [STORE_MUTATION.SetDisableInput]: (state: StateOption, value: boolean) => {
        state.disableInput = value;
    },
    [STORE_MUTATION.SetDefaulthideInputContainerSetting]: (state: StateOption, value: boolean) => {
        state.defaulthideInputContainerSetting = value;
    },
    [STORE_MUTATION.SetErrorMessage]: (state: StateOption, value: string) => {
        state.errorMessage = value;
    },
    [STORE_MUTATION.SetTypingMessageSecondsInterval]: (state: StateOption, value: number) => {
        state.typingMessageSecondsInterval = value;
    },
    [STORE_MUTATION.SetRetryWarningCount]: (
        state: StateOption,
        value: number
    ) => {
        state.retryWarningCount = value;
    },
    [STORE_MUTATION.SetRetryInterval]: (state: StateOption, value: number) => {
        state.retryInterval = value;
    },
    [STORE_MUTATION.SetFocusedWindow]: (state: StateOption, value: boolean) => {
        state.focusedWindow = value;
    },
    [STORE_MUTATION.SetIsSignalRConnected]: (state: StateOption, value: boolean) => {
        state.isSignalRConnected = value;
    },
	[STORE_MUTATION.SetClientVersion]: (state: StateOption, value: string) => {
        state.clientVersion = value;
    },
	[STORE_MUTATION.SetMinimumClientVersion]: (state: StateOption, value: string) => {
        state.minimumClientVersion = value;
    },
    [STORE_MUTATION.SetShowTypingIndicator]: (
        state: StateOption,
        value: boolean
    ) => {
        state.showTypingIndicator = value;
        if (state.messages.length > 1) {
            __vueEvent.$emit(EVENTS.ScrollChatToBottom);
        }

        // If the value is true, which means we are going to show the typing indicator,
        // then we need to hide any open processing messages.
        if (value) {
            state.showProcessingMessage = false;
            state.processingDisplayIcon = "";
            state.processingDisplayText = "";
        }
    },
    [STORE_MUTATION.SetShowProcessingMessage]: (state: StateOption, value: boolean) => {
        state.showProcessingMessage = value;
        if (state && state.messages && state.messages.length > 1) {
            __vueEvent.$emit(EVENTS.ScrollChatToBottom);
        }
    },
    [STORE_MUTATION.SetProcessingDisplayIcon]: (state: StateOption, value: string) => {
        state.processingDisplayIcon = value;
    },
    [STORE_MUTATION.SetProcessingDisplayText]: (state: StateOption, value: string) => {
        state.processingDisplayText = value;
    },

    [STORE_MUTATION.SetSatisfiURL]: (state: StateOption, value: string) => {
        state.satisfiURL = value;
    },

    // SD-3460: New mutations for the Admin Login and MFA
    [STORE_MUTATION.SetWebApiAddress]: (state: StateOption, value: string) => {
        state.webApiAddress = value;
    },
    [STORE_MUTATION.SetDashboardAddress]: (state: StateOption, value: string) => {
        state.dashboardAddress = value;
    },
    [STORE_MUTATION.SetAttemptNoofInvalidCode]: (state: StateOption, value: number) => {
        state.attemptNoofInvalidCode = value;
    },
    [STORE_MUTATION.SetAttemptNoofResendCode]: (state: StateOption, value: number) => {
        state.attemptNoofResendCode = value;
    },
    [STORE_MUTATION.SetMFAExpiration]: (state: StateOption, value: number) => {
        state.mfaExpiration = value;
    },
    [STORE_MUTATION.SetMFAEnableDateTime]: (state: StateOption, value: string) => {
        state.mfaEnableDateTime = value;
    },
    [STORE_MUTATION.SetAuthToken]: (state: StateOption, value: string) => {
        state.authToken = value;
    },
    [STORE_MUTATION.SetAuthTokenExpiration]: (state: StateOption, value: number) => {
        state.authTokenExpiration = value;
    },
    [STORE_MUTATION.SetIsMFAEnabled]: (state: StateOption, value: boolean) => {
        state.isMFAEnabled = value;
    },
    [STORE_MUTATION.SetRefreshTask]: (state: StateOption, value: any) => {
        state.refreshTask = value;
    },

    [STORE_MUTATION.SetAWSServerURL]: (state: StateOption, value: string) => {
        state.AWSServerURL = value;
    },

    [STORE_MUTATION.SetAWSThemeFilePath]: (state: StateOption, value: string) => {
        state.AWSThemeFilePath = value;
    },

    [STORE_MUTATION.SetAWSImageLogoPath]: (state: StateOption, value: string) => {
        state.AWSImageLogoPath = value;
    },

    [STORE_MUTATION.SetAWSPopupConfigPath]: (state: StateOption, value: string) => {
        state.AWSPopupConfigPath = value;
    },
    [STORE_MUTATION.SetHeaderImage]: (state: StateOption, value: string) => {
        state.headerImage = value;
    },
    [STORE_MUTATION.SetBackgroundImage]: (state: StateOption, value: string) => {
        state.backgroundImage = value;
    },
    [STORE_MUTATION.SetHeaderImageDestinationURL]: (state: StateOption, value: string) => {
        state.headerImageDestinationURL = value;
    },

    [STORE_MUTATION.RemoveAllButtons]: (state: StateOption, param: { msgIndex: number, structureIndex: number, compIndex: number }) => {
        let msgIndex = state.messages.length - 1;
        if (msgIndex < 0) {
            return;
        }
        if (state.messages[msgIndex].contents.structures.length > 0) {
            switch (state.messages[msgIndex].contents.structures[0].type) {
                case MESSAGE_STRUCTURE_TYPE.Time:
                case MESSAGE_STRUCTURE_TYPE.FetchMsg:
                    msgIndex = --msgIndex;
            }
            state.messages[msgIndex] = removeButtonsFromPayload(
                state.messages[msgIndex]
            );
        }
        //state.messages[msgIndex].contents.quickReplies = [];
    },
    [STORE_MUTATION.SetTypingType]: (state: StateOption, value: number) => {
        state.typingType = value;
    },
    [STORE_MUTATION.SetIsUserHasAvatar]: (state: StateOption, value: boolean) => {
        state.isUserHasAvatar = value;
    },
    [STORE_MUTATION.SetIsBotHasAvatar]: (state: StateOption, value: boolean) => {
        state.isBotHasAvatar = value;
    },
    [STORE_MUTATION.SetLastFocusableElement]: (state: StateOption, value: HTMLElement) => {
        state.lastFocusableElement = value;
    },
    [STORE_MUTATION.SetConvId]: (state: StateOption, value: string) => {
        state.convId = value;
    },
    [STORE_MUTATION.SetPageId]: (state: StateOption, value: string) => {
        state.pageId = value;
    },
    [STORE_MUTATION.SetPopupId]: (state: StateOption, value: string) => {
        state.popupId = value;
    },
    [STORE_MUTATION.SetPageReferralUrl]: (state: StateOption, value: string) => {
        state.pageReferralUrl = value;
    },
    [STORE_MUTATION.SetParentSiteUrl]: (state: StateOption, value: string) => {
        state.parentSiteUrl = value;
    },
    [STORE_MUTATION.SetParentSiteReferralUrl]: (state: StateOption, value: string) => {
        state.parentSiteReferralUrl = value;
    },
    [STORE_MUTATION.SetRequestResponseReceived]: (state: StateOption, value: boolean) => {
        state.requestResponseReceived = value;
    },
    [STORE_MUTATION.SetLastMessageId]: (state: StateOption, value: number) => {
        state.lastMessageId = Number(value);
    },
    [STORE_MUTATION.SetUserLat]: (state: StateOption, value: string) => {
        state.userLat = value;
    },
    [STORE_MUTATION.SetUserLng]: (state: StateOption, value: string) => {
        state.userLng = value;
    },
    [STORE_MUTATION.SetLocationBlocked]: (state: StateOption, value: boolean) => {
        state.locationBlocked = value;
    },
    [STORE_MUTATION.SetShouldShowBanner]: (
        state: StateOption,
        value: boolean
    ) => {
        state.shouldShowBanner = value;
    },
    [STORE_MUTATION.SetShowWelcomeBanner]: (state: StateOption, value: boolean) => {
        state.showWelcomeBanner = value;
    },
    [STORE_MUTATION.SetIsNewMessageProcessing]: (state: StateOption, value: boolean) => {
        state.isNewMessageProcessing = value;
    },
    [STORE_MUTATION.SetBannerText]: (state: StateOption, value: string) => {
        state.bannerText = value
    },

    [STORE_MUTATION.AddGeneralTimeStamp]: (state: StateOption, payload) => {
        const message = {
            type: MESSAGE_TYPE.TimeStamp,
            id: payload.msg.id + MESSAGE_STRUCTURE_TYPE.Time,
            contents: {
                structures: [
                    {
                        type: MESSAGE_STRUCTURE_TYPE.Time,
                        components: [
                            {
                                content: getTimeStamp(payload.date),
                                type: MESSAGE_COMPONENT_TYPE.Text
                            }
                        ]
                    }
                ]
            }
        };
        state.messages.splice(state.messages.length - 1, 0, message);
        if (state.messages.length > 1) {
            __vueEvent.$emit(EVENTS.ScrollChatToBottom);
        }
    },
    [STORE_MUTATION.AddFetchMsgStamp]: (state: StateOption, content: string) => {
        state.messages.splice(0, 0, {
            type: MESSAGE_TYPE.TimeStamp,
            id: STORE_MUTATION.AddFetchMsgStamp,
            contents: {
                structures: [
                    {
                        type: MESSAGE_STRUCTURE_TYPE.FetchMsg,
                        components: [
                            {
                                content: content,
                                type: MESSAGE_COMPONENT_TYPE.Text
                            }
                        ]
                    }
                ]
            }
        });
    },
    [STORE_MUTATION.RemoveStampMessage]: (state: StateOption) => {
        const index = state.messages.findIndex(
            qry => qry.id === STORE_MUTATION.AddFetchMsgStamp
        );
        state.messages.splice(index, 1);
    },
    [STORE_MUTATION.SetIsAppInAdaMode]: (state: StateOption, value: boolean) => {
        state.isAppInAdaMode = value;
    },
    [STORE_MUTATION.ClearScreen]: (state: StateOption) => {
        state.messages = [];
    },
    [STORE_MUTATION.RemoveMessage]: (state: StateOption, messageId: string) => {
        var index = state.messages.findIndex(qry => qry.id === messageId);
        if (index >= 0) {
            state.messages.splice(index, 1);
        }
    },
    [STORE_MUTATION.PrependMessages]: (state: StateOption, messages: IMessage[]) => {
        messages.forEach(function (message, i) {
            state.messages.splice(0, 0, message);
            __vueEvent.$emit(EVENTS.PrependMessage, message, messages[i + 1]);
        });
    },
    [STORE_MUTATION.PrependTimeStamp]: async (state: StateOption, payload) => {
        const message = {
            type: MESSAGE_TYPE.TimeStamp,
            contents: {
                structures: [
                    {
                        type: MESSAGE_STRUCTURE_TYPE.Time,
                        components: [
                            {
                                content: getTimeStamp(payload.date),
                                type: MESSAGE_COMPONENT_TYPE.Text
                            }
                        ]
                    }
                ]
            }
        };
        state.messages.splice(payload.spliceIndex, 0, message);
        // __vueEvent.$emit(EVENTS.PrependMessage, message);
    },
	[STORE_MUTATION.SetIsTilesOpenedInIframe]: (state: StateOption, value: boolean) => {
        state.isTilesOpenedInIframe = value;
    },
    [STORE_MUTATION.SetCFHeader]: (state: StateOption, value: string) => {
        state.cfHeader = value;
    },
    [STORE_MUTATION.SetShowAdminLoginModal]: (state: StateOption, value: boolean) => {
        state.showAdminLoginModal = value;
    },
    [STORE_MUTATION.SetIsAdminMode]: (state: StateOption, value: boolean) => {
        state.isAdminMode = value;
    },
    [STORE_MUTATION.SetEnableAdminMode]: (state: StateOption, value: boolean) => {
        state.enableAdminMode = value;
    },
    [STORE_MUTATION.SetShowModal]: (state: StateOption, value: IMessage) => {
        try {

            const msg = state.messages.find(message => message.id === value.id);
            if (msg != null && msg.contents && msg.contents.structures && msg.contents.structures.length > 0) {
                const structure = msg.contents.structures[0];

                // Get the value for showModal passed as IMessage
                let showModal;
                if (value && value.contents && value.contents.structures && value.contents.structures.length > 0) {
                    showModal = value.contents.structures[0].showModal;
                }

                structure.showModal = showModal;
            }

        } catch (ex) {
            console.log("[mutations.ts] An error has occurred \n-> Inside STORE_MUTATION.SetShowModal -> Error: ", ex);
        }
    },
    [STORE_MUTATION.SetIsTermsConsentEnabled]: (state: StateOption, value: boolean) => {
        state.isTermsConsentEnabled = value;
    },
    [STORE_MUTATION.SetIsFirstUserMessage]: (state: StateOption, value: boolean) => {
        state.isFirstUserMessage = value;
    },
    [STORE_MUTATION.SetIsTermsOfServiceAccepted]: (state: StateOption, value: boolean) => {
        state.isTermsOfServiceAccepted = value;
    },
    [STORE_MUTATION.SetTermsConsentPayload]: (state: StateOption, value: string) => {
        state.termsConsentPayload = value;
    },
    [STORE_MUTATION.SetTermsConsentExpiration]: (state: StateOption, value: string) => {
        state.termsConsentExpiration = value;
    },
    [STORE_MUTATION.SetShowConsentModal]: (state: StateOption, value: boolean) => {
        state.showConsentModal = value;
    },
    [STORE_MUTATION.SetConsentStatusOptions]: (state: StateOption, value: MessageOptions | null) => {
        state.consentStatusOptions = value;
    },
    [STORE_MUTATION.SetFormInput]: (state: StateOption, value: IFormInput) => {
        try {
            // Check if the formInputs array exists
            if (!state.formInputs) {
                state.formInputs = [];
            }

            // Check if the input already exists
            const existingInput = state.formInputs.find(item => item.fieldId === value.fieldId);

            if (existingInput) {
                // Update the existing input if found
                existingInput.fieldName = value.fieldName;
                existingInput.fieldValue = value.fieldValue;
            } else {
                // Add the input to the array if it doesn't exist
                state.formInputs.push(value);
            }

        } catch (ex) {
            console.log("[mutations.ts] An error has occurred \n-> Inside STORE_MUTATION.SetFormInput -> Error: ", ex);
        }
    },
    [STORE_MUTATION.ClearFormInputs]: (state: StateOption) => {
        state.formInputs = [];
    },
    [STORE_MUTATION.SetIsMaintainStateMessages]: (state: StateOption, value: boolean) => {
        state.isMaintainStateMessages = value;
    },
    [STORE_MUTATION.SetEnableMessageNavigation]: (state: StateOption, value: boolean) => {
        state.enableMessageNavigation = value;
    },
};
