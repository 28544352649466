import { render, staticRenderFns } from "../views/consent_modal.html?vue&type=template&id=2cb352d6&external"
import script from "../scripts/consent_modal.ts?vue&type=script&lang=ts&external"
export * from "../scripts/consent_modal.ts?vue&type=script&lang=ts&external"
import style0 from "../css_builds/components/modal.css?vue&type=style&index=0&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\beta\\SatisfiWebChat\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2cb352d6')) {
      api.createRecord('2cb352d6', component.options)
    } else {
      api.reload('2cb352d6', component.options)
    }
    module.hot.accept("../views/consent_modal.html?vue&type=template&id=2cb352d6&external", function () {
      api.rerender('2cb352d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/consent_modal.vue"
export default component.exports