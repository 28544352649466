import { STORE_MUTATION } from "../enums";
import { MessageContent } from "../types";
import { store } from "../store/store";
import { getMessageType } from ".";

export const onTypingFromServer = async function (message: MessageContent) {
  //store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
  store.commit(STORE_MUTATION.SetTypingType, getMessageType(message.recordInfo.senderType));
  store.commit(STORE_MUTATION.SetShowTypingIndicator, true);
  try {
    const typingTimeout = (message.typingIndicator as any).timeout ? (message.typingIndicator as any).timeout : 1000;
    setTimeout(() => {
      store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
    }, typingTimeout);
  } catch (ex) {
    store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
  }
};
