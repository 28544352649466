var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      class: {
        [_vm.getClass("bot")]: _vm.typingType == 0 || _vm.typingType == 3,
        [_vm.getClass("user")]: _vm.typingType == 1,
        [_vm.getClass("avatar")]: _vm.isBotHasAvatar && _vm.typingType == 0,
        [_vm.getClass("associate")]: _vm.isUserHasAvatar && _vm.typingType == 3,
      },
      attrs: { "aria-label": "typing indicator" },
    },
    [
      _c("div", { class: _vm.getClass("bubble") }, [
        _c("p", { class: _vm.getClass("typing") }, [
          _c("span", { class: _vm.getClass("blink1") }),
          _vm._v(" "),
          _c("span", { class: _vm.getClass("blink2") }),
          _vm._v(" "),
          _c("span", { class: _vm.getClass("blink3") }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }