var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      class: {
        [_vm.getClass("bot")]: _vm.typingType == 0 || _vm.typingType == 3,
        [_vm.getClass("user")]: _vm.typingType == 1,
        [_vm.getClass("avatar")]: _vm.isBotHasAvatar && _vm.typingType == 0,
        [_vm.getClass("associate")]: _vm.isUserHasAvatar && _vm.typingType == 3,
      },
      attrs: { "aria-label": "processing message" },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.getClass("bubble") + " " + _vm.getClass("bubble--processing"),
        },
        [
          _c("div", { class: _vm.getClass("processing") }, [
            !!_vm.processingDisplayIcon
              ? _c("div", { class: _vm.getClass("processing__icon") }, [
                  _vm.processingDisplayIcon === _vm.IconEnum.DEFAULT ||
                  _vm.processingDisplayIcon === _vm.IconEnum.RIPPLE
                    ? _c("div", { class: _vm.getClass("processing--ripple") }, [
                        _c("div"),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("delayed-child") }),
                      ])
                    : _vm.processingDisplayIcon === _vm.IconEnum.DUAL_RING
                    ? _c("div", {
                        class: _vm.getClass("processing--dual-ring"),
                      })
                    : _vm.processingDisplayIcon === _vm.IconEnum.SPINNER
                    ? _c(
                        "div",
                        { class: _vm.getClass("processing--spinner") },
                        [
                          _c("div", { class: _vm.getClass("child-1") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-2") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-3") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-4") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-5") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-6") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-7") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-8") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-9") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-10") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-11") }),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("child-12") }),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            !!_vm.processingDisplayText
              ? _c("div", {
                  class: _vm.getClass("processing__text"),
                  domProps: { textContent: _vm._s(_vm.processingDisplayText) },
                })
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }