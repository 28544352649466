//#region Imports Packages and Libs

import { HttpRequest } from "../helpers/http_request";
import { getBrowserId } from "../helpers/get_browser_id";
import { store } from "../store/store";
import { BaseService } from "./base_service";
import { ADMIN_MESSAGE_TYPE, STORE_ACTION, STORE_MUTATION } from "../enums";
import { StorageHelper } from "../helpers";
import { getQueryStringValue } from "../helpers/get_querystring_value";
import { Util } from "../util";

//#endregion

//#region Define the User Service Class

// Define the global variable `satisfiLabsGLobal` to access the global variables
declare var satisfiLabsGLobal;

/**
 * The `UserService` class is used to handle all the API calls related to the user.
 */
export class UserService extends BaseService {

	/**
	 * The function `userLogin` is an asynchronous function that takes an emailId and password as
	 * parameters, sends a POST request to a specified URL with the provided data, and returns the
	 * response.
	 * @param {string} emailId - A string representing the email ID of the user trying to login.
	 * @param {string} password - The password parameter is a string that represents the user's password.
	 * @returns the response from the API call.
	 */
	async userLogin(emailId: string, password: string) {
		try {
			// Create data object as params to API
			const data = {
				EmailId: emailId,
				Password: password,
				DeviceId: await getBrowserId(),
				ExtUserId: store.state.userId,
				PageId: store.state.pageId,
				CompanyId: store.state.companyId,
				ApplicationType: 2, // 1 - Dashboard, 2 - WebChat
			};

			// Send the POST request to the API.
			const request = new HttpRequest(`${store.state.webApiAddress}api/login`);
			const response = await request.sendAdminPost(data);

			// Check if the response is valid.
			if (this.isValidResponse(response) && response.Status) {
				store.commit(STORE_MUTATION.SetAuthToken, response.AuthToken || "");
				store.commit(STORE_MUTATION.SetAuthTokenExpiration, response.AuthExpire || 60);
			}

			return response;
		} catch (ex) {
			return null;
		}
	}

	/**
     * This method is used to check if the response is valid.
     */
    isValidResponse(response) {
        if (response == null) {
            // null or undefined
            return false;
        }

        if (typeof response !== 'object') {
            // boolean, number, string, function, etc.
            return false;
        }

        // Check if the object is empty.
        return response && Object.keys(response).length !== 0;
    }

	getCorsHeader() {
		return {
			"Access-Control-Allow-Origin": "*", // Required for CORS support to work
			"Cache-Control": "no-cache",
		};
	}

	/**
	 * The function `sendOTP` sends an OTP (One-Time Password) to the specified email address for
	 * verification.
	 * @param {string} emailId - A string representing the email address to which the OTP (One-Time
	 * Password) will be sent.
	 * @param {number} verificationType - The verificationType parameter is a number that specifies the
	 * type of verification to be sent. It could be used to differentiate between different types of
	 * verification methods, such as email verification, phone verification, etc.
	 * @returns the response from the API call.
	 */
	async sendOTP(emailId: string, verificationType: number) {
		try {
			// Send emailid as querystring
			const queryStrings = new URLSearchParams({
				emailId: emailId,
				verificationType: `${verificationType}`
			});

			// console.log(params.toString());
			// Prints "var1=value&var2=value2&arr=foo"

			// Send the POST request to the API.
			const request = new HttpRequest(`${store.state.webApiAddress}api/sendotp?${queryStrings.toString()}`);
			//const authorizationHeader = getAuthHeader();
			const response = await request.sendAdminPostWithAuth(null, store.state.authToken);
			return response;

		} catch (ex) {
			let msg = "";
			try {
				// Check if 500 error occurred.
				if (ex && typeof ex === 'string' && ex === 'Server error occured') {
					msg = "Invalid Authorization. Please login again.";
				} else {
					msg = "Error in sendOTP: " + (ex instanceof Error ? ex.message : "Server error occurred");
				}
			} catch (ex2) { }

			return {
				Status: false,
				ErrorMessage: msg || "An error occurred while sending the OTP.",
			}
		}
	}

	/**
	 * The function `validateOTP` is an asynchronous function that takes in an emailId, verificationType,
	 * and otp as parameters, sends a POST request to an API endpoint with the provided parameters, and
	 * returns the response.
	 * @param {string} emailId - A string representing the email address of the user.
	 * @param {number} verificationType - The verificationType parameter is a number that specifies the
	 * type of verification being performed. It could be used to differentiate between different types of
	 * OTP verification, such as email verification, phone verification, etc.
	 * @param {string} otp - The `otp` parameter is a string that represents the One-Time Password (OTP)
	 * that needs to be validated.
	 * @returns the response from the API call.
	 */
	async validateOTP(params: any) {
		try {
			// Create data object as params to API
			const data = {
				EmailId: params.EmailId || "",
				OTP: params.OTP || "",
				BrowserId: await getBrowserId(),
				RememberMe: params.RememberMe,
				CompanyId: store.state.companyId,
				UserId: params.UserId,
				Type: params.VerificationType,
				ApplicationType: 2, // 1 - Dashboard, 2 - WebChat
			};

			// Send the POST request to the API.
			const request = new HttpRequest(`${store.state.webApiAddress}api/validate`);
			//const authorizationHeader = getAuthHeader();
			const response = await request.sendAdminPostWithAuth(data, store.state.authToken);
			return response;

		} catch (ex) {
			let msg = "";
			try {
				// Check if 500 error occurred.
				if (ex && typeof ex === 'string' && ex === 'Server error occured') {
					msg = "Invalid Authorization. Please login again.";
				} else {
					msg = "Error in validateOTP: " + (ex instanceof Error ? ex.message : "Server error occurred");
				}
			} catch (ex2) { }

			return {
				Status: false,
				ErrorMessage: msg || "An error occurred while validating the OTP.",
			}
		}
	}

	/**
	 * The function `userLogout` is an asynchronous function that logs out a user by sending a POST
	 * request to the API with the user's email ID, browser ID, user ID, and page ID as parameters.
	 * @returns The response from the API is being returned.
	 */
	async userLogout(pageId, extUserId, deviceId) {
		try {

			// Create data object as params to API
			const data = {
				DeviceId: deviceId,
				ExtUserId: extUserId,
				PageId: pageId,
			};

			// Send the POST request to the API.
			const request = new HttpRequest(`${store.state.webApiAddress}api/logout`);
			const response = await request.sendAdminPostWithAuth(data, store.state.authToken);

			// This is done to stop the API calls to auto-refresh of the JWT token
			const refreshTask = store.state.refreshTask;
			if (this.isValidResponse(response) && response.Status && refreshTask) {
				clearTimeout(refreshTask);
				store.commit(STORE_MUTATION.SetRefreshTask, null);
			}

			return response;

		} catch (ex) {
			let msg = "";
			try {
				// Check if 500 error occurred.
                if (ex && typeof ex === 'string' && ex === 'Server error occured') {
                    msg = "Invalid Authorization. Please login again.";
                } else {
					msg = "Error in userLogout: " + (ex instanceof Error ? ex.message : "Server error occurred");
				}
			} catch (ex2) {}

			return {
				Status: false,
				ErrorMessage: msg || "An error occurred while logging out.",
			}
		}
	}

	/**
	 * The `lockAccount` function is an asynchronous function that takes an emailId as a parameter and
	 * sends a POST request to the API to lock the user account associated with that emailId.
	 * @param {string} emailId - The emailId parameter is a string that represents the email address of
	 * the account that needs to be locked.
	 * @returns The response from the API is being returned.
	 */
	async lockAccount(emailId: string) {
		try {

			// Create data object as params to API
			const data = {
				EmailId: emailId,
				DeviceId: await getBrowserId(),
				ExtUserId: store.state.userId,
				PageId: store.state.pageId,
			};

			// Send the POST request to the API.
			const request = new HttpRequest(`${store.state.webApiAddress}api/lockaccount`);
			const response = await request.sendAdminPostWithAuth(data, store.state.authToken);

			// This is done to stop the API calls to auto-refresh of the JWT token
			const refreshTask = store.state.refreshTask;
			if (this.isValidResponse(response) && response.Status && refreshTask) {
				clearTimeout(refreshTask);
				store.commit(STORE_MUTATION.SetRefreshTask, null);
			}

			return response;

		} catch (ex) {
			let msg = "";
			try {
				// Check if 500 error occurred.
                if (ex && typeof ex === 'string' && ex === 'Server error occured') {
                    msg = "Invalid Authorization. Please login again.";
                } else {
					msg = "Error in lockAccount: " + (ex instanceof Error ? ex.message : "Server error occurred");
				}
			} catch (ex2) {}

			return {
				Status: false,
				ErrorMessage: msg || "An error occurred while locking the account.",
			}
		}
	}

	/**
	 * The `forgotPassword` function is an asynchronous function that sends a POST request to the API
	 * endpoint `/Users/ForgotPassword` with the provided emailId, browserId, userId, and pageId as
	 * parameters.
	 * @param {string} emailId - The emailId parameter is a string that represents the email address of
	 * the user who wants to reset their password.
	 * @returns the response from the API request.
	 */
	async forgotPassword(emailId: string) {
		try {

			// Create data object as params to API
			const data = {
				EmailId: emailId,
				DeviceId: await getBrowserId(),
				ExtUserId: store.state.userId,
				PageId: store.state.pageId,
			};

			// Send the POST request to the API.
			const request = new HttpRequest(`${store.state.webApiAddress}api/forgotpassword`);
			const response = await request.sendAdminPost(data);
			return response;

		} catch (ex) {
			let msg = "";
			try {
				msg = (ex instanceof Error ? ex.message : "Server error occurred");
			} catch (ex3) { }

			return {
				Status: false,
				ErrorMessage: "An error occurred while sending the password reset email. Error: " + (msg || ""),
			}
		}
	}

	/**
	 * The function sends an admin message to the server using SignalR if it is connected, otherwise it
	 * retries after a delay.
	 * @param {any} messageType - The `messageType` parameter is of type `any`, which means it can accept
	 * any data type. It is used to specify the type of message that will be sent to the server.
	 * @param {string} [extMsgId=null] - The extMsgId parameter is a string that represents the external
	 * message ID. It is optional and can be set to null if not needed.
	 * @returns In the given code, the function `sendAdminMessage` is an asynchronous function. It checks
	 * if the `isSignalRConnected` property of the `store.state` object is true. If it is true, it calls
	 * the `sendAdminMessageToServer` function and returns its result. If the `isSignalRConnected`
	 * property is false, it sets a timeout of 500 milliseconds and
	 */
	async sendAdminMessage(messageType: any, extMsgId: string = null) {
		if (store.state.isSignalRConnected) {
			return this.sendAdminMessageToServer(messageType, extMsgId);
		} else {
			setTimeout(() => {
				return this.sendAdminMessage(messageType, extMsgId);
			}, 500);
		}
	}

	/**
	 * The function is an asynchronous function that takes a messageType and
	 * extMsgId as parameters, sends a POST request to the API with the provided parameters, and returns
	 * the response.
	 * @param {any} messageType - admin message type (Login or Logout) enum.
	 * @param {string} [extMsgId=null] - The extMsgId parameter is a string that represents the external
	 * message ID. It is optional and can be set to null if not needed.
	 * @returns the response from the API call.
	 */
	async sendAdminMessageToServer(messageType: any, extMsgId: string = null) {
		try {
			// Create data object as params to API
			const data = {
				MessageType: messageType,
				CompanyId: store.state.companyId,
				BotId: store.state.extBotId,
				UserId: store.state.userId,
				ConvId: store.state.convId,
				DeviceId: await getBrowserId(),
				ChatPageId: store.state.pageId,
				Ip: satisfiLabsGLobal.UserIp || "",
				UserAgent: navigator.userAgent,
				AdminMode: messageType === ADMIN_MESSAGE_TYPE.Login ? 1 : 0,
				//ExtMsgId: extMsgId ? extMsgId : getUniqId(),
			};

			// Send the POST request to the API.
			const request = new HttpRequest(`${this.appUrl}Default/SendAdminMessage`);
			const response = await request.sendPost(data);
			const resObj = response && response.response ? response.response : null;

			// Set the `requestResponseReceived` property of the `store.state` object to true
			if (response && resObj && typeof resObj === 'string' && resObj.trim() !== '') {
				store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
			}

			if (response.messageTime) {
				StorageHelper.set(store.state.convId, response.messageTime);
			}

			//Remove the status from fail
			//store.commit(STORE_MUTATION.UpdateMessageStatus, { id: extMsgId, status: "ok" } as IMessage);

			//update the messageCache id
			// if (this.isJsonString(response.response)) {
			// 	store.commit(STORE_MUTATION.UpdateMessageCacheID, {
			// 		id: extMsgId,
			// 		newId: JSON.parse(response.response).recordInfo.messageCacheId
			// 	} as IMessage);
			// }

			return response;
		} catch (ex) {
			return null;
		}
	}

	/**
	 * The function checks if a given string is a valid JSON string.
	 * @param {any} str - The parameter `str` is of type `any`, which means it can be any data type.
	 * @returns The function isJsonString returns a boolean value. It returns true if the input string can
	 * be successfully parsed as a JSON object, and false otherwise.
	 */
	isJsonString(str: any) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	/**
	 * Get fresh authentication token from server.
	 */
	async refreshToken() {
		try {
			const request = new HttpRequest(`${store.state.webApiAddress}api/getToken`);
			//const authorizationHeader = getAuthHeader();
			const response = await request.sendAdminGetWithAuth(store.state.authToken);

			// Check if the response is valid.
			if (this.isValidResponse(response) && response.Status) {
				store.commit(STORE_MUTATION.SetAuthToken, response.AuthToken || "");
				store.commit(STORE_MUTATION.SetAuthTokenExpiration, response.AuthExpire || 60);
			}

			return response;
		} catch (ex) {
			// in any case if getToken fails call checkUserStatus
			// it will chk user is logged in status on table if the user is valid it will generate token also
			try {
				const queryStrings = new URLSearchParams({
					pageId: store.state.pageId,
					botId: (store.state.extBotId || 0).toString(),
					deviceId: await getBrowserId(),
					extUserId: store.state.userId,
				});
				const request2 = new HttpRequest(`${store.state.webApiAddress}api/checkUserStatus?${queryStrings.toString()}`);
				const response2 = await request2.sendAdminGet();

				// Check if the response is valid.
				if (this.isValidResponse(response2) && response2.Status) {
					store.commit(STORE_MUTATION.SetAuthToken, response2.AuthToken || "");
					store.commit(STORE_MUTATION.SetAuthTokenExpiration, response2.AuthExpire || 60);
				}

				return response2;
			} catch (ex2) {

				let msg = "";
				try {
					msg = "Error in checkUserStatus: " + (ex instanceof Error ? ex.message : "Server error occurred");
				} catch (ex3) { }

				return {
					Status: false,
					ErrorMessage: "An error occurred while refreshing the token. Error: " + (msg || ""),
				}
			}
		}
	}

	/**
	 * The function `checkUserStatus` is an asynchronous function that takes an emailId, deviceId, and
	 * extUserId as parameters, sends a GET request to the API with the provided parameters, and returns
	 * the response.
	 * @param {string} emailId - a string that represents the email address of the user.
	 * @param {string} deviceId - a string that represents the browser ID of the user.
	 * @param {string} extUserId - a string that represents the external user ID of the user.
	 */
	async checkUserStatus(botId, deviceId, extUserId) {
		try {
			const queryStrings = new URLSearchParams({
				pageId: store.state.pageId,
				botId: botId,
				deviceId: deviceId,
				extUserId: extUserId,
			});

			const request = new HttpRequest(`${store.state.webApiAddress}api/checkUserStatus?${queryStrings.toString()}`);
			const response = await request.sendAdminGet();

			// Check if the response is valid.
			if (this.isValidResponse(response) && response.Status) {
				store.commit(STORE_MUTATION.SetIsAdminMode, true);
				store.commit(STORE_MUTATION.SetAuthToken, response.AuthToken || "");
				store.commit(STORE_MUTATION.SetAuthTokenExpiration, response.AuthExpire || 60);

				// Call auto refresh to set up the new timeout for tokens
				store.dispatch(STORE_ACTION.CallAutoRefresh, null);
			}

			return response;

		} catch (ex) {
			let msg = "";
			try {
				msg = "Error in checkUserStatus: " + (ex instanceof Error ? ex.message : "Server error occurred");
			} catch (ex3) { }

			return {
				Status: false,
				ErrorMessage: "[Admin Mode] An error occurred while checking the user status. Error: " + (msg || ""),
			}
		}
	}

	/**
     * The function `getExtUserId` retrieves the external user ID from various sources and returns it.
     * @returns the value of the variable `extUserId`.
     */
    getExtUserId() {
        let extUserId = "";
        try {

            let extId = getQueryStringValue('extid');
            if (Util.isNullOrEmpty(extId)) {
                if (satisfiLabsGLobal && satisfiLabsGLobal.HeaderInfo
                    && satisfiLabsGLobal.HeaderInfo.extid) {
                    extId = satisfiLabsGLobal.HeaderInfo.extid;
                } else {
                    extId = StorageHelper.get("user_id") || "";
                }
            }
            extUserId = extId;

        } catch (ex) {
            extUserId = StorageHelper.get("user_id") || "";
            //console.log("[user_service.ts] An error has occurred -> Inside getExtUserId -> Error: ", ex);
        }
        return extUserId;
    }
}

//#endregion