import { STORE_MUTATION } from "../enums";
import { store } from "../store/store";

export function getUserLocation() {
    navigator.geolocation.getCurrentPosition(pos => {
        store.commit(STORE_MUTATION.SetUserLat, pos.coords.latitude.toString());
        store.commit(STORE_MUTATION.SetUserLng, pos.coords.longitude.toString());
        store.commit(STORE_MUTATION.SetLocationBlocked, false);
    }, error => {
        store.commit(STORE_MUTATION.SetLocationBlocked, true);
        console.log(error.message);
    });
}