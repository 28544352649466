import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { MessageStructureComponent, MessageContentSettings } from "../types";
import Button from "../components/button.vue";
import Img from "../components/image.vue";
import { ActionHandler } from "../helpers";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";

declare var marked;
@Component({
    components: { Button, Img },
    props: {
        components: Array,
        structureIndex: Number,
        msgIndex: Number,
        structure: Object,
		messageContentsettings: Object
    },
    computed: mapState(['prefixClassName'])
})
export default class Card extends Vue {
    //property
    components!: MessageStructureComponent[];
	messageContentsettings!: MessageContentSettings

	// SD-1706
	// This is used to determine if the bubble has any images
	isBubbleHasImage = !!this.components && this.components.filter(c => c.type && c.type=='image').length > 0;
	isImageLoaded = false;

	mounted() {
        this.listenEvents();
    }

    marked(value) {
        return marked(value);
    }

	/**
	 * SD-1706
	 * This is used to listen to needed events
	 */
	 listenEvents() {
		try {
			__vueEvent
				.$once(EVENTS.ChatBubbleImageLoaded, () => {
					this.isImageLoaded = true;
				})

		} catch (ex) {
            //var errorObj = { Page: 'quick_reply.ts', MethodName: 'listenEvents', Message: ex.message };
            //console.log(errorObj);
        }
	}
}
