import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
    computed: mapState(['isBotHasAvatar', 'typingType', 'isUserHasAvatar'])
})
export default class TypingIndicator extends Vue {

}
