import { IMessage } from "../interfaces";
import { MESSAGE_COMPONENT_TYPE } from "../enums";

export const removeButtonsFromPayload = function (payload: IMessage) {

    if (payload && payload.contents && payload.contents.settings && payload.contents.settings.hideQuickRepliesOnSend) {
        const quickReplies = [];
        payload.contents.quickReplies.forEach(function (structure) {
            if (structure.settings && structure.settings.disableHideOnSend) {
                quickReplies.push(structure);
            }
        });
        payload.contents.quickReplies = quickReplies;
    }

    payload.contents.structures.forEach(function (structure, structureIndex) {
        const components = [];
        let hideButtons = false;
        if (structure.settings) {
            hideButtons = structure.settings.hideButtonsOnSend == null ? false : structure.settings.hideButtonsOnSend;
        }
        structure.components.forEach(function (item, index) {
            if (hideButtons) {
                if (item.settings) {
                    if (item.settings.disableHideOnSend) {
                        components.push(item);
                    }
                } else {
                    switch (item.type) {
                        case MESSAGE_COMPONENT_TYPE.Button:
                        case MESSAGE_COMPONENT_TYPE.Link:
                            break;
                        default:
                            components.push(item);
                    }
                }
            }
            else {
                components.push(item);
            }
        });
        payload.contents.structures[structureIndex].components = components;
    });
    return payload;
}