import 'es6-promise/auto';
import "isomorphic-fetch"
import Vue from 'vue';
import Main from './components/main.vue';
import { store } from './store/store';
import { __vueEvent } from './constant';
import { SIGNALR_STATUS } from './enums/signalr_status';
import { SignalrHandler } from './extra/signalr_handler';
import { STORE_MUTATION, EVENTS } from './enums';

Vue.prototype.getClass = function (value) {
    return store.state.prefixClassName + value;
}
Vue.prototype.isArrayLengthGt0 = function (value) {
    return value != null && value.length > 0;
}

Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        if (store.state.isAppInAdaMode) {
            // Focus the element
            el.focus();
        }
    }
});

// Initiate vue app
var app = new Vue({
    el: '#app',
    // provide the store using the "store" option.
    // this will inject the store instance to all child components.
    store,
    render: h => h(Main)
});

window.addEventListener('offline', function () {
    __vueEvent.$emit(EVENTS.StopSignalr);
})
window.addEventListener('online', function () {
    __vueEvent.$emit(EVENTS.ConnectSignalr);
})
window.addEventListener('focus', function () {
    store.commit(STORE_MUTATION.SetFocusedWindow, true);
    __vueEvent.$emit(EVENTS.ConnectSignalr);
})

/**
 * SD-1706: Never use the unload event
 * The most important way to optimize for bfcache in all browsers is to never use the unload event.
 * Link: https://web.dev/bfcache/?utm_source=lighthouse&utm_medium=devtools#never-use-the-unload-event
 */
window.addEventListener('pagehide', function () {
    __vueEvent.$emit(EVENTS.RemoveDataFromRedis);
})
// This blur method was removed to fix the issue related to WEBAPPS-957
// We need to remain active even if user keep the current tab open,
// and then click on a differet window of another application.
// window.addEventListener('blur', function () {
//     store.commit(STORE_MUTATION.SetFocusedWindow, false);
// });

/**
 * The function returns the first focusable element within a modal.
 * If there are focusable elements, it returns the first one as an `Element` object. If there are no
 * focusable elements, it returns `null`.
 */
function getFirstFocusableElement(): Element | null {
    try {
        let modal = document.querySelector(`.${store.state.prefixClassName}chat`);
        let focusableElementsString = 'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';
        let focusableElements = modal.querySelectorAll(focusableElementsString);

        // Convert NodeList to Array
        let focusableElements2 = Array.from(focusableElements)
            .filter((node: Element) => {
                return (node as HTMLElement).offsetWidth > 0 && (node as HTMLElement).offsetHeight > 0;
            });

        return focusableElements2[0] || null;
    } catch (ex) {
        return null;
    }
}

/**
 * The function "trapChatPageTabKey" checks if the first focusable element on the page is already
 * focused, and if so, sends a message to the parent window to focus on the close button.
 */
function trapChatPageTabKey(evt) {
    try {
        let firstFocusableElement = getFirstFocusableElement();

        // Check if the first focusable element is not null and is already focused
        if (firstFocusableElement && document.activeElement === firstFocusableElement) {
            evt.preventDefault();
            window.parent.postMessage({ event_id: 'ontabClickFocusSatisfiIframeClose' }, "*");
        }
    } catch (ex) { }
}

var tabKeyPressedCount = 0;
var tabCheckerTimer;
window.addEventListener('keydown', function (event) {
    if (event.key === 'Tab' || event.keyCode === 9) {
        if (!store.state.isAppInAdaMode) {
            ++tabKeyPressedCount;
            clearTimeout(tabCheckerTimer);
            tabCheckerTimer = setTimeout(function () {
                tabKeyPressedCount = 0;
            }, 1000);
            if (tabKeyPressedCount === 3) {
                console.log('ada mode on');
                tabKeyPressedCount = 0;
                store.commit(STORE_MUTATION.SetIsAppInAdaMode, true);
            }
        }
        if (store.state.isIFrameLoaded && document.activeElement === store.state.lastFocusableElement) {
            window.parent.postMessage({
                event_id: 'ontabClickFocusSatisfiIframeClose'
            }, "*");
            event.stopPropagation();
        }

        // SHIFT + TAB
        if (store.state.isIFrameLoaded && event.shiftKey) {
            trapChatPageTabKey(event);
        }
    }
});

/**
 * Handle what actions to take when the current tab is active or in-active
 */
function handleVisibilityChange() {
	try {
		if (document.hidden) {
			// If the page is hidden, SetFocusedWindow to false;
			store.commit(STORE_MUTATION.SetFocusedWindow, false);
		} else {
			// If the current tab is not already focused,
			// meaning the "focus" event was not called first, then only reconnect
			if (!store.state.focusedWindow) {
				// if the page is shown, SetFocusedWindow to true and emit ConnectSignalr event
				store.commit(STORE_MUTATION.SetFocusedWindow, true);
				__vueEvent.$emit(EVENTS.ConnectSignalr);
			}
		}
	} catch (ex) {}
}

/**
 * Check if the current browser tab is active.
 * Listen to the events when browser tab is active/blured
 */
function tabFocusBlurListener(){
	try {

		// Set the name of the hidden property and the change event for visibility
		var hidden, visibilityChange;
		if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		}

		// Warn if the browser doesn't support addEventListener or the Page Visibility API
		if (typeof document.addEventListener === "undefined" || hidden === undefined) {
			console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
		} else {
			// Handle page visibility change
			document.addEventListener(visibilityChange, handleVisibilityChange, false);
		}

	} catch (ex) {}
}

tabFocusBlurListener();

export default app;