import Vue from "vue";
import { Component } from "vue-property-decorator";
import Bubble from "../components/bubble.vue";
import smoothscroll from "smoothscroll-polyfill";
import { __vueEvent, __oneMinute } from "../constant";
import { EVENTS } from "../enums/events";
import { IConfig } from "../interfaces/config";
import Loader from "../components/loader.vue";
import QuickReply from "../components/quick_reply.vue";
import TypingIndicator from "../components/typing_indicator.vue";
import ProcessingMessage from "../components/processing_message.vue";
import LoginModal from "../components/login_modal.vue";
import ConsentModal from "../components/consent_modal.vue";
import Modal from "../components/modal.vue";
import { mapState } from "vuex";
import Card from "../components/card.vue";
import { STORE_ACTION, STORE_MUTATION, CACHE_ID_TYPE, MESSAGE_STRUCTURE_TYPE, MESSAGE_TYPE } from "../enums";
import { IMessage } from "../interfaces";
import { ChatService } from "../service/chat_service";
import { StorageHelper, getMessageFromServer } from "../helpers";
import Banner from "../components/banner.vue";
import { isMobile } from "../helpers/is_mobile";
import { onExceptionOccured } from "../helpers/on_exception_occured";
import { Util } from "../util";
import { UserService } from "../service/user_service";

@Component({
  components: {
    Bubble,
    Loader,
    QuickReply,
    TypingIndicator,
    Card,
    Banner,
    ProcessingMessage,
    Modal,
    LoginModal,
    ConsentModal,
  },
  computed: mapState([
    "showTypingIndicator",
    "messages",
    "isBotHasAvatar",
    "shouldShowBanner",
    "isUserHasAvatar",
    "showProcessingMessage",
    "showAdminLoginModal",
    "showConsentModal",
    "showWelcomeBanner",
    "enableMessageNavigation",
  ]),
})
export default class Chat extends Vue {
    //isUserHasAvatar = false;

    isMobileActive = isMobile.any() || this.$store.state.isIFrameLoaded;

    isPageLoaded = false;

    hideTagLine = false;

    isPrevMessageFetching = false;

    mounted() {
        // initiate scroll polyfill
        smoothscroll.polyfill();
        this.listenEvents();
    }

    updated() {
        if (typeof jQuery !== 'undefined') {
            if (this.$store.state.isIFrameLoaded) {
                this.$nextTick(function () {
                    this.trapFocus();
                });
            }
        }
    }

    /**
     * SD-5153: Returns the message prefix based on the given message type.
     *
     * @param messageType - The type of the message.
     * @returns The message prefix.
     */
    getMessagePrefix(messageType: MESSAGE_TYPE): string {
        try {
            switch (messageType) {
                case MESSAGE_TYPE.Bot:
                case MESSAGE_TYPE.Agent:
                    return 'Bot says: ';
                case MESSAGE_TYPE.User:
                    return 'I say: ';
                // case MESSAGE_TYPE.Agent:
                //     return 'Agent said: ';
                default:
                    return ''; // Handle other message types as needed
            }
        } catch (error) {
            //console.error('Error occurred:', error);
            return '';
        }
    }

    trapFocus() {
        var element = <HTMLElement>document.getElementsByClassName(`${this.$store.state.prefixClassName}standalone`)[0];

        //Last focusable element of popup
        var lastFocusableEl;
        if (this.$store.state.disableInput) {
            var focusableEls = element.querySelectorAll(`button:not([style*="display:none"]):not([style*="display: none"])`);
            var displayedFocusElement = [];
            for (var i = 0; i < focusableEls.length - 1; i++) {
                if (jQuery(focusableEls[i]).is(":visible")) {
                    displayedFocusElement.push(focusableEls[i]);
                }
            }
            lastFocusableEl = <HTMLElement>displayedFocusElement[displayedFocusElement.length - 1];
        } else {
            lastFocusableEl = jQuery(`button.${this.$store.state.prefixClassName}btn.${this.$store.state.prefixClassName}send`);
        }
        this.$store.commit(STORE_MUTATION.SetLastFocusableElement, lastFocusableEl);
    }

    listenEvents() {
        __vueEvent
            .$on(EVENTS.ScrollChatToBottom, this.scrollChatToBottom)
            .$on(EVENTS.ScrollChatToTop, this.scrollChatToTop)
            .$once(EVENTS.SetConfig, (config: IConfig) => {
                this.isPageLoaded = true;
                this.hideTagLine = config.hideTagLine;
                this.checkAdminModeOnReload();
            }).$on(EVENTS.ScrollToNewMessageTop, this.scrollToNewMessageTop)

        const fetchMessage = async () => {
            const element = this.chatBody; //(event.srcElement as HTMLElement)
            const previousScrollHeightMinusTop =
                element.scrollHeight - element.scrollTop;
            const msgs = await getMessageFromServer(
                this.$store.state.lastMessageId,
                CACHE_ID_TYPE.BeforeMessageCacheId,
                true,
                this.$store.state.minimumClientVersion || "",
                `chat.ts:fetchMessage`
            );
            setTimeout(() => {
                this.$store.dispatch(STORE_ACTION.AddPrevMessages, msgs);
                this.isPrevMessageFetching = false;
                setTimeout(() => {
                    if (msgs.length > 0) {
                        element.scrollTop =
                            element.scrollHeight - previousScrollHeightMinusTop;
                    } else {
                        element.scrollTop = 0;
                    }
                }, 0);
            }, 500);
        };
        this.chatBody.onscroll = async event => {
            const element = event.srcElement as HTMLElement;
            const scrollTop = element.scrollTop;
            if (
                this.isPrevMessageFetching === false &&
                scrollTop <= 10 &&
                this.$store.state.lastMessageId > 0
            ) {
                this.isPrevMessageFetching = true;
                const previousScrollHeightMinusTop =
                    element.scrollHeight - element.scrollTop;
                //this.$store.commit(STORE_MUTATION.AddFetchMsgStamp, "Loading previous messages");
                setTimeout(() => {
                    element.scrollTop = 0; //element.scrollHeight - previousScrollHeightMinusTop;
                }, 0);
                fetchMessage();
            }
        };
    }


    /**
     * The function takes a string representing a date and returns a Date object,
     * or the current date if the input is invalid.
     * @param {string} dateString - The dateString parameter is a string that represents a date.
     * @returns a Date object.
     */
    convertStringToDate(dateString: string) {
        let date = null;
        try {
            if (!dateString) {
                throw new Error("Input date string is empty or null.");
            }
            const dateObject = new Date(dateString);
            if (isNaN(dateObject.getTime())) {
                throw new Error("Invalid date representation.");
            }
            date = dateObject;
        } catch (ex) {
            date = null;
        }
        return date;
    }

    // Function to serialize the date object to a string
    serializeDate(date) {
        try {
            if (!date || isNaN(new Date(date).getTime())) {
              return ""; // Return an empty string if the date is empty, false, or not a valid Date object
            }

            return new Date(date).toISOString(); // Convert the date to an ISO 8601 string (e.g., "2023-07-01T12:34:56.789Z")
        } catch (error) {
            //console.error("Error occurred during date serialization:", error);
            return ""; // Return an empty string if there was an error during the conversion
        }
    }
    /**
     * Check if the Admin Mode is enabled on page reload
     */
    async checkAdminModeOnReload() {
        try {
            // Check if we have some admin mode related data saved in the local storage
            const extUserId = new UserService().getExtUserId();
            const deviceId = StorageHelper.get("browser_id") || "";
            const botId = this.$store.state.extBotId || 0;
            const pageId = this.$store.state.pageId || "";
            const adminModeValue = StorageHelper.get(`swc_is_adm_${pageId}_${extUserId}`) || "";
            var decodedString = window.atob( adminModeValue );
            const isAdminModeEnabled = decodedString == "1";

            if (!Util.isNullOrEmpty(extUserId) && !Util.isNullOrEmpty(deviceId)
                && !Util.isNullOrEmpty(botId) && isAdminModeEnabled) {
                // Yes, we have some data saved in the local storage
                // Make a call to the server to check if the user is still logged in
                await new UserService().checkUserStatus(botId, deviceId, extUserId);
            }

        } catch (ex) {
            console.log("[chat.ts] An error has occurred -> Inside checkAdminModeOnReload -> Error: ", ex);
        }
    }

    get chatBody() {
        return this.$refs.chat_body as HTMLElement;
    }

    scrollChatToTop() {
        this.isPrevMessageFetching = true;
        setTimeout(() => {
            const element = this.chatBody;
            element.scroll({ top: 0 });
        }, 10);
    }

    /**
     * Detect if the system is Mac Computers or iOS Devices
     * like iMac, Macbook, iPhone, iPod, or iPad.
     * @returns {boolean} true if the system is Mac Computers or iOS Devices, false otherwise.
     */
    isMacLike() {
        try {
            const userAgent = navigator.userAgent || 'unknown';
            const appleDeviceRegex = /Mac|iPhone|iPod|iPad/i;
            const isMacLike = appleDeviceRegex.test(userAgent);
            return isMacLike;
        } catch (ex) {
            return false;
        }
    }

    /**
     * Scroll to the destination element by the duration specified.
     * @param {HTMLElement} scrollLayer - HTMLElement - The element that you want to scroll.
     * @param {number} destination - The position to scroll to.
     * @param {number} duration - The duration of the scroll animation in milliseconds.
     */
    scrollToElement(scrollLayer: HTMLElement, destination: number, duration: number) {
        try {
            if (duration <= 0) return;
            const difference = destination - scrollLayer.scrollTop;
            const perTick = (difference / duration) * 10;

            setTimeout(() => {
                scrollLayer.scrollTop = scrollLayer.scrollTop + perTick;
                if (scrollLayer.scrollTop === destination) return;
                this.scrollToElement(scrollLayer, destination, duration - 10);
            }, 10);
        } catch (ex) {
            return false;
        }
    }

    scrollToNewMessageTop() {
		try {

			// detect support for the behavior property in ScrollOptions
			const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

			// SD-1671
			// Fix for Tiles Scrolling Issue In Chat
			// 1. Check if the chat is loaded inside the iframe
			const isIFrameLoaded = this.$store.state.isIFrameLoaded;
			const isTilesOpened = this.$store.state.isTilesOpenedInIframe;

			// Yes, the current browser supports native smooth scroll
			setTimeout(() => {
				const element = this.chatBody;
				if (element != null) {

					// Get the offsetTop value for the last message
					let offsetTop = 0;
					try {
						let $lastMessageElem = jQuery(".satisfi_messages section:not(.satisfi_chat-modal-body):last");
						// Check if an element exists in the visible DOM
						if ($lastMessageElem && $lastMessageElem.length && $lastMessageElem[0] != null) {
							offsetTop = $lastMessageElem[0].offsetTop;
						}
					} catch (ex2) {
						offsetTop = 0;
					}

					// SD-2838
                    // Check if the current browser is Mac Computers or iOS Devices
                    if (!this.isMacLike()) {
						// Check if this page is inside an iframe and also a Tile
						if (!!isIFrameLoaded && !!isTilesOpened) {
							// Fix for scrollIntoView() causing the whole page to move, when opened inside a Tile
							element.scroll({ top: offsetTop, behavior: "smooth" });
						} else {
							// continue with default behavior

                            // SD-3057
                            // Fix for Webchat UI Issue when Grammarly extension is installed
							jQuery(".satisfi_messages section:last")[0].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest',
                                inline: 'start'
                            });
						}
					} else {
						// Using `true` here
						// as Safari supports scrollIntoView with boolean parameter, but not smooth behavior option
						//jQuery(".satisfi_messages section:last")[0].scrollIntoView(true);

                        // Current device is an iMac, Macbook, iPhone, iPod, or iPad
                        // custom function for iOS
                        this.scrollToElement(element, offsetTop, 200);
					}

				}
				element.style.height = "500px";
			}, 10);

		} catch (ex) {
			//console.log('Error: ', (ex as Error).message);
		}
    }

    scrollChatToBottom(schollSoothly = true) {
		try {
			const element = this.chatBody;
			if (schollSoothly) {
				setTimeout(() => {
					if (element != null) {
						element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
					}
					element.style.height = '500px';
				}, 10);
			}
			else {
				// Check if the system is Mac Computers or iOS Devices
				if(!this.isMacLike()) {
					// Its a non-Mac device
					element.scroll({ top: element.scrollHeight });
				} else {
					// Yes, its either a Mac/iOS Devices
					this.$nextTick(function () {
						element.scrollTop = element.scrollHeight;
					});
				}
			}
		} catch (ex) {
			//console.log('Error: ', (ex as Error).message);
		}
    }

    async retryMessage(msgId) {
        const msg: IMessage = this.$store.state.messages.find(
            msg => msg.id === msgId
        );
        try {
            //On retry button click remove retry/cancel link by making status "ok".
            this.$store.commit(STORE_MUTATION.UpdateMessageStatus, {
                id: msgId,
                status: "ok"
            } as IMessage);

            let newMessageResponse;
            //Check failed message is text with button or normal text message.
            if (msg.buttonMessageStructure != null) {
                newMessageResponse = await new ChatService().sendMessage(
                    msg.buttonMessageStructure
                );
                //make it button message structure null if message is send successful.
                this.$store.commit(STORE_MUTATION.UpdateMessageStructure, {
                    id: msgId,
                    buttonMessageStructure: null
                } as IMessage);
            } else {
                newMessageResponse = await new ChatService().sendMessage({
                    content: msg.contents.structures[0].components[0].content,
                    type: "text"
                });
            }

            //On message send update message id from Guid to messageCacheId sent from EBM.
            this.$store.commit(STORE_MUTATION.UpdateMessageCacheID, {
                id: msgId,
                newId: JSON.parse(newMessageResponse).recordInfo.messageCacheId
            } as IMessage);

        } catch (ex) {
            //On message fail make status of that message as "failed".
            this.$store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
            this.$store.commit(STORE_MUTATION.UpdateMessageStatus, {
                id: msgId,
                status: "failed"
            } as IMessage);

            //onExceptionOccured("[chat.ts] An error has occurred -> Inside retryMessage -> Error: ", ex);
        }
    }

    cancelMessage(id) {
        this.$store.commit(STORE_MUTATION.RemoveMessage, id);
        this.$store.commit(STORE_MUTATION.RemoveMessage, id + MESSAGE_STRUCTURE_TYPE.Time);
    }

	/**
	 * Check if the current message is a card type
	 */
	isBotMessageCard(message: IMessage): boolean {
		try {
			// Check if the contents & structures have a valid value
			if (message.contents && message.contents.structures) {
				// Check if any of the structure have a type of "card"
				return message.contents.structures.some((s) => s.type == 'card');
			}
			return false;
		} catch (ex) {}
		return false;
	}
}
