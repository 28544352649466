/**
 * Checks if the current browser is Firefox.
 * @returns {boolean} Returns true if the browser is Firefox, false otherwise.
 */
export const isFirefox = function (): boolean {
	try {
		// Access userAgent string in lowercase for case-insensitive comparison
		const userAgent = navigator.userAgent.toLowerCase();
		return userAgent.indexOf("firefox") !== -1;
	} catch (error) {
		return false;
	}
};
