export const installJquery = async function () {
    return new Promise(function (res) {
        if (typeof jQuery == 'undefined') {
            var script = document.createElement("SCRIPT") as HTMLScriptElement;
            script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js';
            script.onload = function () {
                res();
            }
            document.head.appendChild(script);
        }
        else {
            res();
        }
    })
}