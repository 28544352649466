export enum EVENTS {
    ScrollChatToBottom = "scroll_chat_to_bottom",
    SetConfig = "set_config",
    ShowTypingIndicator = "show_typing_indicator",
    ShowProcessingMessage = "show_processing_message",
    ProcessingDisplayIcon = "processing_display_icon",
    ProcessingDisplayText = "processing_display_text",
    ButtonClick = "button_click",
    NewMessage = "new_message",
    CheckForDisableInput = "check_for_disable_input",
    ConnectSignalr = "connect_signalr",
    StopSignalr = "stop_signalr",
    PrependMessage = "prepend_message",
    NewMessageSent = "new_message_sent",
    MessageFromMaintainState = "message_from_maintain_state",
    CheckForDuplicateIframe = "check_for_duplicate_iframe",
    ScrollToNewMessageTop = "scroll_to_new_message_top",
    RemoveAllButtons = "remove_all_buttons",
    FocusInputArea = "focus_input_area",
    ScrollChatToTop = "scroll_chat_to_top",
    UrlOpenedInIFrame = "url_opened_in_iframe",
    UrlOpenedInIFrameFromMain = "url_opened_in_iframe_from_main",
    HideCloseIFrameIcon = "hide_close_iframe_icon",
    RemoveDataFromRedis = "remove_data_from_redis",
    ChatBubbleImageLoaded = "chat_bubble_image_loaded",
    ClearAdminModeInput = "clear_admin_mode_input",
    UpdateAdminModalStep = "update_admin_modal_step",

}