import { getCookie, setCookie, deleteCookie } from './cookie_handler';

export class StorageHelper {
    static get(key: string) {
        try {

            if (localStorage == null) {
                return getCookie(key);
            } else {
                return localStorage.getItem(key);
            }
        } catch { return null; }
    }

    static set(key: string, value: string) {
        try {

            if (localStorage == null) {
                setCookie(key, value);
            } else {
                localStorage.setItem(key, value);
            }
        } catch { }
    }

    static remove(key: string) {
        try {
            if (localStorage == null) {
                deleteCookie(key);
            } else {
                localStorage.removeItem(key);
            }
        } catch { }

    }

}