var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.getClass("quick-reply-wrapper") }, [
    _c(
      "ul",
      {
        class:
          _vm.getClass("button ") +
          _vm.getClass("quickReply ") +
          (_vm.messageContentsettings.showOneQuickReplyPerLine !== null &&
          _vm.messageContentsettings.showOneQuickReplyPerLine
            ? _vm.getClass("singleQuickReplyPerLine ")
            : " "),
        attrs: { "aria-label": "quick replies", role: "list" },
      },
      [
        _vm._l(_vm.data, function (value, index) {
          return _vm.isFontLoaded
            ? [
                _c(
                  "li",
                  {
                    class: _vm.getClass("quick-reply-item"),
                    attrs: { role: "listitem" },
                  },
                  [
                    _c(
                      "button",
                      {
                        class:
                          _vm.getClass("btn ") +
                          _vm.getClass("btnQuickReply ") +
                          (_vm.messageContentsettings
                            .showOneQuickReplyPerLine !== null &&
                          _vm.messageContentsettings.showOneQuickReplyPerLine
                            ? _vm.getClass("btnQuickReplyPerLine ")
                            : " "),
                        on: {
                          click: function ($event) {
                            return _vm.onClick(index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(value.label))]
                    ),
                  ]
                ),
              ]
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }