import { DEVICE_TYPE } from "./enums";
import { StorageHelper } from "./helpers";

export class Util {
    static isNullOrEmpty(value: string) {
        return value == null || value.length === 0;
    }

    static isArray(value) {
        return Array.isArray(value);
    }

    static setCssVariable(key, val) {
        let root = document.documentElement;
        root.style.setProperty(key, val);
    }

    static getDeviceType = function () {
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i) ||
            navigator.userAgent.match(/iPad/i)) {
            return DEVICE_TYPE.Mobile;
        } else if (window.innerWidth <= 600) {
            return DEVICE_TYPE.PcLow;
        } else {
            return DEVICE_TYPE.Pc;
        }
    }

    /**
    * Encode all less than & greater than characters in a string
    * @param str The string value that we need to encode
    */
    static htmlEscape(str: string = '') {
        return str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

    /**
    * Decode all less than & greater than characters in a string
    * @param str The string value that we need to decode
    */
    static htmlUnEscape(str: string = '') {
        return str.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    }

    /**
    * Remove all script tags from string.
    * @param str The string value that we need to sanitize
    */
    static removeScriptTags(str: string = '') {
        if (!str) return '';
        var regExp = /<script.*?>(.|\n)*?<\/script>/igm
        return str.replace(regExp, '');
    }

    /**
     * A TypeScript implementation of debounce function
     */
    static debounce<F extends (...params: any[]) => void>(func: F, waitMilliseconds = 50)
        : (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
        let timeoutId: ReturnType<typeof setTimeout> | undefined;

        return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
            const context = this;

            const doLater = function () {
                timeoutId = undefined;
                func.apply(context, args);
            }

            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(doLater, waitMilliseconds);
        }
    }

    /**
     * Throttling is a technique in which, no matter how many times the user fires the event,
     * the attached function will be executed only once in a given time interval.
     * @param {Function} fn This is the function we need to Throttle
     * @param {number} wait This is the time in milliseconds that we need to wait
     * @example let throttledFunc = throttle(myFunc, 500);
     */
    static throttle(fn: Function, wait: number = 50) {
        let isCalled = false;

        return function (...args) {
            if (!isCalled) {
                fn(...args);
                isCalled = true;
                setTimeout(() => isCalled = false, wait);
            }
        };
    }

    /**
     * The function `isValidJSON` checks if a given string is a valid JSON.
     * @param {string} str - The `str` parameter is a string that represents a JSON object.
     * @returns The function `isValidJSON` returns a boolean value. It returns `true` if the input string
     * `str` is a valid JSON, and `false` otherwise.
     */
    static isValidJSON(str: string = '') {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * This function validates and parses a JSON payload, returning the parsed
     * object if successful or null if there is an error.
     * @param payload - The payload parameter is expected to be a string containing JSON data.
     * @returns the parsed JSON payload if it is valid, otherwise it returns null.
     */
    static validateAndParsePayload(payload) {
        try {
            if (!payload || typeof payload !== 'string') {
                throw new Error('Payload is null or not a string');
            }

            if (!this.isValidJSON(payload)) {
                throw new Error('Payload is not valid JSON');
            }

            return JSON.parse(payload);
        } catch (ex) {
            return null;
        }
    }

    /**
     * Function to check if a variable is an object,
     * and if it has a property that is an array with length > 0
     */
    static isObjectWithNonEmptyArray(obj, propertyName) {
        try {
            return (
                typeof obj === 'object' &&
                obj !== null &&
                propertyName in obj &&
                Array.isArray(obj[propertyName]) &&
                obj[propertyName].length > 0
            );
        } catch (ex) {
            return false;
        }
    }

    /**
     * The function takes a string representing a date and returns a Date object,
     * or the current date if the input is invalid.
     * @param {string} dateString - The dateString parameter is a string that represents a date.
     * @returns a Date object.
     */
    static convertStringToDate(dateString: string) {
        let date = null;
        try {
            if (!dateString) {
                throw new Error("Input date string is empty or null.");
            }
            const dateObject = new Date(dateString);
            if (isNaN(dateObject.getTime())) {
                throw new Error("Invalid date representation.");
            }
            date = dateObject;
        } catch (ex) {
            date = null;
        }
        return date;
    }

    /**
     * The function checks if a terms consent has been accepted and is still valid based on the
     * expiration time.
     * @param pageId - The pageId parameter is a unique identifier for a specific page or section of a
     * website. It is used to retrieve the consent data from local storage.
     * @param expirationInSecs - The expirationInSecs parameter is the duration in seconds for which
     * the consent is considered valid.
     * @returns a boolean value. It returns true if the terms consent is still valid and has not
     * expired, and it returns false if there is no consent data in local storage, if the date in local
     * storage is invalid, or if the consent has expired.
     */
    static isTermsConsentAccepted(pageId, expirationInSecs = "2592000") {
        try {
            let data = StorageHelper.get(`swc_cnst_dt_${pageId}`) || "";
            if (!data) return false; // No consent data in local storage

            let consentExpDate = this.convertStringToDate(window.atob(data)) || "";
            if (!consentExpDate) return false; // Invalid date in local storage

            // Get the expiration time in seconds
            // let timeInSeconds = Number(expirationInSecs);
            // if (isNaN(timeInSeconds)) {
            //     //console.error('Invalid seconds value provided. Using default value.');
            //     timeInSeconds = 2592000; // Default value: 30 days in seconds
            // }

            const currentTime = new Date().getTime();
            const expirationTime = new Date(consentExpDate).getTime();
            //const expirationInMillis = timeInSeconds * 1000; // in milliseconds

            if (expirationTime < currentTime) {
                return false; // Consent has expired
            }

            return true; // Consent is still valid
        } catch (ex) {
            return false;
        }
    }

    /**
     * The function checks if a given object has a non-null and non-empty value for a specified
     * property.
     * @param obj - The `obj` parameter is the object that you want to check if it has a specific
     * property with a value.
     * @param propertyName - The propertyName parameter is a string that represents the name of the
     * property that we want to check for a value in the obj object.
     * @returns a boolean value. It returns true if the object has a non-null, non-undefined, and
     * non-empty value for the specified property name. Otherwise, it returns false.
     */
    static propertyHasValue(obj, propertyName) {
        try {
            return (
                typeof obj === 'object' &&
                obj !== null &&
                propertyName in obj &&
                obj[propertyName] !== null &&
                obj[propertyName] !== undefined &&
                obj[propertyName] !== ''
            );
        } catch (ex) {
            return false;
        }
    }

    /**
     * The function takes an optional parameter `seconds` and returns a new Date
     * object that is the current date plus the specified number of seconds.
     * @param [seconds=2592000] - The "seconds" parameter is a string representing the number of
     * seconds to add to the current date. The default value is "2592000", which corresponds to 30
     * days.
     * @returns a new Date object that represents the current time plus the specified number of
     * seconds. If an error occurs, it will return the current date and time.
     */
    static addSecondsToDate(seconds = "2592000") {
        try {
            let timeInSeconds = Number(seconds);
            if (isNaN(timeInSeconds)) {
                //console.error('Invalid seconds value provided. Using default value.');
                timeInSeconds = 2592000; // Default value: 30 days in seconds
            }

            const currentTime = new Date().getTime();
            const expirationInMillis = timeInSeconds * 1000; // in milliseconds
            return new Date(currentTime + expirationInMillis);
        } catch (error) {
            return new Date();
        }
    }
}
