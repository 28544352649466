
//#region Imports Packages and Libs

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import { store } from "../store/store";
import { STORE_MUTATION } from "../enums/store_mutation";
import { UserService } from "../service/user_service";
import { StorageHelper } from "../helpers/storage_helper";
import { ADMIN_MESSAGE_TYPE, EVENTS, STORE_ACTION } from "../enums";
import { sendAdminMessage } from "../helpers";
import { Util } from "../util";
import { __vueEvent } from "../constant";

//#endregion

//#region Enums

/**
 * Enum for login form action.
 */
enum LoginActionEnum {
    LOGIN = 1,
    FORGOT_PASSWORD,
    GET_CODE,
    ENTER_CODE,
    LOGIN_SUCCESS,
    ACCOUNT_LOCKED,
    LOGOUT_SUCCESS,
}

//#endregion

//#region Define the component in class-style

@Component({
    computed: mapState(['isBotHasAvatar', 'typingType', 'isUserHasAvatar'])
})
export default class LoginModal extends Vue {

    //#region Class properties will be component data

    // Declare the existing prop
    //@Prop({ default: LoginActionEnum.LOGIN }) // Set default value here
    //currentStepProp!: LoginActionEnum;

    // Create a local data property to hold the current step value
    //currentStep: LoginActionEnum = this.currentStepProp;

    // Declare additional class properties
    LoginActionEnum = LoginActionEnum;
    currentStep: LoginActionEnum = LoginActionEnum.LOGIN;
    loggedInUserId: number = 0;
    loggedInUserEmail: string = "";
    loggedInUserSuperAdmin: boolean = false;
    invalidClicked: number = 0;
    resendClicked: number = 0;
    sentCodeTimeoutId: ReturnType<typeof setTimeout> | undefined;
    isMfaRememberMe: boolean = true;

    //#region Modal Lifecycle Hooks

    mounted() {
        this.listenEvents();
    }

    listenEvents() {
        __vueEvent.$on(EVENTS.UpdateAdminModalStep, this.updateAdminModalStep)
    }

    /**
     * Updates the current step of the admin modal based on the provided step.
     * @param {number} step - The `step` parameter is of type `LoginActionEnum`.
     */
    updateAdminModalStep(step: number) {
        this.currentStep = step;
    }

    //#endregion

    // Data for the login form.
    loginForm = {
        email: "",
        password: "",
        errors: "",
        isLoading: false,
    }

    // Data for the forgot password form.
    forgotPwdForm = {
        error: "",
        email: "",
        message: "",
        isLoading: false,
    }

    // Data for Get verification code form.
    getVerificationCodeForm = {
        isEmail: true,
        error: "",
        message: "",
        isLoading: false,
    }

    // Data for Email verification form.
    emailVerificationForm = {
        otp: "",
        rememberMe: true,
        error: "",
        message: "",
        isLoading: false,
        isEmailLoading: false,
    }

    //#endregion


    //#region Modal related logic here

    /**
     * This method is called when the user clicks the close modal button.
     */
    closeModal() {
        try {
            // Check if the current step is login success.
            if (this.currentStep !== LoginActionEnum.LOGIN_SUCCESS) {
                // Clear some state value (e.g., reset the currentStep to the initial step)
                store.commit(STORE_MUTATION.SetAuthToken, "");

                // This is done to stop the API calls to auto-refresh of the JWT token
                const refreshTask = store.state.refreshTask;
                if (refreshTask) {
                    clearTimeout(refreshTask);
                    store.commit(STORE_MUTATION.SetRefreshTask, null);
                }
            }
            store.commit(STORE_MUTATION.SetShowAdminLoginModal, false);

        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside closeModal -> Error: ", ex);
        }
    }

    //#endregion

    //#region Login View Logic here

    /**
     * This method is used to check if the response is valid.
     */
    isValidResponse(response) {
        if (response == null) {
            // null or undefined
            return false;
        }

        if (typeof response !== 'object') {
            // boolean, number, string, function, etc.
            return false;
        }

        // Check if the object is empty.
        return response && Object.keys(response).length !== 0;
    }

    /**
     * The function adds a specified number of days to the current date and returns the resulting date.
     * @param [days=0] - The "days" parameter is the number of days to add to the current date.
     * @returns a new Date object that is incremented by the specified number of days.
     */
    addDays(days = 0) {
        var result = new Date();
        result.setDate(result.getDate() + days);
        return result;
    }

    /**
     * The function `getLocaleDate` takes a date as input and returns a formatted string representing
     * the date in the en-US locale.
     * @param date - The `date` parameter is the input date that you want to convert to a localized
     * date format.
     * @returns The function `getLocaleDate` returns a formatted date string in the format
     * "MM/DD/YYYY".
     */
    getLocaleDate(date) {
        var result = new Date(date);
        return result.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
    }

    /**
     * This method is called when the user clicks the "Login" button.
     */
    async doLogin(event) {
        try {
            event.preventDefault();
            this.loginForm.errors = "";

            if (!this.loginForm.email) {
                this.loginForm.errors = 'E-Mail or Username is required.';
                return;
            }

            if (!this.loginForm.password) {
                this.loginForm.errors = 'Password is required.';
                return;
            }

            this.loginForm.isLoading = true;

            // Add the logic to verify the login credentials here.
            const emailId = this.loginForm.email;
            const password = this.loginForm.password;
            const response = await new UserService().userLogin(emailId, password);

            // Check if the response is valid.
            if (this.isValidResponse(response) && response.Status) {
                // If the credentials are valid,
                // move to MFA view or login success view based on the response.
                this.loggedInUserId = response.Data ? Number(response.Data) : 0;
                this.loggedInUserEmail = emailId || "";
                this.loggedInUserSuperAdmin = response.IsSuperAdmin || false;

                // Call auto refresh to set up the new timeout for tokens
                store.dispatch(STORE_ACTION.CallAutoRefresh, null);

                // Check if we need to show the MFA view.
                let showMFA = response.ShowAuthScreen;
                if (showMFA) {
                    this.currentStep = LoginActionEnum.GET_CODE;
                } else {
                    this.currentStep = LoginActionEnum.LOGIN_SUCCESS;
                }
            } else {
                this.loginForm.errors = response.ErrorMessage || "An error has occurred while logging in. Please try again later!";
            }


            // If the credentials are valid,
            // move to MFA view or login success view based on the response.
            //this.currentStep = LoginActionEnum.GET_CODE;

        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside doLogin -> Error: ", ex);
            this.loginForm.errors = "An error has occurred while logging in. Please try again later.";
        } finally {
            this.loginForm.isLoading = false;
        }
    }


    /**
     * The function takes a string representing a date and returns a Date object,
     * or the current date if the input is invalid.
     * @param {string} dateString - The dateString parameter is a string that represents a date.
     * @returns a Date object.
     */
    convertStringToDate(dateString: string) {
        let date = null;
        try {
            if (!dateString) {
                throw new Error("Input date string is empty or null.");
            }
            const dateObject = new Date(dateString);
            if (isNaN(dateObject.getTime())) {
                throw new Error("Invalid date representation.");
            }
            date = dateObject;
        } catch (ex) {
            date = null;
        }
        return date;
    }

    /**
     * The function `getDecodeDataByKey` takes a string representing a base64 encoded data and a key
     * as input, decodes the string, and returns the value of the key from the decoded object.
     */
    getDecodeDataByKey(data, key) {
        let decodedData = "";
        try {
            if (!data || !key) {
                throw new Error("Input data or key is empty or null.");
            }

            let decodedString = window.atob(data);
            let decodedObject = JSON.parse(decodedString);

            // Check if the key exists in the decoded object.
            decodedData = decodedObject.hasOwnProperty(key) ? decodedObject[key] : "";
        } catch (ex) {
            decodedData = "";
        }
        return decodedData;
    }

    // Function to serialize the date object to a string
    serializeDate(date) {
        try {
            if (!date || isNaN(new Date(date).getTime())) {
              return ""; // Return an empty string if the date is empty, false, or not a valid Date object
            }

            return new Date(date).toISOString(); // Convert the date to an ISO 8601 string (e.g., "2023-07-01T12:34:56.789Z")
        } catch (error) {
            //console.error("Error occurred during date serialization:", error);
            return ""; // Return an empty string if there was an error during the conversion
        }
    }

    /**
     * The function `getEncodedData` takes an expiration date and enable date as input, serializes them
     * into a JSON string, and then encodes the string using base64 encoding.
     * @param expirationDate - The MFA expiration date.
     * @param enableDateTime - A Last enabled date and time for MFA.
     * @returns the encoded data as a string.
     */
    getEncodedData(expirationDate) {
        let encodedData = "";
        try {
            // Get the MFA enable date from the store.
            let mfaEnableDateTimeStore = store.state.mfaEnableDateTime;
            let enableDateTime = this.convertStringToDate(mfaEnableDateTimeStore) || "";

            let data = {
                expDate: expirationDate,
                enableDate: enableDateTime,
            };

            // Serialize the date objects to strings
            data.expDate = this.serializeDate(data.expDate);
            data.enableDate = this.serializeDate(data.enableDate);

            // Serialize the data to a string
            let serializedData = JSON.stringify(data);
            encodedData = window.btoa(serializedData);
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside getEncodedData -> Error: ", ex);
        }
        return encodedData;
    }

    /**
     * The function updates the MFA expiration date in the storage based on the provided MFA expiration
     * value and key.
     * @param {number} mfaExpiration - The number of days until the MFA (Multi-Factor Authentication)
     * expiration date.
     * @param {string} key - The key is a string that represents the key or identifier for the value
     * that will be stored in the storage.
     */
    updateMFAExpiration(mfaExpiration: number, key: string) {
        if (mfaExpiration > 0) {
            let mfaExpirationDate = this.addDays(mfaExpiration);
            let encodedData = this.getEncodedData(mfaExpirationDate);
            StorageHelper.set(key, encodedData);
        } else {
            StorageHelper.set(key, this.getEncodedData(""));
        }
    }

    /**
     * The function `validateMFA` takes in a user ID, user email, and a boolean indicating if the user
     * is a super admin, and returns a boolean indicating whether or not to show the multi-factor
     * authentication (MFA) prompt.
     * @param {number} userId - A number representing the user's ID.
     * @param {boolean} isSuperAdmin - A boolean value indicating whether the user is a super admin or
     * not.
     * @returns The variable "showMFA" is being returned.
     */
    /*validateMFA(userId: number, isSuperAdmin: boolean, doUpdate = false) {
        let showMFA = false;
        try {
            let mfaExpiration = store.state.mfaExpiration;
            mfaExpiration = mfaExpiration != null ? mfaExpiration : 30;
            let companyId = store.state.companyId;
            let mfaEnableDateTimeStore = store.state.mfaEnableDateTime;
            let mfaStorageExpDate = "";

            if(!showMFA) {
                // First check if the user is a super admin.
                if (isSuperAdmin) {
                    // Add the logic to check if the user has MFA enabled.
                    let superAdminMFAKey = `swc_exp_dt_${userId}_${0}`;
                    mfaStorageExpDate = this.getDecodeDataByKey(StorageHelper.get(superAdminMFAKey) || "", "expDate");
                    if (mfaStorageExpDate == null || mfaStorageExpDate === "") {
                        // This is the first time the user is logging in.
                        if (doUpdate) this.updateMFAExpiration(mfaExpiration, superAdminMFAKey);

                        // Show the MFA view.
                        showMFA = true;
                    } else {
                        // The user has logged in before.
                        let mfaExpirationDate = this.addDays(mfaExpiration);
                        let mfaEnableDateTime = this.getDecodeDataByKey(StorageHelper.get(superAdminMFAKey) || "", "enableDate");
                        if ((new Date(mfaStorageExpDate).getTime() < new Date().getTime())
                            || (new Date(mfaEnableDateTime).getTime() !== new Date(mfaEnableDateTimeStore).getTime())) {
                            // The MFA has expired.
                            if (doUpdate) this.updateMFAExpiration(mfaExpiration, superAdminMFAKey);
                            showMFA = true;
                        } else {
                            // Check if the MFA expiration date is 0.
                            if (doUpdate) {
                                if (mfaExpiration === 0) {
                                    StorageHelper.set(superAdminMFAKey, this.getEncodedData(""));
                                } else if (mfaExpirationDate.getTime() < new Date(mfaStorageExpDate).getTime()) {
                                    // Check if the new MFA expiration date is less than the date stored in the local storage.
                                    //var encodedDate = window.btoa(mfaExpirationDate.toString());
                                    let encodedData = this.getEncodedData(mfaExpirationDate);
                                    StorageHelper.set(superAdminMFAKey, encodedData);
                                }
                            }

                            // The MFA has not expired.
                            showMFA = false;
                        }
                    }
                } else {
                    let adminMFAKey = `swc_exp_dt_${userId}_${companyId}`;
                    mfaStorageExpDate = this.getDecodeDataByKey(StorageHelper.get(adminMFAKey) || "", "expDate");
                    if (mfaStorageExpDate == null || mfaStorageExpDate === "") {
                        // This is the first time the user is logging in.
                        if (doUpdate) this.updateMFAExpiration(mfaExpiration, adminMFAKey);

                        // Show the MFA view.
                        showMFA = true;
                    } else {
                        // The user has logged in before.
                        //mfaStorageExpDate = window.atob(mfaStorageExpDate);

                        // If the EnableDateTime stored in the local storage and state are different,
                        // then the mfa has been toggled, so show the MFA view.
                        let mfaEnableDateTime = this.getDecodeDataByKey(StorageHelper.get(adminMFAKey) || "", "enableDate");
                        if ((new Date(mfaStorageExpDate).getTime() < new Date().getTime())
                            || (new Date(mfaEnableDateTime).getTime() !== new Date(mfaEnableDateTimeStore).getTime())) {
                            // The MFA has expired.
                            if (doUpdate) this.updateMFAExpiration(mfaExpiration, adminMFAKey);
                            showMFA = true;
                        } else {
                            // The MFA has not expired.
                            if (doUpdate && mfaExpiration === 0) {
                                StorageHelper.set(adminMFAKey, this.getEncodedData(""));
                            }
                            showMFA = false;
                        }
                    }
                }
            }

        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside validateMFA -> Error: ", ex);
        }
        return showMFA;
    }*/

    //#endregion

    //#region Forgot Password View Logic here

    /**
     * This method is called when the user clicks the go back button.
     */
    goBack() {
        // If the user is in the forgot pwd step, go back to the login step.
        if (this.currentStep === LoginActionEnum.FORGOT_PASSWORD
            || this.currentStep === LoginActionEnum.GET_CODE) {
            this.currentStep = LoginActionEnum.LOGIN;

            // Clear the login form data.
            this.loginForm.email = "";
            this.loginForm.password = "";
            this.loginForm.errors = "";
            this.loginForm.isLoading = false;
        }
    }

    showForgotPassword() {
        this.forgotPwdForm.error = "";
        this.forgotPwdForm.message = "";
        this.forgotPwdForm.email = "";
        this.currentStep = LoginActionEnum.FORGOT_PASSWORD;
    }

    /**
     * This method is called when the user clicks the "Request Password Reset" button.
     */
    async requestPasswordReset(event) {
        try {
            event.preventDefault();

            this.forgotPwdForm.error = "";
            this.forgotPwdForm.message = "";

            if (!this.forgotPwdForm.email) {
                this.forgotPwdForm.error = 'E-Mail or Username is required.';
            } else if (!this.validEmail(this.forgotPwdForm.email)) {
                this.forgotPwdForm.error = 'Invalid Email Address.';
            } else {

                this.forgotPwdForm.isLoading = true;

                // Add the logic to request password reset here.
                const emailId = this.forgotPwdForm.email;
                const response = await new UserService().forgotPassword(emailId);

                // Check if the response is valid.
                if (this.isValidResponse(response) && response.Status) {
                    this.forgotPwdForm.error = "";
                    this.forgotPwdForm.message = "An email has been sent to your email address.";
                } else {
                    this.forgotPwdForm.error = response.ErrorMessage
                        || "An error has occurred while requesting password reset. Please try again later!"
                }
            }

        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside requestPasswordReset -> Error: ", ex);
            this.forgotPwdForm.error = "An error has occurred while requesting password reset. Please try again later.";
        } finally {
            this.forgotPwdForm.isLoading = false;
        }
    }

    validEmail(email) {
        // Source: https://v2.vuejs.org/v2/cookbook/form-validation.html
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    //#endregion

    //#region Get Verification Code View Logic here

    /**
     * This method is called when the user clicks the "NEXT" button,
     * in the Get Verification Code step.
     */
    async moveToVerifyEmail(event) {
        try {
            event.preventDefault();
            this.getVerificationCodeForm.error = "";

            // Make a call to the API to send the verification code.
            const response = await new UserService().sendOTP(this.loggedInUserEmail, 1);

            // Check if the response is valid.
            if (this.isValidResponse(response) && response.Status) {
                this.currentStep = LoginActionEnum.ENTER_CODE;
            } else {
                let msg = response.ErrorMessage || "An error has occurred while sending the verification code. Please try again later!";
                this.getVerificationCodeForm.error = msg;
            }
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside moveToVerifyEmail -> Error: ", ex);
            this.getVerificationCodeForm.error = "An error has occurred while sending the verification code. Please try again later.";
        } finally {
            this.getVerificationCodeForm.isLoading = false;
        }
    }

    /**
     * This method is called when the user clicks the "Request Another Email" button.
     * in the Get Verification Code step.
     */
    async requestAnotherEmail(event) {
        try {
            event.preventDefault();
            this.emailVerificationForm.message = "";
            this.emailVerificationForm.error = "";

            if (this.resendClicked < store.state.attemptNoofResendCode) {
                this.resendClicked++;
                this.emailVerificationForm.isEmailLoading = true;

                if (this.sentCodeTimeoutId) {
                    clearTimeout(this.sentCodeTimeoutId);
                }

                // Make a call to the API to send another verification code.
                const response = await new UserService().sendOTP(this.loggedInUserEmail, 1);

                // Check if the response is valid.
                if (this.isValidResponse(response) && response.Status) {
                    this.emailVerificationForm.message = "Another code has been sent to your email address.";
                    this.sentCodeTimeoutId = setTimeout(() => {
                        this.emailVerificationForm.message = "";
                    }, 5000);
                } else {
                    let msg = response.ErrorMessage || "An error has occurred while sending the verification code. Please try again later!";
                    this.emailVerificationForm.error = msg;
                }

            } else {
                // If the user has clicked the resend button more than 5 times,
                // show the account locked view.
                this.lockAccount(LoginActionEnum.ENTER_CODE);
            }
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside requestAnotherEmail -> Error: ", ex);
            this.emailVerificationForm.error = "An error has occurred while sending the verification code. Please try again later.";
        } finally {
            this.emailVerificationForm.isEmailLoading = false;
        }
    }

    /**
     * This method is used to lock the account.
     */
    async lockAccount(loginView: any) {
        try {
            const response = await new UserService().lockAccount(this.loggedInUserEmail);

            // Check if the response is valid.
            if (this.isValidResponse(response) && response.Status) {

                this.currentStep = LoginActionEnum.ACCOUNT_LOCKED;

                // Set the admin mode to false, as we logout once the account is locked.
                store.commit(STORE_MUTATION.SetIsAdminMode, false);
                store.commit(STORE_MUTATION.SetAuthToken, "");
                this.clearAdminModeDetails();

            } else {
                let msg = response.ErrorMessage || "An error has occurred while locking the account. Please try again later!";
                if (loginView === LoginActionEnum.ENTER_CODE) {
                    this.emailVerificationForm.error = msg;
                } else {
                    this.getVerificationCodeForm.error = msg;
                }
            }

        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside lockAccount -> Error: ", ex);
        }
    }

    //#endregion

    //#region Email Verification View Logic here

    /**
     * This method is called when the user clicks the "Submit Code" button.
     */
    async verifyOTP(event) {
        try {
            event.preventDefault();
            this.emailVerificationForm.error = "";

            if (!this.emailVerificationForm.otp) {
                this.emailVerificationForm.error = 'Please enter OTP!';
                return;
            }

            this.emailVerificationForm.isLoading = true;

            // Add the logic to verify the OTP here.
            const otp = this.emailVerificationForm.otp;
            const rememberMe = this.emailVerificationForm.rememberMe;
            const params = {
                UserId: this.loggedInUserId,
                OTP: otp,
                RememberMe: rememberMe,
                EmailId: this.loggedInUserEmail,
                VerificationType: 1,
            };

            const response = await new UserService().validateOTP(params);

            // Check if the response is valid.
            if (this.isValidResponse(response) && response.Status) {
                // If the OTP is valid, move to the login success step.
                this.currentStep = LoginActionEnum.LOGIN_SUCCESS;
                //store.commit(STORE_MUTATION.SetIsAdminMode, true);

            } else {
                // If the OTP is invalid, show the error message.
                if (this.isValidResponse(response) && !response.Status) {
                    // Check if we got invalid authorization error.
                    const errorMsg = response.ErrorMessage || "";
                    if (!Util.isNullOrEmpty(errorMsg) && errorMsg.toLowerCase().includes('Invalid Authorization'.toLowerCase())) {
                        this.emailVerificationForm.error = errorMsg;
                    } else {
                        this.invalidClicked++;
                        if (this.invalidClicked >= store.state.attemptNoofInvalidCode) {
                            // If the user has clicked the submit button more than 5 times,
                            // show the account locked view.
                            this.lockAccount(LoginActionEnum.ENTER_CODE);
                        } else {
                            let invalidErrorMessage = response.ErrorMessage;
                            if (store.state.attemptNoofInvalidCode - 2 <= this.invalidClicked) {
                                invalidErrorMessage += `
                                This is your ${this.invalidClicked} attempt. You have ${store.state.attemptNoofInvalidCode - this.invalidClicked} attempt(s) remaining.`;
                            }

                            this.emailVerificationForm.error = invalidErrorMessage || "An error has occurred while verifying the OTP. Please try again later!";
                        }
                    }
                } else {
                    this.emailVerificationForm.error = response.ErrorMessage || "An error has occurred while verifying the OTP. Please try again later!"
                }
            }

        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside verifyOTP -> Error: ", ex);
            this.emailVerificationForm.error = "An error has occurred while verifying the OTP. Please try again later.";
        } finally {
            this.emailVerificationForm.isLoading = false;
        }
    }

    /**
     * This method is used to allow only numbers in the OTP input field.
     */
    numbersOnly(evt: any) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    //#endregion

    //#region Login Success View Logic here

    async continueChatAsAdmin() {
        try {
            // Set the is admin mode to true.
            store.commit(STORE_MUTATION.SetIsAdminMode, true);
            this.storeAdminModeDetails();
            store.commit(STORE_MUTATION.SetShowAdminLoginModal, false);
            await sendAdminMessage(ADMIN_MESSAGE_TYPE.Login);
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside continueChatAsAdmin -> Error: ", ex);
        }
    }

    /**
     * This method is used to store the admin mode details in the storage.
     * So that we can use it to persist the admin mode on page refresh.
     */
    async storeAdminModeDetails() {
        try {
            var encodedString = window.btoa("1");
            const pageId = store.state.pageId || "";
            const extUserId = new UserService().getExtUserId();
            StorageHelper.set(`swc_is_adm_${pageId}_${extUserId}`, encodedString);
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside persistAdminMode -> Error: ", ex);
        }
    }

    /**
     * This method is used to clear the admin mode details from the storage.
     */
    clearAdminModeDetails() {
        try {
            var encodedString = window.btoa("0");
            const pageId = store.state.pageId || "";
            const extUserId = new UserService().getExtUserId();
            StorageHelper.set(`swc_is_adm_${pageId}_${extUserId}`, encodedString);
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside clearAdminModeDetails -> Error: ", ex);
        }
    }

    //#endregion

    //#region Logout Success View Logic here

    continueChatAsNonAdmin() {
        try {
            __vueEvent.$emit(EVENTS.ClearAdminModeInput);
            store.commit(STORE_MUTATION.SetShowAdminLoginModal, false);
        } catch (ex) {
            console.log("[login_modal.ts] An error has occurred \n-> Inside continueChatAsAdmin -> Error: ", ex);
        }
    }

    //#endregion
}

//#endregion
