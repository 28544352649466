import { preFetchImage } from "./pre_fetch_Image";
import { ChatService } from "../service/chat_service";
import { MessageContent } from "../types";
import { CACHE_ID_TYPE } from "../enums";

export const removeDataFromRedis = async function() {
  let response;
  try {    
    response = await new ChatService().removeDataFromRedis();    
  } catch {
    return [];
  }  
};
