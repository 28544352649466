import { HttpRequest } from "./http_request";
import { store } from "../store/store";
import { getAppUrl } from "./get_app_url";

export const onExceptionOccured = async (msg: string, ex) => {
    console.error("message", msg, "ex", ex);
    var log = {
        Message: msg,
        Exception: ex.stack == null ? "no stacktarce" : ex.stack,
        Type: 1, // Exception,
        SourceApp: "exe_app"
    }

    var request = new HttpRequest(`${store.state.apiServer}/log`);
    const response = await request.sendPost(log);
}

export const logServerSideError = async (msg: string) => {
    console.error("message", msg);
    var log = {
        Message: msg,
        Exception: {},
        Type: 1, // Exception,
        SourceApp: "webchat_dev"
    }

    var request = new HttpRequest(`${getAppUrl()}Default/LogException`);
    await request.sendPost(log);
}

export const logServerSideDebug = async (msg: string) => {
    console.error("message", msg);
    var log = {
        Message: msg,
        Exception: {},
        Type: 0, // Debug,
        SourceApp: "webchat_dev"
    }

    var request = new HttpRequest(`${getAppUrl()}Default/LogException`);
    await request.sendPost(log);
}