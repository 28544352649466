import { STORE_ACTION } from "../enums/store_action";
import { STORE_MUTATION } from "../enums/store_mutation";
import { MESSAGE_TYPE } from "../enums/message_type";
import { IMessage } from "../interfaces/message";
import { IConfig } from "../interfaces/config";
import { StateOption, MessageContent } from "../types";
import {
  getConvId,
  StorageHelper,
  getUniqId,
  addUserMessage
} from "../helpers";
import { __maintainState, __maintainStateTime, __vueEvent } from "../constant";
import { getMessageFromServer } from "../helpers/get_message_from_server";
import { getMsgFromContent } from "../helpers/get_msg_from_content";
import { removeButtonsFromPayload } from "../helpers/remove_buttons_from_payload";
import { validateAndModify } from "../helpers/validate_and_modify";
import { EVENTS, CACHE_ID_TYPE } from "../enums";
import { getQueryStringValue } from "../helpers/get_querystring_value";
import { setCookie } from "../helpers/cookie_handler";
import { UserService } from "../service/user_service";

type StoreContext = {
  commit: (mutationType: STORE_MUTATION, payload?: any) => void;
  state: StateOption;
  dispatch: (action: STORE_ACTION, payload: any) => void;
};

export const actions = {
  [STORE_ACTION.InitConfig]: async (ctx: StoreContext, config: IConfig) => {
    const addWelcomeMsg = function () {
      try {
        const welcomeMessage = JSON.parse(config.welcomeMessage);
        ctx.commit(STORE_MUTATION.AddMessage, {
          type: MESSAGE_TYPE.Bot,
          contents: welcomeMessage,
          id: welcomeMessage.recordInfo.messageCacheId
        } as IMessage);
      } catch (ex) { }
    };
    ctx.commit(STORE_MUTATION.SetBotId, config.extBotId);
    ctx.commit(STORE_MUTATION.SetCompanyToken, config.companyToken);
    ctx.commit(STORE_MUTATION.SetCompanyId, config.companyId);
    ctx.commit(STORE_MUTATION.SetSendMsgUrl, config.sendMessageUrl);
    ctx.commit(STORE_MUTATION.SetMaintainState, config.maintainState);
    ctx.commit(STORE_MUTATION.SetEnableAdminMode, config.enableAdminMode);
    ctx.commit(STORE_MUTATION.SetIsBotHasAvatar, config.avatarBotImage);
    ctx.commit(STORE_MUTATION.SetIsUserHasAvatar, config.avatarHumanImage);
    ctx.commit(STORE_MUTATION.SetIsNewMessageProcessing, config.newMessageProcessing);
    ctx.commit(STORE_MUTATION.SetEnableMessageNavigation, config.enableMessageNavigation);
    ctx.commit(
      STORE_MUTATION.SetConvId,
      getConvId(config.maintainState, ctx.state.pageId, config.convId)
    );
    ctx.commit(
      STORE_MUTATION.SetRetryInterval,
      Number(config.webChatConnectionRetryInterval) * 1000
    );
    ctx.commit(
      STORE_MUTATION.SetRetryWarningCount,
      Number(config.webChatConnectionRetryWarningCount)
    );

    ctx.commit(
      STORE_MUTATION.SetTypingMessageSecondsInterval,
      Number(config.typingMessageSecondsInterval) * 1000
    );

    __vueEvent.$emit(EVENTS.SetConfig, config);
    if (config.maintainState) {
      if (StorageHelper.get(`${__maintainState}${ctx.state.pageId}`) != null) {

        let msgs = [];
        if (!config.newMessageProcessing) {
          /**
           * We are using minimumClientVersion here instead of clientVersion, because after page reload
           * we call this function and if getMessageFromServer returns `reload true`, then the popup
           * will keep reloading continuously.
           */
          msgs = await getMessageFromServer(
            0,
            CACHE_ID_TYPE.BeforeMessageCacheId,
            false,
            ctx.state.minimumClientVersion || "",
            `actions.ts:InitConfig`
          );
        } else {
          /**
           * SD-4680: We are not using getMessageFromServer here
           * Incase of maintain state ON, we are fetching messages from server after getting the welcome message
           * using fetchMaintainStateMessages method inside the main.ts file.
           */
          msgs = [];
        }

        const msgLength = msgs.length;

        if (msgLength > 0) {
          //save the redis convid and time in localstorage
          try {
            localStorage.setItem(msgs[0].keys.extConvId, msgs[0].messageTime);
          }
          catch (ex) {
            setCookie(msgs[0].keys.extConvId, msgs[0].messageTime);
          }

          ctx.commit(STORE_MUTATION.SetIsAppInAdaMode, true);
          msgs.forEach(function (msg, index) {

            if (msg.settings && msg.settings.clearScreen) {
              ctx.commit(STORE_MUTATION.ClearScreen);
            }

            if ((msg.settings && !msg.settings.inputHidden) || !msg.settings) {
              const msgPayload = getMsgFromContent(msg);
              ctx.commit(STORE_MUTATION.AddMessage, msgPayload);
              __vueEvent.$emit(EVENTS.MessageFromMaintainState, msgPayload);
            }
            if (index !== msgLength - 1) {
              ctx.commit(STORE_MUTATION.RemoveAllButtons);
            } else {
              __vueEvent.$emit(
                EVENTS.CheckForDisableInput,
                getMsgFromContent(msg)
              );
            }
          });
          const firstMessage = msgs[0];
          const time = firstMessage.recordInfo.messageDateTime;
          const lastMessageId = Number(firstMessage.recordInfo.messageCacheId);
          ctx.commit(STORE_MUTATION.SetLastMessageId, lastMessageId);
          setTimeout(() => {
            ctx.commit(STORE_MUTATION.SetIsAppInAdaMode, false);
            __vueEvent.$emit(EVENTS.FocusInputArea);
          }, 100);
        } else {
          addWelcomeMsg();
        }
      } else {
        StorageHelper.set(`${__maintainState}${ctx.state.pageId}`, "true");
        addWelcomeMsg();
      }
    } else {
      if (StorageHelper.get(`${__maintainState}${ctx.state.pageId}`) != null) {
        StorageHelper.remove(`${__maintainState}${ctx.state.pageId}`);
      }
      addWelcomeMsg();
    }
  },
  [STORE_ACTION.AddPrevMessages]: (
    ctx: StoreContext,
    msgs: MessageContent[]
  ) => {
    try {
      // remove stamp message
      // ctx.commit(STORE_MUTATION.RemoveStampMessage);
      if (msgs.length > 0) {
        const messages = msgs.map(msg => {
          let message = getMsgFromContent(msg);
          message = validateAndModify(message);
          return removeButtonsFromPayload(message);
        });
        const lastMessage = messages[0];
        // push all messages
        ctx.commit(STORE_MUTATION.PrependMessages, messages.reverse());
        // ctx.state.messages.splice(0, 0, ...messages);

        const lastMessageId = Number(lastMessage.id);
        ctx.commit(STORE_MUTATION.SetLastMessageId, lastMessageId);
      } else {
        ctx.commit(STORE_MUTATION.SetLastMessageId, 0);
      }
    } catch (ex) {
      console.error(ex);
    }
  },
  [STORE_ACTION.RefreshToken]: async (ctx: StoreContext, payload?: any) => {
    try {
      await new UserService().refreshToken();

      // Finally, call autoRefresh to set up the new timeout
      ctx.dispatch(STORE_ACTION.CallAutoRefresh, null)

    } catch (ex) {
      console.log("[actions.ts] An error has occurred \n-> Inside RefreshJWTToken -> Error: ", ex);
    }
  },
  [STORE_ACTION.CallAutoRefresh]: async (ctx: StoreContext, payload?: any) => {
    try {
      let timeUntilRefresh = ctx.state.authTokenExpiration || 30;
      timeUntilRefresh = timeUntilRefresh - 5; // Refresh 5 minutes before it expires
      const refreshTask = setTimeout(() => {
        ctx.dispatch(STORE_ACTION.RefreshToken, null)
      }, timeUntilRefresh * 60 * 1000);

      ctx.commit(STORE_MUTATION.SetRefreshTask, refreshTask); // In case you want to cancel this task on logout
    } catch (ex) {
      console.log("[actions.ts] An error has occurred \n-> Inside CallAutoRefresh -> Error: ", ex);
    }
  },
};
