var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.getClass("modal-backdrop") }, [
    _c(
      "div",
      {
        class: _vm.getClass("modal"),
        attrs: {
          role: "dialog",
          "aria-label": "Admin Login modal",
          "aria-describedby": "modalDescription",
        },
      },
      [
        _c("header", { class: _vm.getClass("modal-header") }, [
          _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(" ")]),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.currentStep !== _vm.LoginActionEnum.LOGIN_SUCCESS &&
                    _vm.currentStep !== _vm.LoginActionEnum.LOGOUT_SUCCESS,
                  expression:
                    "currentStep !== LoginActionEnum.LOGIN_SUCCESS && currentStep !== LoginActionEnum.LOGOUT_SUCCESS",
                },
              ],
              class: _vm.getClass("btn-close"),
              attrs: {
                type: "button",
                "aria-label": "Close modal",
                title: "Close modal",
              },
              on: { click: _vm.closeModal },
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
        ]),
        _vm._v(" "),
        _c(
          "section",
          {
            class: _vm.getClass("modal-body"),
            attrs: { id: "modalDescription" },
          },
          [
            _c("div", { class: _vm.getClass("multi-step-form") }, [
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === _vm.LoginActionEnum.LOGIN,
                      expression: "currentStep === LoginActionEnum.LOGIN",
                    },
                  ],
                  key: "loginForm",
                },
                [
                  _c(
                    "form",
                    {
                      class: _vm.getClass("form-inline"),
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.doLogin.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { attrs: { id: "LoginForm" } }, [
                        _c(
                          "div",
                          {
                            class:
                              _vm.getClass("form-row ") +
                              _vm.getClass("content-center "),
                          },
                          [
                            _c("p", { class: _vm.getClass("small-text ") }, [
                              _vm._v(
                                "Please enter your e-mail address or\r\n                                    username and password."
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("form-row ") }, [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.loginForm.email,
                                  expression: "loginForm.email",
                                  modifiers: { trim: true },
                                },
                              ],
                              class: _vm.getClass("form-input "),
                              attrs: {
                                maxlength: "500",
                                name: "EmailUser",
                                placeholder: "Email",
                                type: "text",
                                value: "",
                                required: "",
                                oninvalid:
                                  "this.setCustomValidity('E-Mail or Username is required.')",
                                oninput: "this.setCustomValidity('')",
                                tabindex: "0",
                                spellcheck: "false",
                                autocomplete: "username",
                              },
                              domProps: { value: _vm.loginForm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.loginForm,
                                    "email",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.getClass("login-form-validation-error ") +
                                  _vm.getClass("field-validation-valid ") +
                                  _vm.getClass("no-display "),
                              },
                              [
                                _vm._v(
                                  "\r\n                                        E-Mail or Username is required.\r\n                                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("form-row ") }, [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.loginForm.password,
                                  expression: "loginForm.password",
                                  modifiers: { trim: true },
                                },
                              ],
                              class: _vm.getClass("form-input "),
                              attrs: {
                                id: "Password",
                                maxlength: "100",
                                name: "Password",
                                placeholder: "Password",
                                type: "password",
                                required: "",
                                oninvalid:
                                  "this.setCustomValidity('Password is required.')",
                                oninput: "this.setCustomValidity('')",
                                tabindex: "0",
                                spellcheck: "false",
                              },
                              domProps: { value: _vm.loginForm.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.loginForm,
                                    "password",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.getClass("login-form-validation-error ") +
                                  _vm.getClass("field-validation-valid ") +
                                  _vm.getClass("no-display "),
                              },
                              [
                                _vm._v(
                                  "\r\n                                        Password is required.\r\n                                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("form-row ") }, [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.getClass("secondary-link ") +
                                  _vm.getClass("content-right "),
                                staticStyle: { width: "100%" },
                                attrs: {
                                  id: "hlForgotPassword",
                                  href: "javascript:void(0)",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showForgotPassword.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\r\n                                        Forgot Password"
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("form-row ") }, [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c("input", {
                              class:
                                _vm.getClass("btn-login ") +
                                _vm.getClass("btn-block ") +
                                _vm.getClass("btn-primary "),
                              attrs: {
                                type: "submit",
                                disabled: _vm.loginForm.isLoading,
                              },
                              domProps: {
                                value: !_vm.loginForm.isLoading
                                  ? "Login"
                                  : "Logging in...",
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: _vm.getClass("form-row "),
                            staticStyle: { "margin-top": "25px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                class:
                                  _vm.getClass("form-element ") +
                                  _vm.getClass("form-footer ") +
                                  _vm.getClass("content-center "),
                              },
                              [
                                _vm._v(
                                  "\r\n                                    © " +
                                    _vm._s(new Date().getFullYear()) +
                                    " Satisfi Labs Inc. All rights reserved.\r\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !!_vm.loginForm.errors
                          ? _c(
                              "div",
                              {
                                class:
                                  _vm.getClass("form-row ") +
                                  _vm.getClass("pb-0"),
                              },
                              [
                                _c("span", {
                                  class:
                                    _vm.getClass("content-center ") +
                                    _vm.getClass(
                                      "login-form-validation-error "
                                    ) +
                                    _vm.getClass("field-validation-error "),
                                  domProps: {
                                    innerHTML: _vm._s(_vm.loginForm.errors),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentStep === _vm.LoginActionEnum.FORGOT_PASSWORD,
                      expression:
                        "currentStep === LoginActionEnum.FORGOT_PASSWORD",
                    },
                  ],
                  key: "forgotPasswordForm",
                },
                [
                  _c(
                    "form",
                    {
                      class: _vm.getClass("form-inline"),
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.requestPasswordReset.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { attrs: { id: "ForgotPassword" } }, [
                        _c(
                          "div",
                          {
                            class:
                              _vm.getClass("form-row ") +
                              _vm.getClass("content-center ") +
                              _vm.getClass("pb-16 "),
                          },
                          [
                            _c("p", { class: _vm.getClass("large-text ") }, [
                              _vm._v("Forgot Password"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("form-row ") }, [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.forgotPwdForm.email,
                                  expression: "forgotPwdForm.email",
                                  modifiers: { trim: true },
                                },
                              ],
                              class: _vm.getClass("form-input "),
                              attrs: {
                                maxlength: "500",
                                name: "EmailUser",
                                placeholder: "Email",
                                type: "text",
                                value: "",
                                required: "",
                                oninvalid:
                                  "this.setCustomValidity('E-Mail or Username is required.')",
                                oninput: "this.setCustomValidity('')",
                                tabindex: "0",
                                spellcheck: "false",
                              },
                              domProps: { value: _vm.forgotPwdForm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.forgotPwdForm,
                                    "email",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm._v(" "),
                            !!_vm.forgotPwdForm.error
                              ? _c(
                                  "span",
                                  {
                                    class:
                                      _vm.getClass("content-center ") +
                                      _vm.getClass(
                                        "login-form-validation-error "
                                      ) +
                                      _vm.getClass("field-validation-error "),
                                  },
                                  [
                                    _vm._v(
                                      "\r\n                                        " +
                                        _vm._s(_vm.forgotPwdForm.error) +
                                        "\r\n                                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { class: _vm.getClass("form-row ") }, [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c("input", {
                              class:
                                _vm.getClass("btn-login ") +
                                _vm.getClass("btn-block ") +
                                _vm.getClass("btn-primary "),
                              attrs: {
                                type: "submit",
                                disabled: _vm.forgotPwdForm.isLoading,
                              },
                              domProps: {
                                value: !_vm.forgotPwdForm.isLoading
                                  ? "Request Password Reset"
                                  : "Requesting...",
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        !!_vm.forgotPwdForm.message
                          ? _c(
                              "div",
                              {
                                class:
                                  _vm.getClass("form-row ") +
                                  _vm.getClass("content-center "),
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    class:
                                      _vm.getClass("small-text ") +
                                      _vm.getClass("action-success "),
                                  },
                                  [_vm._v(_vm._s(_vm.forgotPwdForm.message))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.getClass("form-row ") +
                              _vm.getClass("pb-0 ") +
                              _vm.getClass("content-center "),
                          },
                          [
                            _c(
                              "a",
                              {
                                class: _vm.getClass("goback-link "),
                                attrs: { href: "javascript:void();" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.goBack.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("← Back to Login")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === _vm.LoginActionEnum.GET_CODE,
                      expression: "currentStep === LoginActionEnum.GET_CODE",
                    },
                  ],
                  key: "getVerificationCodeForm",
                },
                [
                  _c(
                    "form",
                    {
                      class: _vm.getClass("form-inline"),
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.moveToVerifyEmail.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: _vm.getClass("mfa-container-main"),
                          attrs: { id: "TwoStepVerification" },
                        },
                        [
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("large-text ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _vm._v(
                                "\r\n                                Two-Step Verification\r\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("small-text2 ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _vm._v(
                                "\r\n                                In order to keep your account safe, we want to make sure it's really you.\r\n                                Choose how you want to verify\r\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("form-row ") }, [
                            _c(
                              "label",
                              {
                                class:
                                  _vm.getClass("control-label ") +
                                  _vm.getClass("content-right "),
                                attrs: { for: "chkEmail" },
                              },
                              [
                                _vm._v(
                                  "\r\n                                    Get verification code by Email\r\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { class: _vm.getClass("form-checkbox") },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.getVerificationCodeForm.isEmail,
                                      expression:
                                        "getVerificationCodeForm.isEmail",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  class: _vm.getClass("checkbox-control"),
                                  attrs: {
                                    type: "checkbox",
                                    id: "chkEmail",
                                    required: "",
                                    oninvalid:
                                      "this.setCustomValidity('Please select a way to verify your account')",
                                    oninput: "this.setCustomValidity('')",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.getVerificationCodeForm.isEmail
                                    )
                                      ? _vm._i(
                                          _vm.getVerificationCodeForm.isEmail,
                                          null
                                        ) > -1
                                      : _vm.getVerificationCodeForm.isEmail,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.getVerificationCodeForm.isEmail,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.getVerificationCodeForm,
                                              "isEmail",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.getVerificationCodeForm,
                                              "isEmail",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.getVerificationCodeForm,
                                          "isEmail",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("form-row ") }, [
                            _c("input", {
                              class:
                                _vm.getClass("btn-login ") +
                                _vm.getClass("btn-block ") +
                                _vm.getClass("btn-primary "),
                              attrs: {
                                type: "submit",
                                disabled: _vm.getVerificationCodeForm.isLoading,
                              },
                              domProps: {
                                value: !_vm.getVerificationCodeForm.isLoading
                                  ? "NEXT"
                                  : "Loading...",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          !!_vm.getVerificationCodeForm.error
                            ? _c(
                                "div",
                                {
                                  class:
                                    _vm.getClass("form-row ") +
                                    _vm.getClass("content-center "),
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.getClass(
                                          "login-form-validation-error "
                                        ) +
                                        _vm.getClass("field-validation-error "),
                                    },
                                    [
                                      _vm._v(
                                        "\r\n                                    " +
                                          _vm._s(
                                            _vm.getVerificationCodeForm.error
                                          ) +
                                          "\r\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: _vm.getClass("goback-link "),
                                  attrs: { href: "javascript:void();" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.goBack.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v("Login to a different account?")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentStep === _vm.LoginActionEnum.ENTER_CODE,
                      expression: "currentStep === LoginActionEnum.ENTER_CODE",
                    },
                  ],
                  key: "emailVerificationForm",
                },
                [
                  _c(
                    "form",
                    {
                      class: _vm.getClass("form-inline"),
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.verifyOTP.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: _vm.getClass("mfa-container-main"),
                          attrs: { id: "EmailVerification" },
                        },
                        [
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("large-text ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _vm._v(
                                "\r\n                                Two-Step Verification\r\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("small-text2 ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _vm._v(
                                "\r\n                                Please Enter the 6 digit code we have sent to your login email address\r\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.emailVerificationForm.otp,
                                    expression: "emailVerificationForm.otp",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class:
                                  _vm.getClass("form-otp-text ") +
                                  _vm.getClass("numbersOnly "),
                                attrs: {
                                  type: "text",
                                  id: "otpText",
                                  pattern: "\\d*",
                                  maxlength: "6",
                                  required: "",
                                  oninvalid:
                                    "this.setCustomValidity('Please enter OTP!')",
                                  oninput: "this.setCustomValidity('')",
                                  tabindex: "0",
                                  spellcheck: "false",
                                },
                                domProps: {
                                  value: _vm.emailVerificationForm.otp,
                                },
                                on: {
                                  keypress: _vm.numbersOnly,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.emailVerificationForm,
                                      "otp",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { class: _vm.getClass("form-row ") }, [
                            _c("input", {
                              class:
                                _vm.getClass("btn-login ") +
                                _vm.getClass("btn-block ") +
                                _vm.getClass("btn-primary "),
                              attrs: {
                                type: "submit",
                                disabled: _vm.emailVerificationForm.isLoading,
                              },
                              domProps: {
                                value: !_vm.emailVerificationForm.isLoading
                                  ? "Submit Code"
                                  : "Submitting...",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("mfa-checkbox-div "),
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.emailVerificationForm.rememberMe,
                                    expression:
                                      "emailVerificationForm.rememberMe",
                                    modifiers: { trim: true },
                                  },
                                ],
                                class:
                                  _vm.getClass("rememberMe ") +
                                  _vm.getClass("checkbox-control "),
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.emailVerificationForm.rememberMe
                                  )
                                    ? _vm._i(
                                        _vm.emailVerificationForm.rememberMe,
                                        null
                                      ) > -1
                                    : _vm.emailVerificationForm.rememberMe,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.emailVerificationForm.rememberMe,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.emailVerificationForm,
                                            "rememberMe",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.emailVerificationForm,
                                            "rememberMe",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.emailVerificationForm,
                                        "rememberMe",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                "\r\n                                Remember me on this computer\r\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !!_vm.emailVerificationForm.error
                            ? _c(
                                "div",
                                {
                                  class:
                                    _vm.getClass("form-row ") +
                                    _vm.getClass("content-center "),
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.getClass(
                                          "login-form-validation-error "
                                        ) +
                                        _vm.getClass("field-validation-error "),
                                    },
                                    [
                                      _vm._v(
                                        "\r\n                                    " +
                                          _vm._s(
                                            _vm.emailVerificationForm.error
                                          ) +
                                          "\r\n                                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !!_vm.emailVerificationForm.message
                            ? _c(
                                "div",
                                {
                                  class:
                                    _vm.getClass("form-row ") +
                                    _vm.getClass("content-center "),
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      class:
                                        _vm.getClass("small-text ") +
                                        _vm.getClass("action-success "),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.emailVerificationForm.message
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class:
                                _vm.getClass("form-row ") +
                                _vm.getClass("content-center "),
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: _vm.getClass("goback-link "),
                                  attrs: {
                                    href: "javascript:void();",
                                    disabled:
                                      _vm.emailVerificationForm.isEmailLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.requestAnotherEmail.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\r\n                                    Didn't receive an email? Click here to request another"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentStep === _vm.LoginActionEnum.LOGIN_SUCCESS,
                      expression:
                        "currentStep === LoginActionEnum.LOGIN_SUCCESS",
                    },
                  ],
                  key: "loginSuccessView",
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.getClass("login-success"),
                      attrs: { id: "LoginSuccess" },
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.getClass("form-row ") +
                            _vm.getClass("content-center "),
                        },
                        [
                          _c("p", { class: _vm.getClass("large-text ") }, [
                            _vm._v("Login Successful!"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class:
                            _vm.getClass("form-row ") +
                            _vm.getClass("content-center "),
                        },
                        [
                          _c("p", { class: _vm.getClass("small-text ") }, [
                            _vm._v(
                              "\r\n                                Administrative features are now active for this session."
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class:
                            _vm.getClass("form-row ") + _vm.getClass("pb-0 "),
                        },
                        [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c(
                              "button",
                              {
                                class:
                                  _vm.getClass("btn-login ") +
                                  _vm.getClass("btn-block ") +
                                  _vm.getClass("btn-primary "),
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.continueChatAsAdmin()
                                  },
                                },
                              },
                              [_vm._v("Continue")]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentStep === _vm.LoginActionEnum.ACCOUNT_LOCKED,
                      expression:
                        "currentStep === LoginActionEnum.ACCOUNT_LOCKED",
                    },
                  ],
                  key: "accountLockedView",
                },
                [
                  _c("div", { attrs: { id: "AccountLock" } }, [
                    _c(
                      "div",
                      {
                        class:
                          _vm.getClass("form-row ") +
                          _vm.getClass("content-center "),
                      },
                      [
                        _c("p", { class: _vm.getClass("error-large-text ") }, [
                          _vm._v("Account Locked"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.getClass("form-row ") +
                          _vm.getClass("content-center "),
                      },
                      [
                        _c("p", { class: _vm.getClass("small-text2") }, [
                          _vm._v(
                            "Too many failed attempts were made to access your account. Please get in touch with an administrator on your account or submit a request ticket to our support team "
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: {
                                color: "#337ab7",
                                "text-decoration": "underline",
                                "font-weight": "bold",
                              },
                              attrs: {
                                target: "_blank",
                                href: "https://satisfi-labs.atlassian.net/servicedesk/customer/portal/10/group/39/create/167",
                                rel: "noopener noreferrer",
                              },
                            },
                            [_vm._v("here")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentStep === _vm.LoginActionEnum.LOGOUT_SUCCESS,
                      expression:
                        "currentStep === LoginActionEnum.LOGOUT_SUCCESS",
                    },
                  ],
                  key: "logoutSuccessView",
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.getClass("login-success"),
                      attrs: { id: "LogoutSuccess" },
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.getClass("form-row ") +
                            _vm.getClass("content-center "),
                        },
                        [
                          _c("p", { class: _vm.getClass("large-text ") }, [
                            _vm._v("Logged Out"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class:
                            _vm.getClass("form-row ") +
                            _vm.getClass("content-center "),
                        },
                        [
                          _c("p", { class: _vm.getClass("small-text ") }, [
                            _vm._v(
                              "\r\n                                Administrative features are no longer active."
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class:
                            _vm.getClass("form-row ") + _vm.getClass("pb-0 "),
                        },
                        [
                          _c("div", { class: _vm.getClass("form-element ") }, [
                            _c(
                              "button",
                              {
                                class:
                                  _vm.getClass("btn-login ") +
                                  _vm.getClass("btn-block ") +
                                  _vm.getClass("btn-primary "),
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.continueChatAsNonAdmin()
                                  },
                                },
                              },
                              [_vm._v("Continue")]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }