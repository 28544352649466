const prependZero = (value: number) => {
    if (value.toString().length == 1) {
        return '0' + value;
    }
    return value;
}
export const getTimeStamp = (payload: Date) => {
    const todayDate = new Date();
    let formattedDate = payload.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    if (!(payload.getDate() === todayDate.getDate() && payload.getMonth() === todayDate.getMonth() && payload.getFullYear() === todayDate.getFullYear())) {
        formattedDate = payload.getFullYear() + "-" + prependZero(payload.getMonth() + 1) +
            "-" + prependZero(payload.getDate()) + " " + formattedDate;
    }
    return formattedDate;
}