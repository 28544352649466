export enum STORE_MUTATION {
    AddMessage = "addMessage",
    UpdateMessageStatus = "updateMessageStatus",
    SetUserId = "setUserId",
    SetIsConfigLoaded = "setIsConfigLoaded",
    SetBotId = "setBotId",
    SetCompanyId = "setCompanyId",
    SetCompanyToken = "setCompanyToken",
    SetSendMsgUrl = "setSendMsgUrl",
    SetMaintainState = "setMaintainState",
    SetMessages = "setMessages",
    SetShowInfoPanel = "setShowInfoPanel",
    SetErrorMessage = "setErrorMessage",
    SetShowTypingIndicator = "setShowTypingIndicator",
    SetShowProcessingMessage = "setShowProcessingMessage",
    SetProcessingDisplayIcon = "setProcessingDisplayIcon",
    SetProcessingDisplayText = "setProcessingDisplayText",
    RemoveAllButtons = "removeAllButtons",
    SetIsBotHasAvatar = "setIsBotHasAvatar",
    SetConvId = "setConvId",
    SetPageId = "setPageId",
    SetLastMessageId = "setLastMessageId",
    SetShouldShowBanner = "setShouldShowBanner",
    AddGeneralTimeStamp = "addGeneralTimeStamp",
    AddFetchMsgStamp = "addFetchMsgTimeStamp",
    AddPrevMessages = "addPrevMessages",
    RemoveStampMessage = "removeStampMessage",
    SetIsAppInAdaMode = "setIsAppInAdaMode",
    PrependMessages = "prependMessages",
    PrependTimeStamp = "prependTimeStamp",
    RemoveMessage = "removeMessage",
    SetRetryWarningCount = "SetRetryWarningCount",
    SetRetryInterval = "SetRetryWarningInterval",
    UpdateMessageCacheID = "updateMessageCacheID",
    UpdateMessageStructure = "updateMessageStructure",
    SetIsBrowseUrlOnSametab = "setIsBrowseUrlOnSametab",
    SetIsShowHeaderInIframe = "setIsShowHeaderInIframe",
    SetIsHideTitleBar = "setIsHideTitleBar",
    SetSatisfiURL = "setSatisfiURL",
    SetHeaderImage = "setHeaderImage",
    SetBackgroundImage = "setBackgroundImage",
    SetHeaderImageDestinationURL = "setHeaderImageDestinationURL",
    SetTypingMessageSecondsInterval = "setTypingMessageSecondsInterval",
    SetTypingType = "setTypingType",
    SetIsUserHasAvatar = "setIsUserHasAvatar",
    SetAWSServerURL = "setAWSServerURL",
    SetAWSThemeFilePath = "setAWSThemeFilePath",
    SetAWSImageLogoPath = "setAWSImageLogoPath",
    SetAWSPopupConfigPath = "setAWSPopupConfigPath",
    SetPopupId = "setPopupId",
    SetPageReferralUrl = "setPageReferralUrl",
    SetDisableInput = "setDisableInput",
    SetDefaulthideInputContainerSetting = "setDefaulthideInputContainerSetting",
    SetLastFocusableElement = "setLastFocusableElement",
    SetBannerText = "setBannerText",
    SetUserLng = "setUserLng",
    SetUserLat = "setUserLat",
    SetLocationBlocked = "setLocationBlocked",
    SetUserIdSourceTypeId = "setUserIdSourceTypeId",
    ClearScreen = "clearScreen",
    SetParentSiteUrl = "setParentSiteUrl",
    SetParentSiteReferralUrl = "setParentSiteReferralUrl",
    SetRequestResponseReceived = "setRequestResponseReceived",
    SetFocusedWindow = "setFocusedWindow",
    SetIsSignalRConnected = "setIsSignalRConnected",
    SetIsAnchorTagClickedSametabNewtab = "setIsAnchorTagClickedSametabNewtab",
	SetClientVersion = "setClientVersion",
	SetMinimumClientVersion = "setMinimumClientVersion",
	SetIsTilesOpenedInIframe = "setIsTilesOpenedInIframe",
    SetCFHeader = "setCFHeader",
    SetShowAdminLoginModal = "setShowAdminLoginModal",
    SetShowConsentModal = "setShowConsentModal",
    SetIsAdminMode = "setIsAdminMode",
    SetEnableAdminMode = "setEnableAdminMode",
    SetShowModal = "setShowModal",
    SetWebApiAddress = "setWebApiAddress",
    SetDashboardAddress = "setDashboardAddress",
    SetAttemptNoofInvalidCode = "setAttemptNoofInvalidCode",
    SetAttemptNoofResendCode = "setAttemptNoofResendCode",
    SetMFAExpiration = "setMFAExpiration",
    SetMFAEnableDateTime = "setMFAEnableDateTime",
    SetAuthToken = "setAuthToken",
    SetAuthTokenExpiration = "setAuthTokenExpiration",
    SetIsMFAEnabled = "setIsMFAEnabled",
    SetRefreshTask = "setRefreshTask",
    SetIsTermsConsentEnabled = "setIsTermsConsentEnabled",
    SetTermsConsentPayload = "setTermsConsentPayload",
    SetTermsConsentExpiration = "setTermsConsentExpiration",
    SetIsFirstUserMessage = "setIsFirstUserMessage",
    SetConsentStatusOptions = "setConsentStatusOptions",
    SetIsTermsOfServiceAccepted = "setIsTermsOfServiceAccepted",
    SetFormInput = "setFormInput",
    ClearFormInputs = "clearFormInputs",
    SetShowWelcomeBanner = "setShowWelcomeBanner",
    SetIsNewMessageProcessing = "setIsNewMessageProcessing",
    SetIsMaintainStateMessages = "setIsMaintainStateMessages",
    SetEnableMessageNavigation = "setEnableMessageNavigation",
}
