import { Util } from "../util";

/**
 * Checks if the given URL represents an SVG image.
 *
 * @param url - The URL to check.
 * @returns `true` if the URL represents an SVG image, `false` otherwise.
 */
export const isSvgImage = function (url: unknown): boolean {
	try {
    if (typeof url !== "string" || Util.isNullOrEmpty(url)) {
      return false;
    }

    // Create a URL object. If url is relative, it will be resolved against the current document's base URL.
    const parsedUrl = new URL(url, window.location.href);
    const pathname = parsedUrl.pathname;

    if (pathname === null || pathname === undefined || pathname.trim() === "") {
      return false;
    }

		const lastDotIndex = pathname.lastIndexOf(".");
		if (lastDotIndex === -1) {
			return false; // No extension found
		}

		const extension = pathname.substring(lastDotIndex + 1).toLowerCase();
    return extension === "svg";
	} catch (error) {
		// Handle potential errors during string manipulation (e.g., unexpected format)
		//console.error("Error checking SVG:", error);
		return false;
	}
};

// Create various test cases for the isSvgImage function
/*
isSvgImage(""); // false
isSvgImage("https://example.com/image.jpg"); // false
isSvgImage("https://example.com/image.svg"); // true
isSvgImage("https://example.com/image.SVG"); // true
isSvgImage("https://example.com/image.svg?query=1"); // true
isSvgImage("https://example.com/image.svg?query=1&another=2"); // true
isSvgImage("https://example.com/image.svg?query=1&another=2#fragment"); // true
isSvgImage("https://example.com/image.svg#fragment"); // true
*/
