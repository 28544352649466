import { render, staticRenderFns } from "../views/chat.html?vue&type=template&id=2d07cb3a&external"
import script from "../scripts/chat.ts?vue&type=script&lang=ts&external"
export * from "../scripts/chat.ts?vue&type=script&lang=ts&external"
import style0 from "../css_builds/components/chat-messages.css?vue&type=style&index=0&lang=css&external"
import style1 from "../css_builds/components/chat-bubbles.css?vue&type=style&index=1&lang=css&external"
import style2 from "../css_builds/components/buttons.css?vue&type=style&index=2&lang=css&external"
import style3 from "../css_builds/components/media.css?vue&type=style&index=3&lang=css&external"
import style4 from "../css_builds/components/timestamp.css?vue&type=style&index=4&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\beta\\SatisfiWebChat\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d07cb3a')) {
      api.createRecord('2d07cb3a', component.options)
    } else {
      api.reload('2d07cb3a', component.options)
    }
    module.hot.accept("../views/chat.html?vue&type=template&id=2d07cb3a&external", function () {
      api.rerender('2d07cb3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/chat.vue"
export default component.exports