var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.getClass("chat-modal-container") }, [
    _vm.messageSettings &&
    _vm.messageSettings.modalLoadBehavior &&
    (_vm.messageSettings.modalLoadBehavior ===
      _vm.LoadBehaviorEnum.PLACEHOLDER_ONLY ||
      _vm.messageSettings.modalLoadBehavior ===
        _vm.LoadBehaviorEnum.AUTO_OPEN_WITH_PLACEHOLDER)
      ? _c(
          "div",
          {
            class: _vm.getClass("bubble ") + _vm.getClass("chat-modal-bubble"),
            on: { click: _vm.openModal },
          },
          [
            _c("div", { class: _vm.getClass("bubble-label") }, [
              _c("p", [_vm._v(_vm._s(_vm.placeholderLabel))]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.getClass("bubble-icon") }, [
              !!_vm.isPlaceholderIconImage
                ? _c("img", {
                    style: _vm.iconImageStyle,
                    attrs: {
                      src: _vm.placeholderIcon,
                      alt: _vm.placeholderLabel,
                    },
                  })
                : _c("span", {
                    style: _vm.iconImageStyle,
                    domProps: { innerHTML: _vm._s(_vm.placeholderIcon) },
                  }),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class:
          _vm.getClass("chat-modal-backdrop ") +
          " " +
          (!_vm.showModal ? _vm.getClass("hide-modal") : "") +
          " " +
          (_vm.messageSettings &&
          _vm.messageSettings.style &&
          _vm.messageSettings.style == "slide"
            ? _vm.getClass("slide-fade")
            : "") +
          " " +
          (_vm.messageSettings &&
          _vm.messageSettings.style &&
          _vm.messageSettings.style == "center"
            ? _vm.getClass("modal-center")
            : ""),
      },
      [
        _c(
          "div",
          {
            class: _vm.getClass("chat-modal "),
            attrs: { role: "dialog", "aria-label": "Modal" },
          },
          [
            _c("header", { class: _vm.getClass("chat-modal-header") }, [
              _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.messageSettings &&
                        _vm.messageSettings.enableCloseIcon != null
                          ? !!_vm.messageSettings.enableCloseIcon
                          : true,
                      expression:
                        "messageSettings && messageSettings.enableCloseIcon != null ? !!messageSettings.enableCloseIcon : true",
                    },
                  ],
                  class: _vm.getClass("btn-modal-close"),
                  attrs: {
                    type: "button",
                    "aria-label": "Close modal",
                    title: "Close modal",
                  },
                  on: { click: _vm.closeModal },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "section",
              { class: _vm.getClass("chat-modal-body") },
              [
                _vm._l(_vm.components, function (component, index) {
                  return [
                    component.type == "text"
                      ? _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.decodeTags(component.content)
                            ),
                          },
                        })
                      : component.type == "html"
                      ? _c("p", {
                          domProps: { innerHTML: _vm._s(component.content) },
                        })
                      : component.type == "markdown"
                      ? _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.marked(component.content)),
                          },
                        })
                      : component.type == "input"
                      ? _c("FormInput", { attrs: { component: component } })
                      : component.type == "button" || component.type == "link"
                      ? _c("Button", {
                          attrs: {
                            component: component,
                            messageContentsettings: _vm.messageContentsettings,
                          },
                        })
                      : component.type == "image"
                      ? _c("Img", { attrs: { component: component } })
                      : component.type == "line"
                      ? _c("hr")
                      : component.type == "break"
                      ? _c("br")
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }