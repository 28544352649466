var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "chat_body",
      class: _vm.getClass("chatBody"),
      attrs: { id: "divChatBody" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "satisfi_fade" } },
        [
          _c("Banner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.shouldShowBanner == true || _vm.showWelcomeBanner == true,
                expression: "shouldShowBanner==true || showWelcomeBanner==true",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPageLoaded == false ? _c("Loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.getClass("chatContainerBgImage") }),
      _vm._v(" "),
      _c(
        "main",
        {
          class:
            (_vm.isMobileActive && _vm.isMobileActive !== null
              ? _vm.getClass("messagesMobile")
              : "") +
            " " +
            _vm.getClass("messages"),
          attrs: { role: "main" },
        },
        [
          _vm._l(_vm.messages, function (message, msgIndex) {
            return [
              _c(
                "section",
                {
                  class: {
                    [_vm.getClass("bot")]:
                      message.type == 0 || message.type == 3,
                    [_vm.getClass("user")]: message.type == 1,
                    [_vm.getClass("timestamp")]: message.type == 2,
                    [_vm.getClass("avatar")]:
                      _vm.isBotHasAvatar &&
                      message.type == 0 &&
                      !_vm.isBotMessageCard(message),
                    [_vm.getClass("associate")]:
                      _vm.isUserHasAvatar && message.type == 3,
                  },
                  attrs: {
                    tabIndex:
                      _vm.enableMessageNavigation && message.type != 2 ? 0 : -1,
                    role:
                      _vm.enableMessageNavigation && message.type != 2
                        ? "region"
                        : null,
                    "aria-atomic":
                      _vm.enableMessageNavigation && message.type != 2
                        ? "true"
                        : null,
                  },
                },
                [
                  _c("span", {
                    class: _vm.getClass("visually-hidden"),
                    domProps: {
                      textContent: _vm._s(_vm.getMessagePrefix(message.type)),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._l(
                        message.contents.structures,
                        function (structure, structureIndex) {
                          return [
                            structure.type == "bubble"
                              ? _c("Bubble", {
                                  class:
                                    structure.settings &&
                                    structure.settings.forceMaxWidth
                                      ? _vm.getClass("bubbleMaxWidth")
                                      : "",
                                  attrs: {
                                    components: structure.components,
                                    messageContentsettings: Array.isArray(
                                      message.contents.settings
                                    )
                                      ? {}
                                      : message.contents.settings,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            structure.type == "card"
                              ? _c("Card", {
                                  class:
                                    structure.settings &&
                                    structure.settings.forceMaxWidth
                                      ? _vm.getClass("cardMaxWidth")
                                      : "",
                                  attrs: {
                                    components: structure.components,
                                    messageContentsettings: Array.isArray(
                                      message.contents.settings
                                    )
                                      ? {}
                                      : message.contents.settings,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            structure.type == "modal"
                              ? _c("Modal", {
                                  attrs: {
                                    components: structure.components,
                                    messageSettings: structure.settings
                                      ? structure.settings
                                      : {},
                                    messageContentsettings: Array.isArray(
                                      message.contents.settings
                                    )
                                      ? {}
                                      : message.contents.settings,
                                    messageId: message.id,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            structure.type == "time"
                              ? _c("div", [
                                  _c("p", { class: _vm.getClass("time") }, [
                                    _vm._v(
                                      _vm._s(structure.components[0].content)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            structure.type == "fetch_msg"
                              ? _c("div", { class: _vm.getClass("history") }, [
                                  _c("p", { class: _vm.getClass("time") }, [
                                    _vm._v(
                                      _vm._s(structure.components[0].content)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isArrayLengthGt0(message.contents.quickReplies)
                    ? _c("QuickReply", {
                        attrs: {
                          msgIndex: msgIndex,
                          messageContentsettings: Array.isArray(
                            message.contents.settings
                          )
                            ? {}
                            : message.contents.settings,
                          data: message.contents.quickReplies,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  message.status === "failed"
                    ? _c("div", { class: _vm.getClass("status") }, [
                        _c("p", [_vm._v("Sending...")]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
          _vm._v(" "),
          _vm.showTypingIndicator ? _c("TypingIndicator") : _vm._e(),
          _vm._v(" "),
          !_vm.showTypingIndicator && _vm.showProcessingMessage
            ? _c("ProcessingMessage")
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.hideTagLine
        ? _c(
            "div",
            {
              class:
                (_vm.isMobileActive && _vm.isMobileActive !== null
                  ? _vm.getClass("taglineMobile")
                  : "") +
                " " +
                _vm.getClass("tagline"),
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.satisfilabs.com",
                    target: "_blank",
                    rel: "noopener noreferrer",
                    "aria-hidden": "true",
                    tabindex: "-1",
                  },
                },
                [_vm._v("Powered by Satisfi Labs")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAdminLoginModal ? _c("LoginModal") : _vm._e(),
      _vm._v(" "),
      _vm.showConsentModal ? _c("ConsentModal") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }