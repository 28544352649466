import Vue from "vue";
import { Component } from "vue-property-decorator";
import { STORE_MUTATION } from "../enums/store_mutation";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";
import { IConfig } from "../interfaces/config";
import { isMobile } from "../helpers/is_mobile";

@Component
export default class InfoPanel extends Vue {

    isMobileActive = isMobile.any() || this.$store.state.isIFrameLoaded;

    legalEntries = [];

    mounted() {
        this.listenEvents();
    }

    listenEvents() {
        __vueEvent.$once(EVENTS.SetConfig, (config: IConfig) => {
            this.legalEntries = config.legalEntries;
        })
    }

    hideInfo() {
        this.$store.commit(STORE_MUTATION.SetShowInfoPanel, false);
    }
}
