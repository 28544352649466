import Vue from "vue";
import { Component } from "vue-property-decorator";
import { MessageStructureComponent, MessageContentSettings } from "../types";
import { mapState } from "vuex";
import { ActionHandler } from "../helpers/action_handler";
import { __vueEvent } from "../constant";
import { store } from "../store/store";

@Component({
    props: {
        component: Object,
        structure: Object,
        messageContentsettings: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: mapState(['prefixClassName'])
})
export default class Button extends Vue {

    component!: MessageStructureComponent;
    messageContentsettings!: MessageContentSettings

    get type() {
        return this.component.type;
    }

    get size() {
        const size = (this.component.style as any).size;
        return size == null ? '' : size;
    }

    /**
     * The function `buttonWrapperClass` returns a string that represents the class name for a button
     * wrapper element, based on the size of the component's style.
     * @returns a string value. If the size is not medium or if there is an error, an empty string will
     * be returned.
     */
    get buttonWrapperClass() {
        try {
            const tempSize = (this.component.style as any).size;
            let size = tempSize == null ? '' : tempSize;
            size = size || '';

            // Check if size is a string
            if (typeof size !== 'string') {
                size = size.toString();
            }

            // Check if size is medium
            if (size && size.toLowerCase().includes('medium')) {
                return this.getClassName('button-wrapper') + ' ' + this.getClassName(size);
            }

            // If size is not medium, return empty string
            return '';
        } catch (error) {
            return '';
        }
    }

    /**
     * The function returns a class name by concatenating a prefix with a given value.
     * @param value - The value parameter is a string representing the class name that you want to
     * append to the prefixClassName.
     * @returns the concatenation of the value parameter and the prefixClassName property of the
     * store.state object.
     */
    getClassName(value) {
        return store.state.prefixClassName + value;
    }

    onElClick() {
        ActionHandler.handleBtnClickAction(this.component, this.messageContentsettings);
    }
}
