import { STORE_MUTATION, MESSAGE_COMPONENT_TYPE, MESSAGE_TYPE, EVENTS, MESSAGE_STRUCTURE_TYPE } from "../enums";
import { MessageContent } from "../types";
import { store } from "../store/store";
import { getMessageType, preFetchImage } from ".";
import { IMessage } from "../interfaces";
import { __vueEvent } from "../constant";
import { onExceptionOccured } from "../helpers/on_exception_occured";
import { setCookie } from "./cookie_handler";

/**
 * Handles the messages received from the server.
 *
 * @param {MessageContent[]} messages - An array of MessageContent objects representing the received messages.
 */
export const onMessageFromServer = async function (messages: MessageContent[]) {
  try {

    // Get the first message from the MessageContent array
    const message = messages && messages.length > 0 ? messages[0] : {};

    try {
      // Check if type property is present in the message and it is not empty
      // and the type is "message_received_status"
      if (isValidResponse(message)) {
        //update the messageCache id
        const extMsgId = message.recordInfo.extMsgId || "";
        const newMsgId = message.recordInfo.messageCacheId || "";

        // Check if we have both the extMsgId and the newMsgId
        if (extMsgId && newMsgId) {
          store.commit(STORE_MUTATION.UpdateMessageCacheID, { id: extMsgId, newId: newMsgId } as IMessage);

          // Update the failed message status to "ok"
          store.commit(STORE_MUTATION.UpdateMessageStatus, { id: newMsgId, status: "ok" } as IMessage);

          // Update the id of the timestamp message
          const extMsgTimeId = extMsgId + MESSAGE_STRUCTURE_TYPE.Time;
          const newMsgTimeId = newMsgId + MESSAGE_STRUCTURE_TYPE.Time;
          store.commit(STORE_MUTATION.UpdateMessageCacheID, { id: extMsgTimeId, newId: newMsgTimeId } as IMessage);
        }

        return;
      }
    } catch (error) {
      //console.log('Error in onMessageFromServer - New Logic:', error);
    }

    const typingTimeout = (message.typingIndicator as any).timeout;
    //store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
    store.commit(STORE_MUTATION.SetTypingType, getMessageType(message.recordInfo.senderType));
    await preFetchImage(message);
    //message.recordInfo.messageDateTime = new Date();

    // Update messageDateTime for all the messages in the array
    messages.forEach((msg) => {
      msg.recordInfo.messageDateTime = new Date();
    });

    if (typingTimeout > 0) {
      store.commit(STORE_MUTATION.SetShowTypingIndicator, true);
      setTimeout(() => {
        showMessageOnScreen(messages);

        try {
          localStorage.setItem(message.keys.extConvId, message.messageTime);
        }
        catch (ex) {
          setCookie(message.keys.extConvId, message.messageTime);
        }
        //Save time in local storage

        // store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
        // store.commit(STORE_MUTATION.ClearScreen);
        // __vueEvent.$emit(EVENTS.ScrollChatToTop);
        // store.commit(STORE_MUTATION.AddMessage, {
        //   contents: message,
        //   type: getMessageType(message.recordInfo.senderType),
        //   id: message.recordInfo.messageCacheId,
        //   status: "ok"
        // } as IMessage);
      }, typingTimeout);
    }
    else {
      showMessageOnScreen(messages);
      //Save time in local storage

      try {
        localStorage.setItem(message.keys.extConvId, message.messageTime);
      }
      catch (ex) {
        setCookie(message.keys.extConvId, message.messageTime);
      }
      // store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
      // store.commit(STORE_MUTATION.ClearScreen);
      // __vueEvent.$emit(EVENTS.ScrollChatToTop);
      // store.commit(STORE_MUTATION.AddMessage, {
      //   contents: message,
      //   type: getMessageType(message.recordInfo.senderType),
      //   id: message.recordInfo.messageCacheId,
      //   status: "ok"
      // } as IMessage);
    }
  }
  catch (ex) {
    store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
    store.commit(STORE_MUTATION.SetShowProcessingMessage, false);
  }
};

/**
 * Checks if the "type" property exists and is not null, empty, or consists only of whitespaces.
 * If "type" is a string, leading and trailing whitespaces are trimmed.
 *
 * @param message The message object to check.
 * @returns True if "type" exists and is valid, false otherwise.
 */
function isValidResponse(message: MessageContent): boolean {
  try {
    if (!message || !message.type) {
      return false;
    }

    if (typeof message.type === 'string') {
      // Trim leading and trailing whitespace if "type" is a string
      return message.type.trim() !== '' && message.type.trim().toLowerCase() === "message_received_status";
    }

    return false; // If "type" is not a string, consider it invalid
  } catch (error) {
    //console.log('Error in isValidResponse: ', error);
    return false;
  }
}

/**
 * Adds the messages to the chat screen.
 *
 * @param {MessageContent[]} messages - An array of MessageContent objects representing the received messages.
 */
function showMessageOnScreen(messages: MessageContent[]) {
  try {
    store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
    store.commit(STORE_MUTATION.SetShowProcessingMessage, false);

    // Loop through the messages and add them to the chat screen
    messages.forEach((message) => {
      // Check if the message has a "settings" property and a "clearScreen" property set to true
      if (message.settings && message.settings.clearScreen) {
        store.commit(STORE_MUTATION.ClearScreen);
      }

      // check if the messages array stored in state has the message with the same id already
      // if it does, then do not add the message to the chat screen.
      // id for the welcome message is "1"
      //const isWelcomeMessage = message.recordInfo.messageCacheId === "1";
      const isMessageAlreadyInStore = store.state.messages.some(m => m.id === message.recordInfo.messageCacheId);
      if (isMessageAlreadyInStore) {
        return; // Skip duplicate message
      }

      // Add the message to the chat screen
      store.commit(STORE_MUTATION.AddMessage, {
        contents: message,
        type: getMessageType(message.recordInfo.senderType),
        id: message.recordInfo.messageCacheId,
        status: "ok"
      } as IMessage);
    });

    // Check if the message is a maintain state message
    if (messages && messages.length > 1 && store.state.isMaintainStateMessages) {
      store.commit(STORE_MUTATION.SetIsMaintainStateMessages, false);
    }

  } catch (ex) {
    store.commit(STORE_MUTATION.SetShowTypingIndicator, false);
    store.commit(STORE_MUTATION.SetShowProcessingMessage, false);
    store.commit(STORE_MUTATION.SetIsMaintainStateMessages, false);
  }
}
