import { store } from "../store/store";

declare var SatisfiApp;

export const getAppUrl = function () {
    if (store.state.isIFrameLoaded) {
        return store.state.satisfiURL;
    }
    return "/";
}
