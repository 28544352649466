import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { isMobile } from "../helpers/is_mobile";

@Component({
    computed: mapState(['errorMessage'])
})
export default class Error extends Vue {
    isMobileActive = isMobile.any() || this.$store.state.isIFrameLoaded;
}
