import { preFetchImage } from "./pre_fetch_Image";
import { ChatService } from "../service/chat_service";
import { MessageContent } from "../types";
import { CACHE_ID_TYPE } from "../enums";


/**
 * Get chat messages from the EBM Server
 *
 * @param {number} msgId - `id` of the last message stored in client side state.
 * @param {string} cacheIdType - Check if it is `afterMessageCacheId` or `beforeMessageCacheId`.
 * @param {boolean} recoonect - Is the call from the SignalR retryConnection method, then `true` else `false`.
 * @param {string} clientVersion - The `ClientVersion` stored locally on client side. Optional parameter w/ default value "".
 * @param {string} sourceInfo - The source of the call. Optional parameter w/ default value "".
 *
 * @returns {Promise<MessageContent[]>} msgs - An array of promises containing messages or an empty array.
 */
export const getMessageFromServer = async function(
  msgId,
  cacheIdType,
  recoonect,
  clientVersion = "",
  sourceInfo = ""
) {
  let response;
  try {
    response = await new ChatService().getMessage(msgId, cacheIdType, recoonect, clientVersion, sourceInfo);
  } catch {
    return [];
  }
  if (response && response.data && response.data.length > 0) {
    let fetchedImagePromise: Promise<
      MessageContent
    >[] = (response.data as MessageContent[]).map(function (msg) {
      return preFetchImage(msg);
    });
    const msgs = await Promise.all(fetchedImagePromise);
    console.log("fetch message from ebm after signalr reconnect at: ", new Date());
    return msgs;
  } else if (response && !!response.isReload && response.isReload === true) {
	  // If isReload is true then simple reload current page
	  // This property is coming from GetMessage method in Defaultcontroller.cs file
	  if (typeof window !== "undefined") {
        window.location.reload();
      }
  }
  return [];
};
