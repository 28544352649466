import { store } from "../store/store";
import {
    STORE_MUTATION,
    MESSAGE_TYPE,
    MESSAGE_STRUCTURE_TYPE,
    MESSAGE_COMPONENT_TYPE,
    EVENTS
} from "../enums";
import { IMessage } from "../interfaces/message";
import { ChatService } from "../service/chat_service";
import { getUniqId } from "./get_uniq_id";
import { Util } from "../util";
import { __vueEvent } from "../constant";
import { onExceptionOccured } from "../helpers/on_exception_occured";
import { isMobile } from "../helpers/is_mobile";
import { MessageOptions } from "../types";

/**
 * Determines whether the terms consent should be handled or not.
 * @param contents The contents of the user message.
 * @returns A boolean indicating whether the terms consent should be handled or not.
 */
function shouldHandleTermsConsent(contents: any): boolean {
    try {
        return store.state.isTermsConsentEnabled && store.state.isFirstUserMessage
            && !Util.isNullOrEmpty(contents) && Util.isObjectWithNonEmptyArray(contents, "structures")
            && !Util.isTermsConsentAccepted(store.state.pageId, store.state.termsConsentExpiration);
    } catch (ex) {
        console.log("[addUserMessage.ts] An error has occurred \n-> Inside shouldHandleTermsConsent -> Error: ", ex);
        return false;
    }
}

/**
 * Handles the terms consent for the user message.
 * @param msg The message to be sent.
 * @param shouldSend A boolean indicating whether the message should be sent or not.
 * @param hideInChat A boolean indicating whether the message should be hidden in the chat or not.
 * @param messageStructure The structure of the message.
 */
function handleTermsConsent(msg: string, shouldSend: boolean, hideInChat: boolean, messageStructure: any): void {
    try {
        // First, show the consent modal
        store.commit(STORE_MUTATION.SetShowConsentModal, true);

        // Second, save the addUserMessage parameters to the store
        const options: MessageOptions = {
            msg: msg,
            shouldSend: shouldSend,
            hideInChat: hideInChat,
            messageStructure: messageStructure
        };
        store.commit(STORE_MUTATION.SetConsentStatusOptions, options);
    } catch (ex) {
        store.commit(STORE_MUTATION.SetConsentStatusOptions, null);
        console.log("[addUserMessage.ts] An error has occurred \n-> Inside handleTermsConsent -> Error: ", ex);
    } finally {
        // Finally, set the request response received flag to true
        store.commit(STORE_MUTATION.SetIsFirstUserMessage, false);
        store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
    }
}

/**
 * Adds a user message to the chat and sends it to the server.
 * @param msg The message to be sent.
 * @param shouldSend A boolean indicating whether the message should be sent or not. Default is true.
 * @param hideInChat A boolean indicating whether the message should be hidden in the chat or not. Default is false.
 * @param messageStructure The structure of the message. Default is null.
 * @returns The message payload that was sent.
 */
export const addUserMessage = async function (
    msg: string,
    shouldSend: boolean = true,
    hideInChat: boolean = false,
    messageStructure: any = null
) {
    if (!store.state.requestResponseReceived) return;
    store.commit(STORE_MUTATION.SetRequestResponseReceived, false);

    let isBrowseUrlOnSametab = false;
    if (Util.isNullOrEmpty(msg)) {
        return;
    }

    if (messageStructure != null && messageStructure.clickAction &&
        messageStructure.clickAction.settings && messageStructure.clickAction.settings.browserTarget === "sametab") {
        isBrowseUrlOnSametab = true;
    }

    store.commit(STORE_MUTATION.SetIsMaintainStateMessages, false);

    // Get the consent modal message settings
    let payload = store.state.termsConsentPayload || "";
    let contents = Util.validateAndParsePayload(payload);

    // Handle terms consent
    if (shouldHandleTermsConsent(contents)) {
        handleTermsConsent(msg, shouldSend, hideInChat, messageStructure);
        return;
    }

    // Continue with sending message
    const service = new ChatService();
    const msgId = getUniqId();
    const msgPayload = {
        type: MESSAGE_TYPE.User,
        contents: {
            structures: [
                {
                    type: MESSAGE_STRUCTURE_TYPE.Bubble,
                    components: [
                        {
                            type: MESSAGE_COMPONENT_TYPE.Text,
                            content: msg
                        }
                    ]
                }
            ],
            recordInfo: {
                messageDateTime: new Date()
            }
        },
        status: "ok",
        id: msgId
    } as IMessage;
    if (!hideInChat) {
        store.commit(STORE_MUTATION.AddMessage, msgPayload);
    }
    if (shouldSend) {
        try {
            //const service = new ChatService();
            // Before we brodcast this message we need to encode <> tags,
            // so that we can display them properly inside the Dashboard inbox chat view
            const found = new RegExp(['&lt;', '&gt;'].join("|")).test(msg);
            if (found) {
                msg = msg.replace(/&lt;/g, '&amp;amp;lt;').replace(/&gt;/g, '&amp;amp;gt;');
            }

            const newMessageResponse = await service.sendMessage({
                content: msg,
                type: "text",
                settings: { inputHidden: hideInChat }
            }, null, null, msgId);

            if(!store.state.isNewMessageProcessing) {
                store.commit(STORE_MUTATION.UpdateMessageCacheID, {
                    id: msgId,
                    newId: JSON.parse(newMessageResponse.response).recordInfo.messageCacheId
                } as IMessage);
            } else {
                // Check if the newMessageResponse has "response" property
                // and if its value is a string and "1"
                if (newMessageResponse.response === "1") {
                    store.commit(STORE_MUTATION.UpdateMessageStatus, { id: msgId, status: "ok" } as IMessage);
                } else {
                    store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
                    store.commit(STORE_MUTATION.UpdateMessageStatus, { id: msgId, status: "failed" } as IMessage);
                }
            }
        } catch (ex) {
            store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
            store.commit(STORE_MUTATION.UpdateMessageStatus, {
                id: msgId,
                status: "failed"
            } as IMessage);

            // Add client side error handler
            //onExceptionOccured("[addUserMessage.ts] An error has occurred -> shouldSend is true -> Error: ", ex);
        }
    }
    if (messageStructure != null) {
        try {
            //const service = new ChatService();
            const newMessageResponse = await service.sendMessage(messageStructure, null, null, msgId);
            if(!store.state.isNewMessageProcessing) {
                store.commit(STORE_MUTATION.UpdateMessageCacheID, {
                    id: msgId,
                    newId: JSON.parse(newMessageResponse.response).recordInfo.messageCacheId
                } as IMessage);
            } else {
                // Check if the newMessageResponse has "response" property
                // and if its value is a string and "1"
                if (newMessageResponse.response === "1") {
                    store.commit(STORE_MUTATION.UpdateMessageStatus, { id: msgId, status: "ok" } as IMessage);
                } else {
                    throw new Error("Failed to send message");
                }
            }
        } catch (ex) {
            // console.log("Exception occured");
            // alert("Exception occured");
            if (!store.state.isBrowseUrlOnSametab && !isMobile.any()) {
                store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
                store.commit(STORE_MUTATION.UpdateMessageStatus, {
                    id: msgId,
                    status: "failed"
                } as IMessage);

                //Update message structure for failed button click payload.
                store.commit(STORE_MUTATION.UpdateMessageStructure, {
                    id: msgId,
                    buttonMessageStructure: messageStructure
                } as IMessage);
            }

            store.commit(STORE_MUTATION.SetIsBrowseUrlOnSametab, false);

        }
    }
    __vueEvent.$emit(EVENTS.NewMessageSent, msgPayload);
    return msgPayload;
};
