import Vue from "vue";
import { Component } from "vue-property-decorator";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Chat from "../components/chat.vue";
import Loader from "../components/loader.vue";
import Error from "../components/error.vue";
import Banner from "../components/banner.vue";
import { SignalrHandler } from "../extra/signalr_handler";
import { STORE_MUTATION } from "../enums/store_mutation";
import { getUserId } from "../helpers/get_user_id";
import { store } from "../store/store";
import { getQueryStringValue } from "../helpers/get_querystring_value";
import { Util } from "../util";
import { installJquery } from "../helpers/install_jquery";
import { installSignalr } from "../helpers/install_signalr";
import { __vueEvent, __maintainStateTime, __maintainState } from "../constant";
import { ConfigLoader } from "../helpers/config_loader";
import { mapState } from "vuex";
import { CACHE_ID_TYPE, EVENTS, MESSAGE_TYPE, SIGNALR_STATUS } from "../enums";
import { IConfig } from "../interfaces/config";
import { StorageHelper, addUserMessage, getMessageFromServer, isLoadInIFrame } from "../helpers";
import app from "../index";
import { ChatService } from "../service/chat_service";
import { isMobile } from "../helpers/is_mobile";
import { IMessage } from "../interfaces";
import { isFirefox } from '../helpers/get-browser-type';

declare var satisfiLabsGLobal;

@Component({
  components: { Header, Footer, Chat, Loader, Error, Banner },
  computed: mapState([
    "isHideTitleBar",
    "isConfigurationLoaded",
    "errorMessage",
    "isShowHeaderInIframe",
    "hideHeader",
    "shouldShowBanner",
    "isIFrameLoaded",
    "enableMessageNavigation",
  ]),
})
export default class Main extends Vue {

    isMobileActive = isMobile.any() || this.$store.state.isIFrameLoaded;
    hideIFrameHeader = true;

    async mounted() {
        this.windowEventListener();
        console.log("Main component called ");
        window.parent.postMessage({
            event_id: 'getSatisfiMessageMetaInfo'
        }, "*");
        const service = new ChatService();
        const awsConfigs = await service.getAWSConfig('0');
        store.commit(STORE_MUTATION.SetAWSServerURL, awsConfigs.awsServerURL);
        store.commit(STORE_MUTATION.SetAWSImageLogoPath, awsConfigs.awsImageLogoPath);
        store.commit(STORE_MUTATION.SetAWSPopupConfigPath, awsConfigs.awsPopupConfigPath);
        store.commit(STORE_MUTATION.SetAWSThemeFilePath, awsConfigs.awsThemeFilePath);

        store.commit(STORE_MUTATION.SetSatisfiURL, satisfiLabsGLobal.WebChatAddress);

        store.commit(STORE_MUTATION.SetPageReferralUrl, document.referrer);
        this.listenEvents();
        await this.setUserId();
        await this.setCFHeader();
        await new ConfigLoader().load();
    }

    windowEventListener() {

        //Call from Iframe to web chat.
        if (window.addEventListener) {
            window.addEventListener("message", (event) => {
                switch (event.data.event_id) {
                    case 'satisfiMessageMetaInfo':
                        store.commit(STORE_MUTATION.SetPopupId, event.data.data.popupId);
                        store.commit(STORE_MUTATION.SetParentSiteUrl, event.data.data.parentSiteUrl);
                        store.commit(STORE_MUTATION.SetParentSiteReferralUrl, event.data.data.parentSiteReferralUrl);
                        break;

                    case 'satisfiLocationChangeInfo':
                        store.commit(STORE_MUTATION.SetParentSiteUrl, event.data.data.parentSiteUrl);
                        break;

                    case 'satisfiFocusFirstMessage':
                        this.focusFirstElementOFChatPage();
                        break;
                    case 'satisfiFocusLastElement':
                        this.focusLastElementOFChatPage();
                        break;
                    case 'unhideInputContainer':
                        store.commit(STORE_MUTATION.SetDisableInput, false);
                        store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, false);
                        break;
                    case 'satisfiFocusInputField':
                        if (typeof jQuery !== 'undefined') {
                            jQuery(`.${this.$store.state.prefixClassName}textInput`).focus();
                        }
                        break;
                    case 'satisfiSendMessage':
                        this.addMessageInIframeFromGlobal(event.data.data.input, event.data.data.inputHidden);
                        break;
                    case 'satisfiBrowserPopupBlockReport':
                        if (!store.state.shouldShowBanner) {
                            store.commit(STORE_MUTATION.SetBannerText, "Browser is blocking urls on this site.");
                            store.commit(STORE_MUTATION.SetShouldShowBanner, true);
                            setTimeout(() => {
                                if (store.state.bannerText !== "Connecting...") {
                                    store.commit(STORE_MUTATION.SetShouldShowBanner, false);
                                }
                            }, 5000);
                        }
                        break;
                    case 'satisfiCloseIFrameForRemoteUrl':
                        var ifrm = document.getElementById("remote_browser_target");
                        if (ifrm) {
                            ifrm.remove();
                        }
                        break;
                    case 'satisfiTilesIsOpened':
                        // SD-1671
                        // Now we know that tile is opened by a client.
                        // We can now update the flag value, so that we can access it other components
                        store.commit(STORE_MUTATION.SetIsTilesOpenedInIframe, true);
                        break;
                    case 'onRedirectURL':
                        this.RedirectURLFromChild(event.data.data);
                        break;
                }
            }, false);
        }

        this.SetPlatformInfo();
        this.DocumentEventListener();
    }

    /**
     * SD-5173: Redirects the URL from the child iframe to the parent iframe.
     * @param data - The props passed from the child iframe to the parent iframe.
     */
    RedirectURLFromChild(data: any){
      try {
        // Get all the props passed from child iframe to parent iframe
        const href = data.href || "";
        const target = data.target || "";
        const hideInChatBackButton = data.hideInChatBackButton || false;

        // Check if the current window is loaded in an iframe
        if (isLoadInIFrame()) {
          // Pass the same information to the parent iframe
          window.parent.postMessage({
            event_id: 'onRedirectURL',
            data: { href: href, target: target, hideInChatBackButton: hideInChatBackButton }
          }, "*");
        } else {
          // Redirect the URL to the new page in a new tab
          if (target === "_blank") {
            window.open(href, target, "noopener noreferrer");
          }
        }
      } catch (ex) {
        console.log("[main.ts] An error has occurred \n-> Inside RedirectURLFromChild -> Error: ", ex);
      }
    }

    /**
     * 628 - This method is used to set a class to body for mobile devices only.
     * It is used to fix a bug where footer was getting hidden sometimes when virtual keyboard opens in iOS
     */
    SetPlatformInfo() {
        try {

            let userAgentString = navigator.userAgent.toLowerCase();
            let chromeIndex = userAgentString.indexOf("chrome");
            let safariIndex = userAgentString.indexOf("safari");

            if (document.body != null && safariIndex != -1 && isMobile.any()) {
                if (chromeIndex > -1) {
                    document.body.classList.add("satisfi_chrome");
                } else {
                    document.body.classList.add("satisfi_safari");

                    // Get iOS version and add that version as a class
                    let ver = this.GetiOSVersion();
                    if (ver >= 15) {
                        document.body.classList.add("satisfi_safari_iOS15");
                    }
                }
            }

        } catch (ex) {
            var errorObj = { Page: 'main.ts', MethodName: 'SetPlatformInfo', Message: ex.message };
            //console.log(errorObj);
        }
    }

    /**
     * This method is used to Detect the iOS version
     * WEBAPPS-954
     */
    GetiOSVersion() {
        try {
            var agent = navigator.userAgent
            if (/(iPhone|iPod|iPad)/i.test(agent)) {
                var v = agent.match(/OS (\d+)_(\d+)_?(\d+)?/);
                return (v && v.length > 1) ? Number(v[1]) : 0;
            }
            return 0;
        } catch (ex) {
            return 0;
        }
    }

    timer = null;
    /**
     * This method is used to close the virtual keyboard in iOS on clicking outside the textarea.
     */
    DocumentEventListener() {
        try {
            let vm = this;
            if (isMobile.iOS() && document.addEventListener) {
                document.addEventListener("touchend", function (event) {
                    let target = event.target as HTMLElement;
                    if (!target) return;
                    let tagName = target.tagName;
                    if (tagName === "TEXTAREA") return;

                    // Check if the target is an input field and a text field
                    // If it is a text field, then do not close the virtual keyboard in iOS
                    if (tagName === "INPUT") {
                        let inputElement = target as HTMLInputElement;
                        if (["text", "email", "number", "password", "search", "tel", "url"].includes(inputElement.type)) {
                            return;
                        }
                    }

                    vm.timer = setTimeout(function () {
                        if (document.activeElement) {
                            (document.activeElement as HTMLElement).blur();
                        }
                        clearTimeout(vm.timer);
                    }, 300);
                });
            }

            /**
             * Captures the text selected by the user and modifies it before copying to the clipboard.
             * Removes the text "Bot says:" or "I say:" from the copied text to avoid including it in the clipboard.
             *
             * @param {ClipboardEvent} event - The event object representing the clipboard event.
             */
            function captureClipboard(event) {
              try {
                const selection = window.getSelection();
                if (selection) {
                  let copiedText = selection.toString();
                  if (!Util.isNullOrEmpty(copiedText)) {
                    const clipboardData = event.clipboardData || window.clipboardData;
                    if (clipboardData) {
                      event.preventDefault();
                      // Remove the text "Bot says:" or "I say:" from the copied text
                      // This is done to avoid copying the text "Bot says:" or "I say:" in the clipboard
                      copiedText = copiedText.replace(/(Bot says:|I say:)\s*\n?/gi, '');
                      clipboardData.setData('text/plain', copiedText);
                    }
                  }
                }
              } catch (ex) {
                //console.log(ex);
              }
            }

            // SD-5446: Handle the case when user copy the text from the chat window and paste it in slack app.
            // We do not need this fix for Firefox browser as it is already handled by the browser.
            if (document.addEventListener && !isFirefox()) {
              document.addEventListener("copy", captureClipboard);
            }
        } catch (error) { }
    }

    addMessageInIframeFromGlobal(input = '', inputHidden = '') {
        if (input != '') {
            addUserMessage(input, true, (inputHidden == '1' ? true : false));
        }
    }

    focusFirstElementOFChatPage() {
        try {
            let webchatSectionBtn = jQuery(`.${this.$store.state.prefixClassName}standalone section .${this.$store.state.prefixClassName}btn`);

            // SD-5153: Check if enableMessageNavigation is true, then focus on the first chat section
            if (this.$store.state.enableMessageNavigation) {
                let chatSection = jQuery(`.${this.$store.state.prefixClassName}chatBody main section[tabindex="0"]`);
                if (chatSection.length > 0) {
                    let firstFocusableSection = <HTMLElement>chatSection[0];
                    firstFocusableSection.focus();
                    return;
                }
            }

            // Else, continue with the normal flow of focusing on the first button
            if (webchatSectionBtn.length > 0) {
                let firstFocusableSectionButton = <HTMLElement>webchatSectionBtn[0];
                firstFocusableSectionButton.focus();
            }
            else {
                if (!this.$store.state.disableInput) {
                    if (jQuery(`button.${this.$store.state.prefixClassName}info`).is(":visible")) {
                        jQuery(`button.${this.$store.state.prefixClassName}info`).focus();
                    }
                    else if (jQuery(`button.${this.$store.state.prefixClassName}camera`).is(":visible")) {
                        jQuery(`button.${this.$store.state.prefixClassName}camera`).focus();
                    }
                    else {
                        jQuery(`input.${this.$store.state.prefixClassName}textInput`).focus();
                    }
                }
            }
        } catch (ex) {
            console.log("[main.ts] An error has occurred \n-> Inside focusFirstElementOFChatPage -> Error: ", ex);
        }
    }

    /**
     * The function `focusLastElementOFChatPage()` is used to set focus on the last element of the chat
     * page, either a send button or a text input, depending on the state of the application.
     */
    focusLastElementOFChatPage() {
        try {
            if (!this.$store.state.disableInput) {
                if (jQuery(`button.${this.$store.state.prefixClassName}send`).is(":visible")) {
                    jQuery(`button.${this.$store.state.prefixClassName}send`).focus();
                } else {
                    jQuery(`input.${this.$store.state.prefixClassName}textInput`).focus();
                }
            } else {
                let webchatSectionBtn = jQuery(`.${this.$store.state.prefixClassName}standalone section .${this.$store.state.prefixClassName}btn`);
                if (webchatSectionBtn.length > 0) {
                    let lastFocusableSectionButton = <HTMLElement>webchatSectionBtn[webchatSectionBtn.length - 1];
                    lastFocusableSectionButton.focus();
                }
            }
        } catch (ex) {
            console.log("[main.ts] An error has occurred \n-> Inside focusLastElementOFChatPage -> Error: ", ex);
        }
    }


    listenEvents() {
        __vueEvent.$once(EVENTS.SetConfig, (config: IConfig) => {
            // Show the banner if the new message is processing
            if (store.state.isNewMessageProcessing) {
                // This is used to show a banner before getting the welcome message
                this.$store.commit(STORE_MUTATION.SetBannerText, "Loading...");
                this.$store.commit(STORE_MUTATION.SetShowWelcomeBanner, true);
            }

            this.startSignalr();
        }).$on(EVENTS.NewMessage, () => {
            if (isMobile.any()) {
                if (typeof jQuery != 'undefined')
                    jQuery(`.${this.$store.state.prefixClassName}chatBody main section`).last().focus();
            } else {
                __vueEvent.$emit(EVENTS.FocusInputArea);
            }
            if (this.$store.state.maintainState) {
                StorageHelper.set(`${__maintainStateTime}${this.$store.state.pageId}`, new Date().toUTCString());
            } else if (StorageHelper.get(`${__maintainStateTime}${this.$store.state.pageId}`) != null) {
                StorageHelper.remove(`${__maintainStateTime}${this.$store.state.pageId}`);
            }
        }).$on(SIGNALR_STATUS.Disconnected, () => {
            this.$store.commit(STORE_MUTATION.SetBannerText, "Connecting...");
            this.$store.commit(STORE_MUTATION.SetShouldShowBanner, true);
        }).$on(SIGNALR_STATUS.Connected, () => {
            this.$store.commit(STORE_MUTATION.SetShouldShowBanner, false);
        }).$on(EVENTS.RemoveAllButtons, () => {
            this.$store.commit(STORE_MUTATION.RemoveAllButtons);
        }).$on(EVENTS.UrlOpenedInIFrame, () => {
            // if the settings.browserTarget is "iframe"
            // This means url has been opened in a iframe.
            // So now we will show the Header for IFrame w/ return to chat title
            this.hideIFrameHeader = false;

            // As the header is shown again, emit event one more time after a delay
            if (this.$store.state.isHideTitleBar) {
                setTimeout(() => {
                    __vueEvent.$emit(EVENTS.UrlOpenedInIFrameFromMain);
                }, 200);
            }
        }).$on(EVENTS.HideCloseIFrameIcon, () => {
            // Close the Header for IFrame w/ return to chat title
            this.hideIFrameHeader = true;
        }).$once(SIGNALR_STATUS.Connecting, () => {
            console.log(`[x] Trying to connect to SignalR...`);
        }).$once(SIGNALR_STATUS.Connected, async () => {
            console.log(`[x] SignalR Connected`);

            // If the new message processing is false, then do not fetch the welcome message
            if (!store.state.isNewMessageProcessing) return;
            console.log(`[x] Requesting welcome message...`);
            await this.fetchWelcomeMessage();
            console.log(`[x] Welcome message response received!`);
            await this.fetchMaintainStateMessages();
        });
    }

    /**
     * The function adds a welcome message to a store in the application.
     * @param {string} [message] - The `message` parameter is a string that represents a JSON object.
     * It is optional and has a default value of an empty string.
     */
    addWelcomeMsg(message: string = "") {
        try {
            const welcomeMessage = JSON.parse(message);
            store.commit(STORE_MUTATION.AddMessage, {
                type: MESSAGE_TYPE.Bot,
                contents: welcomeMessage,
                id: welcomeMessage.recordInfo.messageCacheId
            } as IMessage);
        } catch (ex) { }
    }

    /**
     * The function is an asynchronous function that fetches a welcome message from
     * a ChatService and adds it to the current instance.
     */
    async fetchWelcomeMessage() {
        try {
            const service = new ChatService();

            // This is to get the welcome message from the EBM for backward compatibility
            const welcomeMsgCfg: IConfig = await service.getWelcomeMessage();
            this.$store.commit(STORE_MUTATION.SetShowWelcomeBanner, false);

            // This is to handle the case for backward compatibility for welcome message
            if (welcomeMsgCfg && !Util.isNullOrEmpty(welcomeMsgCfg.welcomeMessage)) {
                this.addWelcomeMsg(welcomeMsgCfg.welcomeMessage);
            }
        } catch (ex) {
            //console.log(ex);
            this.$store.commit(STORE_MUTATION.SetShowWelcomeBanner, false);
        }
    }

    /**
     * The function is an asynchronous function that fetches maintain state messages from
     * a ChatService and adds it to the current instance.
     */
    async fetchMaintainStateMessages() {
        try {
            const isMaintainState = StorageHelper.get(`${__maintainState}${this.$store.state.pageId}`) != null;
            if (this.$store.state.maintainState && isMaintainState) {
                console.log(`[x] Maintain state is ON. Requesting for maintain state messages...`);
                store.commit(STORE_MUTATION.SetIsMaintainStateMessages, true);
                const msgs = await getMessageFromServer(
                    0,
                    CACHE_ID_TYPE.BeforeMessageCacheId,
                    false,
                    this.$store.state.minimumClientVersion || "",
                    `main.ts:fetchMaintainStateMessages`
                );
                console.log(`[x] Request for maintain state messages received!`);
            }
        } catch (ex) {
            //console.log(ex);
            store.commit(STORE_MUTATION.SetIsMaintainStateMessages, false);
        }
    }

    async setUserId() {
        // Update the WebChat application to pass the External Id Source to ebm.
        // When you generate the id automatically, set to 2 (Satisfi)
        // When you are using the client supplied id from the URL, set to 1 (Client)

        let extId = getQueryStringValue('extid');
        if (Util.isNullOrEmpty(extId)) {
            if (satisfiLabsGLobal.HeaderInfo.extid) {
                extId = satisfiLabsGLobal.HeaderInfo.extid;
                //When you generate the id from header, set to 3 (Client)
                store.commit(STORE_MUTATION.SetUserIdSourceTypeId, 3);
            }
            else {
                extId = getUserId();
                //When you generate the id automatically, set to 2 (Satisfi)
                store.commit(STORE_MUTATION.SetUserIdSourceTypeId, 2);
            }
        } else {
            //the client supplied id from the URL, set to 3 (Client)
            store.commit(STORE_MUTATION.SetUserIdSourceTypeId, 3);
        }
        store.commit(STORE_MUTATION.SetUserId, extId);
    }

    /**
     * This method is used to set the cf header value in the store.
     * SD-3539
     */
    async setCFHeader() {
        try {
            let cfHeader = getQueryStringValue('cf');
            if (Util.isNullOrEmpty(cfHeader)) {
                if (satisfiLabsGLobal && satisfiLabsGLobal.HeaderInfo && satisfiLabsGLobal.HeaderInfo.cf) {
                    cfHeader = satisfiLabsGLobal.HeaderInfo.cf;
                } else {
                    cfHeader = '';
                }
            }
            store.commit(STORE_MUTATION.SetCFHeader, cfHeader || '');

        } catch (ex) {
            //console.log(ex);
        }
    }

    async startSignalr() {
        await installJquery();
        await installSignalr();
        new SignalrHandler().start();
    }

}
