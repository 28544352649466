export class HttpRequest {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    sendPost(data: any, header?): Promise<any> {
        const defaultHeader = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        for (var key in header) {
            defaultHeader[key] = header[key];
        }
        return new Promise((res, rej) => {
            fetch(this.url, {
                body: data == null ? null : JSON.stringify(data),
                method: "POST",
                headers: defaultHeader
            }).then(response => {
                switch (response.status) {
                    case 200:
                    case 201:
                        res(response.json()); break;
                    case 204:
                        res(); break;
                    case 404:
                        console.error(`erroc occured while sending request from url ${this.url} & response is: `, response);
                        rej("Invalid url");
                        break;
                    case 500:
                        rej("Server error occured"); break;
                    default:
                        rej(`unknown error occured. status code - ${response.status}`);
                }
            }).catch(rej);
        });
    }

    async sendGet(data: object, header?) {
        var urlParam = Object.keys(data).map(k => k + '=' + data[k]).join('&');
        const defaultHeader = {
            Accept: 'application/json'
        }
        for (var key in header) {
            defaultHeader[key] = header[key];
        }
        const response = await fetch(`${this.url}?${urlParam}`, {
            method: "GET",
            headers: defaultHeader
        });
        switch (response.status) {
            case 200:
                const result = await response.text();
                const contentType = (response.headers.get("Content-Type") as string).split(";")[0];
                switch (contentType) {
                    case "text/css":
                        return result;
                    default: return JSON.parse(result);
                }
            case 404:
                console.error(`erroc occured while sending request from url ${this.url} & response is: `, response);
                return Promise.reject("Invalid url");
            case 500:
                return Promise.reject("Server error occured");
        }
    }

    //#region New Admin Login Calls

    /**
     * Sends a POST request to the server with the given data.
     */
    sendAdminPost(data: any): Promise<any> {
        return new Promise((res, rej) => {
            fetch(this.url, {
                body: data == null ? null : JSON.stringify(data),
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                }
            }).then(response => {
                switch (response.status) {
                    case 200:
                    case 201:
                        res(response.json()); break;
                    case 204:
                        res(); break;
                    case 404:
                        console.error(`An error occured while sending request from url ${this.url} \nResponse is: `, response);
                        rej("Invalid url");
                        break;
                    case 500:
                        rej("Server error occured"); break;
                    default:
                        rej(`unknown error occured. status code - ${response.status}`);
                }
            }).catch(rej);
        });
    }

    /**
     * Sends a POST request to the server with the given data and authorization token.
     */
    sendAdminPostWithAuth(data: any, authToken: string): Promise<any> {
        return new Promise((res, rej) => {
            fetch(this.url, {
                body: data == null ? null : JSON.stringify(data),
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': 'Bearer ' + authToken,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                }
            }).then(response => {
                switch (response.status) {
                    case 200:
                    case 201:
                        res(response.json()); break;
                    case 204:
                        res(); break;
                    case 404:
                        console.error(`An error occured while sending request from url ${this.url} \nResponse is: `, response);
                        rej("Invalid url");
                        break;
                    case 500:
                        rej("Server error occured"); break;
                    default:
                        rej(`unknown error occured. status code - ${response.status}`);
                }
            }).catch(rej);
        });
    }

    /**
     * Sends a GET request to the server with the given data.
     */
    async sendAdminGet() {
        //var urlParam = Object.keys(data).map(k => k + '=' + data[k]).join('&');
        const response = await fetch(`${this.url}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            }
        });
        switch (response.status) {
            case 200:
                const result = await response.text();
                const contentType = (response.headers.get("Content-Type") as string).split(";")[0];
                switch (contentType) {
                    case "text/css":
                        return result;
                    default: return JSON.parse(result);
                }
            case 404:
                console.error(`An error occured while sending request from url ${this.url} \nResponse is: `, response);
                return Promise.reject("Invalid url");
            case 500:
                return Promise.reject("Server error occured");
        }
    }

    /**
     * Sends a GET request to the server with the given data and authorization token.
     */
    async sendAdminGetWithAuth(authToken: string) {
        //var urlParam = Object.keys(data).map(k => k + '=' + data[k]).join('&');
        const response = await fetch(`${this.url}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + authToken,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            }
        });
        switch (response.status) {
            case 200:
                const result = await response.text();
                const contentType = (response.headers.get("Content-Type") as string).split(";")[0];
                switch (contentType) {
                    case "text/css":
                        return result;
                    default: return JSON.parse(result);
                }
            case 404:
                console.error(`An error occured while sending request from url ${this.url} \nResponse is: `, response);
                return Promise.reject("Invalid url");
            case 500:
                return Promise.reject("Server error occured");
        }
    }

    //#endregion
}