var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      class:
        _vm.getClass("headerContainer") +
        " " +
        (!_vm.hideHeaderCloseIFrame ? _vm.getClass("returnToChat") : ""),
      on: {
        mousedown: _vm.headerContainerMousedown,
        mouseup: _vm.headerContainerMouseup,
        touchstart: _vm.headerContainerMousedown,
        touchend: _vm.headerContainerMouseup,
      },
    },
    [
      !_vm.hideHeaderCloseIFrame
        ? _c(
            "button",
            {
              class: _vm.getClass("btn") + " " + _vm.getClass("closeIFrame"),
              attrs: { "aria-label": "Close iframe" },
              on: { click: _vm.headerCloseIFrameClick },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "17",
                    height: "17",
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 256 512",
                    "aria-hidden": "true",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z",
                      fill: "#D8D8D8",
                      "fill-rule": "evenodd",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideHeaderCloseIFrame
        ? _c(
            "span",
            {
              class: _vm.getClass("returnText"),
              on: { click: _vm.headerCloseIFrameClick },
            },
            [_vm._v(_vm._s(_vm.backButtonLabelIFrame))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideHeader && !!_vm.hideHeaderCloseIFrame
        ? _c(
            "h1",
            {
              class:
                (_vm.isHeaderImage ? _vm.getClass("logo") : "") +
                " " +
                (_vm.destinationURL != "" ? _vm.getClass("logoUrl") : ""),
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.headerImageClick.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.pageTitle))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }