import Vue from "vue";
import { Component } from "vue-property-decorator";
import { STORE_MUTATION } from "../enums/store_mutation";
import { ConfigLoader } from "../helpers/config_loader";
import { mapState } from "vuex";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums";
import { IConfig } from "../interfaces/config";
import Banner from "../components/banner.vue";
declare var SatisfiApp;
@Component({
    computed: mapState(['shouldShowBanner', 'isIFrameLoaded']),
    components: { Banner }
})
export default class Header extends Vue {

    pageTitle = "";
    isHeaderImage = false;
    hideHeader = false;
    triggerUnhideInputInterval;
    destinationURL = "";
    isUnhideInputInitiated = true;
	hideHeaderCloseIFrame = true;

	// SD-1837: Add Ability to set back button label when showing IFrame
	backButtonLabelIFrame = "Return to Chat";

    mounted() {
        this.listenEvents();
    }

    listenEvents() {
        __vueEvent.
            $once(EVENTS.SetConfig, (config: IConfig) => {
                this.pageTitle = config.pageTitle.length > 0 ? config.pageTitle : config.pageName;
                this.isHeaderImage = String(config.headerImage).length > 0;
                this.hideHeader = config.hidePageTitle;
                this.destinationURL = config.headerImageDestinationURL || '';
            }).$on(EVENTS.UrlOpenedInIFrame, (iframeBackButtonLabel) => {
                // if the settings.browserTarget is "iframe"
				// This means url has been opened in a iframe.
				// So now we will show the close ifrmae button in the header
				this.hideHeaderCloseIFrame = false;
				this.updateHeaderHeight("42px");

				// SD-1837: Add Ability to set back button label when showing IFrame
				this.backButtonLabelIFrame = "Return to Chat";
				if (iframeBackButtonLabel != null) {
					this.backButtonLabelIFrame = iframeBackButtonLabel;
				}

            }).$on(EVENTS.UrlOpenedInIFrameFromMain, () => {
				this.hideHeaderCloseIFrame = false;
				this.updateHeaderHeight("42px");
            });
    }

	updateHeaderHeight(inputHeight: string, setTempHeight = true) {
        try {
            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles) {
                // Check if the `--chatFooterHeight` var is present
                let headerHeight = compStyles.getPropertyValue('--headerHeight');
                if (!!headerHeight) {
					document.documentElement.style.setProperty('--tempHeaderHeight', setTempHeight ? headerHeight : "0");
                    document.documentElement.style.setProperty('--headerHeight', inputHeight);
                }
            }
        } catch (ex) {}
    }

    headerContainerMousedown() {
        //On mouse down of header for 5 sec show input container
        this.isUnhideInputInitiated = true;
        this.triggerUnhideInputInterval = setInterval(() => {
            this.isUnhideInputInitiated = false;
            this.$store.commit(STORE_MUTATION.SetDisableInput, false);
            this.$store.commit(STORE_MUTATION.SetDefaulthideInputContainerSetting, false);
            clearInterval(this.triggerUnhideInputInterval);
        }, 5000);
    }

    headerContainerMouseup() {
        //On mouse up on header clear interval to show input container
        clearInterval(this.triggerUnhideInputInterval);
    }

    // Event handler for the header image click
    headerImageClick() {
        // If only a headerImageDestinationURL are present,
        // then only open the url in a new tab
        const vm = this;
        const isDestinationURL = String(vm.destinationURL).length > 0;
        if (isDestinationURL && !vm.hideHeader && vm.isUnhideInputInitiated) {
            const a = document.createElement("a");
            a.href = vm.destinationURL;
            a.rel = "noreferrer noopener";
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }

	// Hide the IFrame overlay
	hideIFrame() {
        var ifrm = document.getElementById("remote_browser_target");
		if(ifrm) {
			// Just remove the iframe, just like we do for new tabs
			ifrm.remove();
			//ifrm.style.display = "none";
			// Clear the content of an IFRAME, to prevent any cache issues
			//ifrm.setAttribute("src", "about:blank");
		}
		return false;
    }

	// Event handler for the header close IFrame icon click
	headerCloseIFrameClick() {
		try {
			this.hideHeaderCloseIFrame = true;
			this.hideIFrame();
			__vueEvent.$emit(EVENTS.HideCloseIFrameIcon);
            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles) {
                let headerHeight = compStyles.getPropertyValue('--tempHeaderHeight');
                if (!!headerHeight) {
					this.updateHeaderHeight(headerHeight, false);
                }
            }
        } catch (ex) {}
    }
}
