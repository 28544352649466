import { Errors } from "../enums/errors";

export class ErrorHelper {
    errMessage = "";
    errorType: Errors;
    info: any;

    constructor(errType: Errors, info?) {
        this.errorType = errType;
        this.info = info;
        this.errMessage = this.getErrorMessage_();
    }

    logError() {
        console.error(this.errMessage);
    }

    private getErrorMessage_() {
        switch (this.errorType) {
            case Errors.NoPageIdSupplied:
                return "No page id supplied";
            case Errors.InvalidPageId:
                return "The page you've requested is not available."
        }
    }
}