import { STORE_MUTATION } from "../enums";
import { MessageContent } from "../types";
import { store } from "../store/store";
import { getMessageType } from ".";

/**
 * Assign a value to a variable if it exists, otherwise assign a default value.
 */
function assignValueIfExists<T>(value: T | undefined, defaultValue: T): T {
  return value !== undefined ? value : defaultValue;
}

/**
 * Update the store with the processing message.
 */
function updateStore(processing: boolean, displayIcon: string, displayText: string) {
  store.commit(STORE_MUTATION.SetShowProcessingMessage, processing);
  store.commit(STORE_MUTATION.SetProcessingDisplayIcon, displayIcon);
  store.commit(STORE_MUTATION.SetProcessingDisplayText, displayText);
}

// Store the timeout id for the processing indicator.
let processingTimeoutId: ReturnType<typeof setTimeout> | undefined;

/**
 * SD-3381: Show the processing message on the screen.
 * @param message The message to show.
 */
export const onProcessingFromServer = async function (message: MessageContent) {
  try {
    // If the message is not a typing indicator, ignore it.
    if (!message.processingIndicator) {
      return;
    }

    // If the message is a processing indicator, clear the timeout id.
    if (processingTimeoutId) {
      clearTimeout(processingTimeoutId);
    }

    store.commit(STORE_MUTATION.SetTypingType, getMessageType(message.recordInfo.senderType));

    let displayIcon: string = '';
    let displayText: string = '';

    // Get the display icon and text from the message
    displayIcon = assignValueIfExists(message.processingIndicator.displayIcon, "");
    displayText = assignValueIfExists(message.processingIndicator.displayText, "");

    // Additional checks for empty, null, undefined, or whitespace values
    if (displayIcon && !displayIcon.trim()) {
      displayIcon = "";
    }
    if (displayText && !displayText.trim()) {
      displayText = "";
    }

    // Both the text and the icon should be optional.
    // However if both are missing, you should ignore the message and display nothing.
    if (!displayText && !displayIcon) {
      updateStore(false, "", "");
      return;
    }

    // Update the store with the processing message.
    updateStore(true, displayIcon.trim(), displayText.trim());


    let typingTimeout: number;
    const processingIndicatorTimeout = (message.processingIndicator as any).timeout;

    // If the timeout is specified, use it.
    if (processingIndicatorTimeout !== undefined && processingIndicatorTimeout !== null) {
      typingTimeout = processingIndicatorTimeout;
    } else {
      // Otherwise, use the default timeout.
      typingTimeout = 1000;
    }

    // If the timeout is 0, then the processing message should be displayed indefinitely.
    if (typingTimeout == 0) {
      return;
    }

    // Otherwise, set a timeout to hide the processing message.
    processingTimeoutId = setTimeout(() => {
      updateStore(false, "", "");
    }, typingTimeout);
  } catch (ex) {
    updateStore(false, "", "");
  }
};
