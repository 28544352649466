import Vue from "vue";
import { Component } from "vue-property-decorator";
import { MessageStructureComponent } from "../types";
import { mapState } from "vuex";
import { ActionHandler } from "../helpers/action_handler";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";
import ImageSpinner from "../components/image_spinner.vue";
import { isFirefox } from '../helpers/get-browser-type';
import { isSvgImage } from '../helpers/get-image-type-from-url';

@Component({
	components: { ImageSpinner },
    props: {
        component: Object
    },
    computed: mapState(['prefixClassName', 'enableMessageNavigation'])
})
export default class Img extends Vue {

    component!: MessageStructureComponent;
	isLoaded = false;

    onElClick() {
        ActionHandler.handleBtnClickAction(this.component);
    }

	/**
	 * SD-1706
	 * Check if the image has been fully loaded
	 */
	onImgLoad(){
		// Emit a event here, so that we can listen to it at chat bubble
		__vueEvent.$emit(EVENTS.ChatBubbleImageLoaded);
		this.isLoaded = true;
	}

    /**
     * JIRA: SD-5252
     * Checks if the current browser is Firefox and the component's image URL is an SVG image.
     * @returns {boolean} True if the browser is Firefox and the image URL is an SVG image, false otherwise.
     */
    get isFirefoxSVGClass(): boolean {
      try {
        return this.component && isFirefox() && isSvgImage(this.component.imageUrl || '');
      } catch (ex) {
        return false;
      }
    }
}
