var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        (_vm.isMobileActive && _vm.isMobileActive !== null
          ? _vm.getClass("infoPanelMobile")
          : "") +
        " " +
        _vm.getClass("infoPanel"),
    },
    [
      _c("div", { class: _vm.getClass("header") }, [
        _c("span", { class: _vm.getClass("title") }, [_vm._v("Information")]),
        _vm._v(" "),
        _c(
          "button",
          {
            class: _vm.getClass("close"),
            attrs: { "aria-label": "Close Info", tabindex: "0" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.hideInfo.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "17",
                  height: "17",
                  xmlns: "http://www.w3.org/2000/svg",
                  alt: "Close info",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M8.5 6.868l6.53-6.53a1.154 1.154 0 1 1 1.632 1.632l-6.53 6.53 6.53 6.53a1.154 1.154 0 1 1-1.632 1.632l-6.53-6.53-6.53 6.53A1.154 1.154 0 1 1 .338 15.03l6.53-6.53-6.53-6.53A1.154 1.154 0 1 1 1.97.338l6.53 6.53z",
                    fill: "#D8D8D8",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.getClass("infoPanelContent") }, [
        _c("ul", { class: _vm.getClass("linkList") }, [
          _c(
            "li",
            [
              _c("b", [_vm._v("Privacy & Legal Policies")]),
              _vm._v(" "),
              _vm._l(_vm.legalEntries, function (rule) {
                return _c("ul", [
                  _c("li", [
                    _c("a", {
                      attrs: {
                        rel: "noreferrer noopener",
                        href: rule.url,
                        target: "_blank",
                      },
                      domProps: { innerHTML: _vm._s(rule.displayText) },
                    }),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }