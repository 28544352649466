import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ActionHandler } from "../helpers";
import { MessageContentSettings, MessageStructureComponent } from "../types";

@Component({
    props: {
        msgIndex: Number,
        messageContentsettings: Object,
        data: Array
    }
})
export default class QuickReply extends Vue {
    //property
    data!: MessageStructureComponent[];
    msgIndex!: Number;
    messageContentsettings!: MessageContentSettings

	/**
	 * This is used to determine if the font is fully loaded
	 */
	isFontLoaded = false;

	/**
	 * At this stage, the Vue instance has been initialized
	 */
	created() {
        this.listenEvents();
    }

    onClick(index) {
        ActionHandler.handleBtnClickAction(this.data[index]);
    }

	/**
	 * SD-1706
	 * This is used to listen to needed events
	 */
	listenEvents() {
		try {
			// Check if all fonts in use by visible text have loaded.
			(document as any).fonts.ready
				.then(() => {
					console.log('All fonts in use by visible text have loaded.');
					this.isFontLoaded = true;
				}).catch((err) => {
					this.isFontLoaded = true;
				});

		} catch (ex) {
            //var errorObj = { Page: 'quick_reply.ts', MethodName: 'listenEvents', Message: ex.message };
            //console.log(errorObj);
        }
	}
}
