//#region Imports Packages and Libs

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { IMessage } from "../interfaces/message";
import { MessageStructureComponent, MessageContentSettings, MessageContent, MessageStructure } from "../types";
import { store } from "../store/store";
import { STORE_MUTATION } from "../enums/store_mutation";
import Button from "../components/button.vue";
import Img from "../components/image.vue";
import { isMobile } from "../helpers/is_mobile";
import { Util } from "../util";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";

//#endregion

//#region Enums

/**
 * Enum for Modal Load Behavior
 */
enum LoadBehaviorEnum {
    PLACEHOLDER_ONLY = 'placeholder_only',
    AUTO_OPEN_WITH_PLACEHOLDER = 'auto_open_with_placeholder',
    AUTO_OPEN_WITHOUT_PLACEHOLDER = 'auto_open_without_placeholder',
}

//#endregion

//#region Define the component in class-style

declare var marked;
@Component({
    components: { Button, Img },
    computed: mapState(['isBotHasAvatar', 'typingType', 'isUserHasAvatar'])
})
export default class ConsentModal extends Vue {
    //#region Class properties will be component data

    messageId!: any;
    showModal = false;
    messageSettings!: any;
    messageContentsettings!: MessageContentSettings
    components!: MessageStructureComponent[];
    LoadBehaviorEnum = LoadBehaviorEnum;

    //#endregion

    //#region Modal Lifecycle Hooks

    created() {
        this.setModalSettings();
    }

    mounted() {
        this.listenEvents();
    }

    //#endregion

    //#region Modal related logic here

    listenEvents() {
        __vueEvent.$on(EVENTS.ButtonClick, this.closeModal)
    }

    /**
     * This method is used to set the modal settings.
     */
    setModalSettings() {
        try {
            // Get the consent modal message settings
            let payload = store.state.termsConsentPayload || "";
            let contents = Util.validateAndParsePayload(payload);

            if (!Util.isNullOrEmpty(contents) && Util.isObjectWithNonEmptyArray(contents, "structures")) {
                let messageStructure = contents.structures[0] as MessageStructure;
                this.components = messageStructure.components;
                this.messageSettings = messageStructure.settings ? messageStructure.settings : {};
                this.messageContentsettings = {} as MessageContentSettings;

                // Set the placeholder label and icon
                if (this.messageSettings && Object.keys(this.messageSettings).length !== 0) {
                    // Set the show modal property
                    if (this.messageSettings.modalLoadBehavior === LoadBehaviorEnum.AUTO_OPEN_WITH_PLACEHOLDER) {
                        this.showModal = true;
                    } else if (this.messageSettings.modalLoadBehavior === LoadBehaviorEnum.AUTO_OPEN_WITHOUT_PLACEHOLDER) {
                        this.showModal = true;
                    } else {
                        this.showModal = false;
                    }
                }
            }

        } catch (ex) {
            console.log("[modal.ts] An error has occurred \n-> Inside setModalSettings -> Error: ", ex);
        }
    }

    /**
     * This method is called when the user clicks the close modal button.
     */
    closeModal(id) {
        this.showModal = false;
        this.handleShowModalChange(false);
        store.commit(STORE_MUTATION.SetShowConsentModal, false);
    }

    /**
     * This method is called when the user clicks the open modal message bubble.
     */
    openModal() {
        this.showModal = true;
    }

    marked(value) {
        return marked(value);
    }

    decodeTags(content: string = '') {
        return content.replace(/&amp;/g, '&').replace(/&amp;/g, '&');
    }

    /**
     * The function returns a class name by concatenating a prefix with a given value.
     * @param value - The value parameter is a string representing the class name that you want to
     * append to the prefixClassName.
     * @returns the concatenation of the value parameter and the prefixClassName property of the
     * store.state object.
     */
    getClassName(value) {
        return store.state.prefixClassName + value;
    }

    /**
     * This method is used to handle the show modal change event.
     *
     * @param value - The value parameter is a boolean representing the show modal property.
     */
    handleShowModalChange(value: any) {
        try {
            let userAgentString = navigator.userAgent.toLowerCase();
            let safariIndex = userAgentString.indexOf("safari");

            if (document.body != null && safariIndex != -1) {
                // If the modal is shown, add the class to the body
                if (value) {
                    // Check if body has the class already
                    document.body.classList.add(this.getClassName("modal-show"));
                } else {
                    // If the modal is hidden, remove the class from the body
                    if (document.body.classList.contains(this.getClassName("modal-show"))) {
                        document.body.classList.remove(this.getClassName("modal-show"));
                        __vueEvent.$emit(EVENTS.ScrollToNewMessageTop);
                    }
                }
            }

            // Update the modal height if the modal is shown
            if (value) {
                this.updateModalHeight();
            }
        } catch (ex) {
            console.log("[modal.ts] An error has occurred \n-> Inside onPropertyChanged -> Error: ", ex);
        }
    }

    @Watch('showModal', { immediate: true })
    onPropertyChanged(value: any) {
        this.handleShowModalChange(value);
    }


    /**
     * The function sets a CSS property on the document's root element if the value is truthy.
     * @param {string} propertyName - A string representing the name of the CSS property to be set.
     * @param value - The value parameter is the value that you want to set for the specified CSS
     * property.
     */
    setPropertyIfExist(propertyName: string, value) {
        if (value) {
            document.documentElement.style.setProperty(propertyName, `${value * 100}%`);
        }
    }

    /**
     * The function removes a CSS property from the root element of the document if it exists.
     * @param {string} propertyName - The name of the CSS property that you want to remove.
     */
    removePropertyIfExist(propertyName: string) {
        let compStyles = window.getComputedStyle(document.documentElement);
        if (!compStyles) return;
        const propertyValue = compStyles.getPropertyValue(propertyName);
        if (!Util.isNullOrEmpty(propertyValue)) {
            document.documentElement.style.removeProperty(propertyName);
        }
    }

    /**
     * This method is used to update the css variable named `--modalHeightDesktop` and `--modalHeightMobile` in the root element.
     */
    updateModalHeight() {
        try {
            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles && this.messageSettings && Object.keys(this.messageSettings).length !== 0) {
                let isHeightExist = this.messageSettings.height || 0;
                let isHeightDesktopExist = this.messageSettings.heightDesktop || 0;
                let isHeightMobileExist = this.messageSettings.heightMobile || 0;

                // Set modal height for desktop
                if (isHeightDesktopExist || isHeightExist) {
                    this.setPropertyIfExist('--modalHeightDesktop', this.messageSettings.heightDesktop || this.messageSettings.height);
                } else {
                    this.removePropertyIfExist('--modalHeightDesktop');
                }

                if (isHeightMobileExist || isHeightExist) {
                    this.setPropertyIfExist('--modalHeightMobile', this.messageSettings.heightMobile || this.messageSettings.height);
                } else {
                    this.removePropertyIfExist('--modalHeightMobile');
                }
            }
        } catch (ex) {
            console.log("[modal.ts] An error has occurred \n-> Inside updateModalHeight -> Error: ", ex);
        }
    }

    //#endregion
}

//#endregion