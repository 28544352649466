import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";

/**
 * Enum for icon type.
 */
enum IconEnum {
    DEFAULT = 'default',
    RIPPLE = 'ripple',
    DUAL_RING = 'dual-ring',
    SPINNER = 'spinner',
}

@Component({
    computed: mapState(['isBotHasAvatar', 'typingType', 'isUserHasAvatar', 'processingDisplayIcon', 'processingDisplayText'])
})
export default class ProcessingMessage extends Vue {
    IconEnum = IconEnum; // Make the enum accessible in the component's scope
}
