import { HttpRequest } from "../helpers/http_request";
import { getBrowserId } from "../helpers/get_browser_id";
import { store } from "../store/store";
import { BaseService } from "./base_service";
import { CACHE_ID_TYPE, STORE_MUTATION } from "../enums";
import { StorageHelper, getUniqId } from "../helpers";
import { Util } from "../util";
import { deleteCookie, getCookie, setCookie } from "../helpers/cookie_handler";
import { states } from "../store/states";
import { IMessage } from "../interfaces/message";
import { _convId } from "../constant";

declare var satisfiLabsGLobal;

export class ChatService extends BaseService {
  static isFirstMessage = true;

  // Get the current url of the chat page
  getCurrentPageUrl = () => {
    let url = '';
    try {
      if (typeof window !== "undefined") {
        url = window.location.href
      }
    } catch (error) { }
    return url;
  }

  getFormInputs(message: any) {
    try {
      // Look for a setting in the button called “sendInput”:true
      // If true and the user presses that button.  Include the input as a field in the metadata section of the message to EBM.
      if (message && message.clickAction && message.clickAction.settings && message.clickAction.settings.sendInput) {
        let formInputs = [];
        let inputs = store.state.formInputs || [];
        if (inputs && inputs.length > 0) {
          for (let i = 0; i < inputs.length; i++) {
            let formInput = {
              [inputs[i].fieldName]: inputs[i].fieldValue || ""
            };
            formInputs.push(formInput);
          }
        }
        return formInputs;
      }
      return [];
    } catch (ex) {
      return [];
    }
  }

  /**
   * The function retrieves a unique conversation ID from storage based on a
   * given page ID.
   * @param pageId - The `pageId` parameter is a unique identifier for a page. It is used to generate a
   * unique conversation ID by concatenating it with the `_convId` variable.
   * @returns a unique conversation ID (uniqueId) retrieved from storage based on the provided page ID
   */
  getConvIdFromStorage(pageId) {
    try {
      let uniqueId = "";
      let uniqueConvId = _convId.concat(pageId);

      //userIdSourceTypeId is set as 3 for the user id supplied from the URL
      let userIdSourceTypeId = store.state.userIdSourceTypeId || 0;
      let userId = store.state.userId || "";
      if (userIdSourceTypeId === 3 && userId) {
        uniqueConvId = uniqueConvId.concat(userId);
      }

      uniqueId = StorageHelper.get(uniqueConvId);
      return uniqueId != null ? uniqueId : "";
    } catch (ex) {
      return "";
    }
  }

  async getPageConfig(pageId: string) {
    const convId = this.getConvIdFromStorage(pageId);
    const url = `${this.appUrl}Default/GetPageConfig`;
    const request = new HttpRequest(url);
    const data = {
      userAgent: navigator.userAgent,
      ip: satisfiLabsGLobal.UserIp,
      PageReferralUrl: store.state.pageReferralUrl,
      iFramed: store.state.isIFrameLoaded,
      PopupId: store.state.popupId,
      ChatPageId: pageId,
      ExtMsgId: getUniqId(),
      ParentSiteUrl: store.state.parentSiteUrl,
      ParentSiteReferralUrl: store.state.parentSiteReferralUrl,
      Url: this.getCurrentPageUrl(),
      ConvId: convId,
    };
    return request.sendPost(data);
  }

  /**
   * This function sends a POST request to retrieve a welcome message from EBM.
   * @returns the result of the `request.sendPost(data)` method call.
   */
  async getWelcomeMessage() {
    const url = `${this.appUrl}Default/GetWelcomeMessage`;
    const request = new HttpRequest(url);
    const data = {
      userAgent: navigator.userAgent,
      ip: satisfiLabsGLobal.UserIp,
      PageReferralUrl: store.state.pageReferralUrl,
      iFramed: store.state.isIFrameLoaded,
      PopupId: store.state.popupId,
      ChatPageId: store.state.pageId,
      ExtMsgId: getUniqId(),
      ParentSiteUrl: store.state.parentSiteUrl,
      ParentSiteReferralUrl: store.state.parentSiteReferralUrl,
      Url: this.getCurrentPageUrl(),
      ConvId: store.state.convId,
      CompanyId: store.state.companyId,
      SendMessageUrl: store.state.sendMsgUrl,
      ExtBotId: store.state.extBotId
    };
    return request.sendPost(data);
  }

  typingIndicatorChat() {
    const request = new HttpRequest(`${this.appUrl}default/typingIndicatorChat/}`);

    const data = {
      Type: "typing",
      Timeout: 10000,
      ConvId: store.state.convId,
      UserId: store.state.userId,
      UserIdSourceTypeId: store.state.userIdSourceTypeId,
      CompanyId: store.state.companyId,
      //CompanyToken: store.state.companyToken,
      BotId: store.state.extBotId,
      //SendMsgUrl: store.state.sendMsgUrl,
      SenderType: "customer",
      SendMessageUrl: store.state.sendMsgUrl,
      NewMessageProcessing: store.state.isNewMessageProcessing,
    };

    const response = request.sendPost(data);
    console.log("typing indicator response: " + response);
    return response;
  }

  async getAWSConfig(pageId: string) {
    const url = `${this.appUrl}Default/GetAWSConfig`;
    const request = new HttpRequest(url);
    const data = {
      pageId: pageId
    };
    return request.sendGet(data);
  }

  async sendMessage(
    message: any,
    messageCacheIdType: string = null,
    lastMsgId: number = null,
    extMsgId: string = null
  ) {
    if (store.state.isSignalRConnected) {
      return this.sendMessageToServer(message,
        messageCacheIdType,
        lastMsgId,
        extMsgId);
    }
    else {
      setTimeout(() => {
        return this.sendMessage(message,
          messageCacheIdType,
          lastMsgId,
          extMsgId);
      }, 500);
    }
  }

 async sendMessageToServer(message: any,
  messageCacheIdType: string = null,
  lastMsgId: number = null,
  extMsgId: string = null) {
  const request = new HttpRequest(`${this.appUrl}default/sendmessage/}`);
  const data = {
    Content: message,
    ConvId: store.state.convId,
    UserId: store.state.userId,
    CFHeader: store.state.cfHeader,
    UserIdSourceTypeId: store.state.userIdSourceTypeId,
    CompanyId: store.state.companyId,
    //CompanyToken: store.state.companyToken,
    BeforeMessageCacheId: null,
    AfterMessageCacheId: null,
    BotId: store.state.extBotId,
    //SendMsgUrl: store.state.sendMsgUrl,
    SocketId: store.state.socketId,
    userLat: store.state.userLat,
    userLng: store.state.userLng,
    locationBlocked: store.state.locationBlocked,
    DeviceId: await getBrowserId(),
    AdminMode: store.state.isAdminMode ? 1 : 0,
    ExtMsgId: extMsgId ? extMsgId : getUniqId(),
    ParentSiteUrl: store.state.parentSiteUrl,
    Input: this.getFormInputs(message),
    NewMessageProcessing: store.state.isNewMessageProcessing,
    SendMessageUrl: store.state.sendMsgUrl,

    ...(ChatService.isFirstMessage
      ? {
        browserUuid: await getBrowserId(),
        userAgent: navigator.userAgent,
        ip: satisfiLabsGLobal.UserIp,
        PageReferralUrl: store.state.pageReferralUrl,
        iFramed: store.state.isIFrameLoaded,
        PopupId: store.state.popupId,
        ChatPageId: store.state.pageId,
        //ParentSiteUrl: store.state.parentSiteUrl,
        ParentSiteReferralUrl: store.state.parentSiteReferralUrl,
        Url: this.getCurrentPageUrl(),
        TermsOfServiceAccepted: store.state.isTermsOfServiceAccepted ? 1 : 0,
      }
      : null)
  };

  messageCacheIdType == CACHE_ID_TYPE.AfterMessageCacheId
    ? (data.AfterMessageCacheId = lastMsgId)
    : (data.BeforeMessageCacheId = lastMsgId);

  ChatService.isFirstMessage = false;
  const response = await request.sendPost(data);
  if (response.response.trim() !== '') {
    store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
  }

  if(response.messageTime) {
    try {
      localStorage.setItem( store.state.convId, response.messageTime);
    }
    catch(ex){
      setCookie(store.state.convId, response.messageTime);
    }
  }

  //Remove the status from fail
  store.commit(STORE_MUTATION.UpdateMessageStatus, {
    id: extMsgId,
    status: "ok"
  } as IMessage);

  if(!store.state.isNewMessageProcessing) {
    //update the messageCache id
    store.commit(STORE_MUTATION.UpdateMessageCacheID, {
      id: extMsgId,
      newId: JSON.parse(response.response).recordInfo.messageCacheId
    } as IMessage);
  }
  return response;
 }

	/**
	 * Get clientVersion after validating
	 * @param {string} version - The verions passed from the client end
	 */
	 getClientVersion = (version: string = "") => {
		let clientVersion = 0;
		try {
			// Validate that `version` is not null or empty
			// Also, verify that it is not a NaN type value
			if (!Util.isNullOrEmpty(version) && !isNaN(Number(version))) {
				clientVersion = Number(version);
			} else {
				clientVersion = 0;
			}
		} catch (ex) { }
		return clientVersion;
	}

  async getMessage(lastMsgId: number, cacheIdType: string, recoonect: boolean, clientVersion = "", sourceInfo = "") {
    const request = new HttpRequest(`${this.appUrl}default/getmessage/}`);
    var localTime;

    try {
      localTime = localStorage.getItem(store.state.convId);
    }
    catch (ex) {
      localTime = getCookie(store.state.convId);
    }

    let data = {
      ConvId: store.state.convId,
      UserId: store.state.userId,
      UserIdSourceTypeId: store.state.userIdSourceTypeId,
      CompanyId: store.state.companyId,
      //CompanyToken: store.state.companyToken,
      BotId: store.state.extBotId,
      //SendMsgUrl: store.state.sendMsgUrl,
      BeforeMessageCacheId: null,
      AfterMessageCacheId: null,
      LocalTime: localTime,
      IsReconnect: recoonect,
      ClientVersion: this.getClientVersion(clientVersion),
      NewMessageProcessing: store.state.isNewMessageProcessing,
      SendMessageUrl: store.state.sendMsgUrl,
      SourceInfo: sourceInfo,
      UserAgent: navigator.userAgent
      // send time too from localstroage
    };
    cacheIdType == CACHE_ID_TYPE.AfterMessageCacheId
      ? (data.AfterMessageCacheId = lastMsgId)
      : (data.BeforeMessageCacheId = lastMsgId);
    const response = await request.sendPost(data);
    return response;
  }

  //TODO: add new method to remove
  async removeDataFromRedis() {
    const request = new HttpRequest(`${this.appUrl}default/removeDataFromRedis/}`);
    let data = {
      ConvId: store.state.convId
    };
    try {
      localStorage.removeItem(store.state.convId);
    }
    catch (ex) {
      deleteCookie(store.state.convId);
    }

    const response = await request.sendPost(data);
  }
}
