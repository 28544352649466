import { getUniqId, isLoadInIFrame } from "../helpers";
import { StateOption } from "../types";

declare var SatisfiApp;

export const states: StateOption = {
  apiServer: "https://api.satisfi4.com",
  companyId: 0,
  companyToken: " ",
  extBotId: 0,
  convId: "",
  userId: "",
  cfHeader: "",
  messages: [],
  isBotHasAvatar: false,
  avatarSrc: "",
  isConfigurationLoaded: false,
  maintainState: false,
  sendMsgUrl: "",
  prefixClassName: "satisfi_",
  showInfoPanel: false,
  errorMessage: null as any,
  showTypingIndicator: false,
  showProcessingMessage: false,
  processingDisplayIcon: "",
  processingDisplayText: "",
  pageId: "",
  socketId: getUniqId(),
  lastMessageId: null,
  shouldShowBanner: false,
  isAppInAdaMode: false,
  retryWarningCount: 10,
  retryInterval: 10,
  isIFrameLoaded: isLoadInIFrame(),
  isShowHeaderInIframe: true,
  isHideTitleBar: false,
  satisfiURL: "",
  headerImage: "",
  backgroundImage: "",
  headerImageDestinationURL: "",
  isBrowseUrlOnSametab: false,
  typingMessageSecondsInterval: 10,
  typingType: 0,
  isUserHasAvatar: false,
  AWSServerURL: "",
  AWSThemeFilePath: "",
  AWSImageLogoPath: "",
  AWSPopupConfigPath: "",
  popupId: "0",
  pageReferralUrl: "",
  disableInput: false,
  defaulthideInputContainerSetting: false,
  lastFocusableElement: {},
  bannerText: '',
  userLat: "",
  userLng: "",
  locationBlocked: false,
  userIdSourceTypeId: 0,
  parentSiteUrl: "",
  parentSiteReferralUrl: "",
  requestResponseReceived: true,
  focusedWindow: true,
  isSignalRConnected: false,
  isAnchorTagClickedSametabNewtab: false,
  clientVersion: "",
  minimumClientVersion: "",
  isTilesOpenedInIframe: false,
  showAdminLoginModal: false,
  showConsentModal: false,
  isAdminMode: false,
  enableAdminMode: false,
  webApiAddress: "",
  dashboardAddress: "",
  attemptNoofInvalidCode: 5,
  attemptNoofResendCode: 5,
  mfaExpiration: 30,
  mfaEnableDateTime: "",
  authToken: "",
  authTokenExpiration: 60,
  isMFAEnabled: false,
  refreshTask: null,
  isTermsConsentEnabled: false,
  isFirstUserMessage: true,
  isTermsOfServiceAccepted: false,
  termsConsentPayload: "",
  termsConsentExpiration: "0",
  consentStatusOptions: null,
  formInputs: [],
  showWelcomeBanner: false,
  isNewMessageProcessing: false,
  isMaintainStateMessages: false,
  enableMessageNavigation: false,
};
