var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.getClass("bubble") +
        " " +
        ((_vm.isBubbleHasButton !== null && _vm.isBubbleHasButton) ||
        (_vm.isBubbleHasLinkButton !== null && _vm.isBubbleHasLinkButton) ||
        (_vm.isBubbleHasImage !== null &&
          _vm.isBubbleHasImage &&
          !_vm.isImageLoaded)
          ? _vm.getClass("fill_available")
          : ""),
    },
    [
      _vm._l(_vm.components, function (component, index) {
        return [
          component.type == "text"
            ? _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.decodeTags(component.content)),
                },
              })
            : component.type == "html"
            ? _c("p", { domProps: { innerHTML: _vm._s(component.content) } })
            : component.type == "markdown"
            ? _c("p", {
                domProps: { innerHTML: _vm._s(_vm.marked(component.content)) },
              })
            : component.type == "button" || component.type == "link"
            ? _c("Button", {
                attrs: {
                  component: component,
                  messageContentsettings: _vm.messageContentsettings,
                },
              })
            : component.type == "image"
            ? _c("Img", { attrs: { component: component } })
            : component.type == "line"
            ? _c("hr")
            : component.type == "break"
            ? _c("br")
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }