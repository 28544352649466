var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        (_vm.isMobileActive && _vm.isMobileActive !== null
          ? _vm.getClass("alertBannerMobile")
          : "") +
        " " +
        _vm.getClass("alertBanner"),
    },
    [
      _c("p", { class: _vm.getClass("alertBannerMessage") }, [
        _vm._v("\r\n        " + _vm._s(_vm.bannerText) + "\r\n        "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }