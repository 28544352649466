import { __vueEvent } from "../constant";
import { store } from "../store/store";
import { SIGNALR_STATUS } from "../enums/signalr_status";
import { onExceptionOccured, logServerSideDebug } from "../helpers/on_exception_occured";
import * as signalR from "@microsoft/signalr";
import {
  getAppUrl,
  onMessageFromServer,
  getMessageFromServer,
  removeDataFromRedis,
  getMsgFromContent,
  onTypingFromServer,
  onProcessingFromServer
} from "../helpers/index";
import { STORE_MUTATION, EVENTS, CACHE_ID_TYPE } from "../enums";
import { MessageStructure, MessageStructureComponent } from "../types";
import { setCookie } from "../helpers/cookie_handler";

let signalrConnection: signalR.HubConnection;
let isConnectToSignalRCalled = false;
export class SignalrHandler {
  reconnectTimeValue = store.state.retryInterval;

  disconnectCount = 0;
  stop() {
    signalrConnection.stop();
    console.log("signalr disconnected at: ", new Date());
  }

  constructor() {
    this.listenEvents();
    signalrConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${getAppUrl()}chatHub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.None)
      .build();
  }

  public listenEvents() {
    __vueEvent
      .$on(EVENTS.ConnectSignalr, () => {
        if (
          signalrConnection.state === signalR.HubConnectionState.Disconnected && store.state.focusedWindow
        ) {
          this.disconnectCount = 0;
          this.retryConnection();
        }
      })
      .$on(EVENTS.StopSignalr, this.stop)
      .$on(EVENTS.RemoveDataFromRedis, () => {
        this.removeDataFromRedis();
      });
  }

 async removeDataFromRedis() {
  const msgs = await removeDataFromRedis();
 }

  /**
   * Fetches failed messages from the server and updates the state with the retrieved messages.
   * @returns {Promise<void>} A promise that resolves when the messages are fetched and updated.
   */
  async fetchFailedMessages() {
    let lastMessageId = 0;
    const stateMessageLength = store.state.messages.length;
    for (let i = stateMessageLength - 1; i >= 0; i--) {
      if (Number(store.state.messages[i].id)) {
        lastMessageId = Number(store.state.messages[i].id);
        break;
      }
    }

    // Get messages from the server
    const msgs = await getMessageFromServer(
      lastMessageId,
      CACHE_ID_TYPE.AfterMessageCacheId,
      true,
      store.state.clientVersion,
      `signalr_handler.ts:fetchFailedMessages`
    );

    const msgLength = msgs.length;
    var stateMsgLength = store.state.messages.length - 1;
    if (msgLength > 0) {
      try {
        localStorage.setItem(msgs[0].keys.extConvId, msgs[0].messageTime);
      }
      catch(ex){
        setCookie(msgs[0].keys.extConvId, msgs[0].messageTime);
      }
      // ctx.commit(STORE_MUTATION.SetIsAppInAdaMode, true);
      msgs.forEach(function (msg, index) {
        if (
          store.state.messages.every(m => m.id != msg.recordInfo.messageCacheId)
        ) {

          if (store.state.isAnchorTagClickedSametabNewtab &&
            store.state.messages[stateMsgLength].contents.keys["ExtMsgId"] === msg.keys["ExtMsgId"]) {

            store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
            store.state.messages[stateMsgLength].id = msgs[0].recordInfo.messageCacheId;
            store.commit(STORE_MUTATION.SetIsAnchorTagClickedSametabNewtab, false);
            return;
          }

          store.commit(STORE_MUTATION.AddMessage, getMsgFromContent(msg));
        }

        if (index !== msgLength - 1) {
          store.commit(STORE_MUTATION.RemoveAllButtons);
        }
      });
    }
  }

  lastRetryExecution = 0; // Store the last time retryConnection was called
  retryDelay = 500;		  // Prevent any duplicate calls within 500ms

  retryConnection = () => {

    store.commit(STORE_MUTATION.SetIsSignalRConnected, false);

	// WEBAPPS-959: This logic is used to prevent function from executing multiple times within 500ms delay
	if (this.lastRetryExecution >= (Date.now() - this.retryDelay))
    	return;
	this.lastRetryExecution = Date.now();

    setTimeout(async () => {
      __vueEvent.$emit(SIGNALR_STATUS.Connecting);
      try {
        await this.createConnection_();
        this.fetchFailedMessages();
      } catch (ex) {
        isConnectToSignalRCalled = false;
        ++this.disconnectCount;
        if (this.disconnectCount < store.state.retryWarningCount && store.state.focusedWindow) {
          this.retryConnection();
        } else {
          __vueEvent.$emit(SIGNALR_STATUS.Disconnected);
        }
      }
    }, this.reconnectTimeValue);
  };

  listenToConnectionEvents_() {
    signalrConnection.onclose(() => {
		// If the current tab is in focus, then only reconnect
		if (!!store.state.focusedWindow) {
			this.retryConnection();
		}
    });
  }

  async createConnection_() {
    if (
      signalrConnection.state === signalR.HubConnectionState.Disconnected &&
      isConnectToSignalRCalled == false
    ) {
      if (typeof WebSocket.OPEN == "function") {
        Object.defineProperty(WebSocket, "OPEN", { value: 1 });
      }
      isConnectToSignalRCalled = true;
      await signalrConnection.start();
      isConnectToSignalRCalled = false;
      await this.sendClientInfo();
      __vueEvent.$emit(SIGNALR_STATUS.Connected);
      store.commit(STORE_MUTATION.SetIsSignalRConnected, true);
    }
  }

  async sendClientInfo() {
    var conInfo = {
      ConversationId: store.state.convId
    };

    signalrConnection.send("SetClientInfo", conInfo);
  }

  async start() {
    __vueEvent.$emit(SIGNALR_STATUS.Connecting);
    this.listentToServerEvents_();
    this.listenToConnectionEvents_();
    try {
      await this.createConnection_();
    } catch (ex) {
      isConnectToSignalRCalled = false;
      //onExceptionOccured("start in signalr handler", ex);
    }
    window["signalrConnection"] = signalrConnection;
  }

  listentToServerEvents_() {
    signalrConnection.on("message", onMessageFromServer);
    signalrConnection.on("typing", onTypingFromServer);
    signalrConnection.on("processing", onProcessingFromServer);
  }
}
