//#region Imports Packages and Libs

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { IMessage } from "../interfaces/message";
import { MessageStructureComponent, MessageContentSettings } from "../types";
import { store } from "../store/store";
import { STORE_MUTATION } from "../enums/store_mutation";
import Button from "../components/button.vue";
import Img from "../components/image.vue";
import FormInput from "../components/form_input.vue";
import { isMobile } from "../helpers/is_mobile";
import { Util } from "../util";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";

//#endregion

//#region Enums

/**
 * Enum for Modal Load Behavior
 */
enum LoadBehaviorEnum {
    PLACEHOLDER_ONLY = 'placeholder_only',
    AUTO_OPEN_WITH_PLACEHOLDER = 'auto_open_with_placeholder',
    AUTO_OPEN_WITHOUT_PLACEHOLDER = 'auto_open_without_placeholder',
}

//#endregion

//#region Define the component in class-style

declare var marked;
@Component({
    components: { Button, Img, FormInput },
    props: {
        messageId: String,
        //showModal: Boolean,
        components: Array,
        messageSettings: Object,
        messageContentsettings: Object
    },
    computed: mapState(['isBotHasAvatar', 'typingType', 'isUserHasAvatar'])
})
export default class Modal extends Vue {
    //#region Class properties will be component data

    messageId!: any;
    showModal = false;
    messageSettings!: any;
    messageContentsettings!: MessageContentSettings
    components!: MessageStructureComponent[];
    //timeoutId!: ReturnType<typeof setTimeout> | undefined;
    placeholderLabel!: string;
    placeholderIcon!: string;
    placeholderIconSize!: string;
    isPlaceholderIconImage!: boolean;
    LoadBehaviorEnum = LoadBehaviorEnum;

    //#endregion

    //#region Modal Lifecycle Hooks

    created() {
        this.setModalSettings();
    }

    mounted() {
        this.listenEvents();
    }

    /**
     * This computed prop is used to get the icon image style.
     */
    get iconImageStyle() {
        return {
            width: this.placeholderIconSize || '25px',
            height: this.placeholderIconSize || '25px',
            display: 'flex'
        };
    }

    /**
     * This computed prop is used to get unique id for the modal description.
     */
    get modalDescriptionId() {
        return `modalDescription-${this.messageId}`
    }

    //#endregion

    //#region Modal related logic here

    listenEvents() {
        __vueEvent.$on(EVENTS.ButtonClick, this.closeModal)
    }

    /**
     * This method is used to get the svg icon for the placeholder.
     */
    getSvgIcon() {
        return `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.87" style="enable-background:new 0 0 122.88 122.87" xml:space="preserve"
                width="${this.placeholderIconSize || '25px'}" height="${this.placeholderIconSize || '25px'}" aria-hidden="true">
            <g>
            <path d="M122.88,77.63v41.12c0,2.28-1.85,4.12-4.12,4.12H77.33v-9.62h35.95c0-12.34,0-23.27,0-35.62H122.88L122.88,77.63z M77.39,9.53V0h41.37c2.28,0,4.12,1.85,4.12,4.12v41.18h-9.63V9.53H77.39L77.39,9.53z M9.63,45.24H0V4.12C0,1.85,1.85,0,4.12,0h41 v9.64H9.63V45.24L9.63,45.24z M45.07,113.27v9.6H4.12c-2.28,0-4.12-1.85-4.12-4.13V77.57h9.63v35.71H45.07L45.07,113.27z"></path>
            </g>
        </svg>`;
    }

    /**
     * The function checks if an image exists at a given URL and calls a callback function with a
     * boolean value indicating the result.
     * @param url - The URL of the image you want to check if it exists.
     * @param callback - The callback parameter is a function that will be called once the image has
     * been checked for existence. It takes a single argument, a boolean value indicating whether the
     * image exists or not.
     */
    checkIfImageExists(url, callback) {
        try {
            const img = new Image();
            img.src = url;

            if (img.complete) {
                callback(true);
            } else {
                img.onload = () => {
                    callback(true);
                };

                img.onerror = () => {
                    callback(false);
                };
            }
        } catch (ex) {
            callback(false);
            console.log("[modal.ts] An error has occurred \n-> Inside checkIfImageExists -> Error: ", ex);
        }
    }

    /**
     * This method is used to set the modal settings.
     */
    setModalSettings() {
        try {

            // Set the default placeholder label and icon
            this.placeholderLabel = 'Show Modal';
            this.placeholderIconSize = '25px';

            // Set the placeholder label and icon
            if (this.messageSettings && Object.keys(this.messageSettings).length !== 0) {
                this.placeholderLabel = this.messageSettings.placeholderLabel || 'Show Modal';
                this.placeholderIcon = this.messageSettings.placeholderIcon || '';
                this.placeholderIconSize = this.messageSettings.placeholderIconSize || '25px';

                // Set the show modal property
                if (this.messageSettings.modalLoadBehavior === LoadBehaviorEnum.AUTO_OPEN_WITH_PLACEHOLDER) {
                    this.showModal = true;
                } else if (this.messageSettings.modalLoadBehavior === LoadBehaviorEnum.AUTO_OPEN_WITHOUT_PLACEHOLDER) {
                    this.showModal = true;
                } else {
                    this.showModal = false;
                }
            }

            // Check if the placeholder icon image exists
            if (!Util.isNullOrEmpty(this.placeholderIcon)) {
                this.isPlaceholderIconImage = true;
                /*this.checkIfImageExists(this.placeholderIcon, (exists: any) => {
                    if (exists) {
                        // Success code
                        this.isPlaceholderIconImage = true;
                    } else {
                        // Fail code
                        this.isPlaceholderIconImage = false;
                        this.placeholderIcon = this.getSvgIcon();
                    }
                });
                */
            } else {
                this.isPlaceholderIconImage = false;
                this.placeholderIcon = this.getSvgIcon();
            }

        } catch (ex) {
            console.log("[modal.ts] An error has occurred \n-> Inside setModalSettings -> Error: ", ex);
        }
    }

    /**
     * This method is called when the user clicks the close modal button.
     */
    closeModal(id) {
        // store.commit(STORE_MUTATION.SetShowModal, {
        //     id: id,
        //     contents: {
        //         structures: [{ showModal: false }]
        //     }
        // } as IMessage);

        // Clear the timeout if it is set
        // if (this.timeoutId) {
        //     clearTimeout(this.timeoutId);
        // }
        // this.timeoutId = setTimeout(() => {
        //     this.showModal = false;
        // }, 350);

        this.showModal = false;
    }

    /**
     * This method is called when the user clicks the open modal message bubble.
     */
    openModal() {
        this.showModal = true;
    }

    marked(value) {
        return marked(value);
    }

    decodeTags(content: string = '') {
        return content.replace(/&amp;/g, '&').replace(/&amp;/g, '&');
    }

    /**
     * The function returns a class name by concatenating a prefix with a given value.
     * @param value - The value parameter is a string representing the class name that you want to
     * append to the prefixClassName.
     * @returns the concatenation of the value parameter and the prefixClassName property of the
     * store.state object.
     */
    getClassName(value) {
        return store.state.prefixClassName + value;
    }

    @Watch('showModal', { immediate: true })
    onPropertyChanged(value: any) {
        try {
            let userAgentString = navigator.userAgent.toLowerCase();
            let safariIndex = userAgentString.indexOf("safari");

            if (document.body != null && safariIndex != -1) {
                // If the modal is shown, add the class to the body
                if (value) {
                    // Check if body has the class already
                    document.body.classList.add(this.getClassName("modal-show"));
                } else {
                    // If the modal is hidden, remove the class from the body
                    if (document.body.classList.contains(this.getClassName("modal-show"))) {
                        document.body.classList.remove(this.getClassName("modal-show"));
                        __vueEvent.$emit(EVENTS.ScrollToNewMessageTop);
                    }
                }
            }

            // Update the modal height if the modal is shown
            if (value) {
                this.updateModalHeight();

                // Clear the form inputs if the modal is shown first time
                store.commit(STORE_MUTATION.ClearFormInputs);
            }
        } catch (ex) {
            console.log("[modal.ts] An error has occurred \n-> Inside onPropertyChanged -> Error: ", ex);
        }
    }


    /**
     * The function sets a CSS property on the document's root element if the value is truthy.
     * @param {string} propertyName - A string representing the name of the CSS property to be set.
     * @param value - The value parameter is the value that you want to set for the specified CSS
     * property.
     */
    setPropertyIfExist(propertyName: string, value) {
        if (value) {
            document.documentElement.style.setProperty(propertyName, `${value * 100}%`);
        }
    }

    /**
     * The function removes a CSS property from the root element of the document if it exists.
     * @param {string} propertyName - The name of the CSS property that you want to remove.
     */
    removePropertyIfExist(propertyName: string) {
        let compStyles = window.getComputedStyle(document.documentElement);
        if (!compStyles) return;
        const propertyValue = compStyles.getPropertyValue(propertyName);
        if (!Util.isNullOrEmpty(propertyValue)) {
            document.documentElement.style.removeProperty(propertyName);
        }
    }

    /**
     * This method is used to update the css variable named `--modalHeightDesktop` and `--modalHeightMobile` in the root element.
     */
    updateModalHeight() {
        try {
            let compStyles = window.getComputedStyle(document.documentElement);
            if (compStyles && this.messageSettings && Object.keys(this.messageSettings).length !== 0) {
                let isHeightExist = this.messageSettings.height || 0;
                let isHeightDesktopExist = this.messageSettings.heightDesktop || 0;
                let isHeightMobileExist = this.messageSettings.heightMobile || 0;

                // Set modal height for desktop
                if (isHeightDesktopExist || isHeightExist) {
                    this.setPropertyIfExist('--modalHeightDesktop', this.messageSettings.heightDesktop || this.messageSettings.height);
                } else {
                    this.removePropertyIfExist('--modalHeightDesktop');
                }

                if (isHeightMobileExist || isHeightExist) {
                    this.setPropertyIfExist('--modalHeightMobile', this.messageSettings.heightMobile || this.messageSettings.height);
                } else {
                    this.removePropertyIfExist('--modalHeightMobile');
                }
            }
        } catch (ex) {
            console.log("[modal.ts] An error has occurred \n-> Inside updateModalHeight -> Error: ", ex);
        }
    }

    //#endregion
}

//#endregion