import { getUniqId } from "./get_uniq_id";
import { _convId } from "../constant";
import { StorageHelper } from "./storage_helper";
import { store } from "../store/store";

export const getConvId = function (maintainState: boolean, pageId: string, convId: string = "") {
    let uniqueId = "";
    let uniqueConvId = _convId.concat(pageId);
    //userIdSourceTypeId is set as 3 for the user id supplied from the URL
    if (store.state.userIdSourceTypeId === 3) {
        uniqueConvId = uniqueConvId.concat(store.state.userId);
    }
    if (maintainState) {
        uniqueId = StorageHelper.get(uniqueConvId);
        if (uniqueId == null) {
            // Conversation id is now set inside the GetPageConfig method,
            // on server-side, so we don't need to set it here.
            //uniqueId = getUniqId();
            uniqueId = convId;
            StorageHelper.set(uniqueConvId, uniqueId);
        }
    }
    else {
        if (StorageHelper.get(uniqueConvId) != null) {
            StorageHelper.remove(uniqueConvId);
        }
        uniqueId = convId;
    }
    return uniqueId;
}