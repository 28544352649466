var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a",
        { class: _vm.getClass("skip"), attrs: { href: "#divChatBody" } },
        [_vm._v("Skip to main content")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isConfigurationLoaded,
              expression: "isConfigurationLoaded",
            },
          ],
          class:
            (_vm.isMobileActive && _vm.isMobileActive !== null
              ? _vm.getClass("chatMobile") +
                " " +
                _vm.getClass("standaloneMobile")
              : "") +
            " " +
            _vm.getClass("chat") +
            " " +
            _vm.getClass("standalone") +
            " " +
            (_vm.enableMessageNavigation && _vm.enableMessageNavigation !== null
              ? _vm.getClass("msg-navigation-enabled")
              : ""),
        },
        [
          _c(
            "div",
            {
              class:
                (_vm.isMobileActive
                  ? _vm.getClass("chatContainerMobile")
                  : "") +
                " " +
                _vm.getClass("chatContainer"),
            },
            [
              !_vm.isIFrameLoaded &&
              (!_vm.isHideTitleBar || !_vm.hideIFrameHeader)
                ? _c("Header")
                : _vm._e(),
              _vm._v(" "),
              _c("Chat"),
              _vm._v(" "),
              _c("Footer"),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isConfigurationLoaded,
            expression: "isConfigurationLoaded",
          },
        ],
        class: _vm.getClass("safariTempFooter"),
      }),
      _vm._v(" "),
      !_vm.isConfigurationLoaded && _vm.errorMessage == null
        ? _c("Loader")
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessage != null ? _c("Error") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }