import { getUniqId } from "./get_uniq_id";
import { _convId } from "../constant";
import { StorageHelper } from "./storage_helper";

export const getUserId = function () {
    let uniqueId = "";
        uniqueId = StorageHelper.get("user_id");
        if (uniqueId == null) {
            uniqueId = getUniqId();
            StorageHelper.set("user_id", uniqueId);
        }
    return uniqueId;
}