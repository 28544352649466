//#region Imports Packages and Libs

import { store } from "../store/store";
import { UserService } from "../service/user_service";
import { STORE_MUTATION } from "../enums";
import { getUniqId } from "./get_uniq_id";
import { IMessage } from "../interfaces";

//#endregion

//#region Send Admin Message Function

/**
 * This function is used to send admin message to EBM after login and logout trigger message.
 * @param messageType - Admin message type (Login or Logout) enum.
 */
export const sendAdminMessage = async function (messageType: any) {
    const msgId = getUniqId();
    try {
        store.commit(STORE_MUTATION.SetRequestResponseReceived, false);
        const service = new UserService();
        const newMessageResponse = await service.sendAdminMessage(messageType, msgId);

        // store.commit(STORE_MUTATION.UpdateMessageCacheID, {
        //     id: msgId,
        //     newId: JSON.parse(newMessageResponse.response).recordInfo.messageCacheId
        // } as IMessage);

    } catch (error) {

        store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
        // store.commit(STORE_MUTATION.UpdateMessageStatus, {
        //     id: msgId,
        //     status: "failed"
        // } as IMessage);

        console.log("Error in sendAdminMessage: " + error);
        return null;
    }
};

//#endregion
