import * as Fingerprint2 from "fingerprintjs2";
import { getCookie, setCookie } from "./cookie_handler";
export const getBrowserId = (): Promise<string> => {
    return new Promise(function (resolve, reject) {
        let browserId;
        try {
            if (localStorage == null) {
                browserId = getCookie('browser_id');
            } else {
                browserId = localStorage.getItem('browser_id');
            }
        } catch { }
        if (browserId == null) {
            const options = {}
            var getBrowserIdFromFingerPrint = function () {
                Fingerprint2.getV18(options, function (result) {
                    result += "_webchat2";
                    try {
                        if (localStorage == null) {
                            setCookie('browser_id', result);
                        } else {
                            localStorage.setItem('browser_id', result);
                        }
                    } catch { }
                    resolve(result);
                });
            };
            if (window['requestIdleCallback']) {
                window['requestIdleCallback'](getBrowserIdFromFingerPrint)
            } else {
                setTimeout(getBrowserIdFromFingerPrint, 500);
            }
        }
        else {
            resolve(browserId);
        }
    });
}