export const installSignalr = async function () {
    return new Promise(function (res) {
        var script = document.createElement("SCRIPT") as HTMLScriptElement;
        script.src = 'https://cdn.jsdelivr.net/npm/signalr@2.4.0/jquery.signalR.min.js';
        script.onload = function () {
            res();
        }
        document.head.appendChild(script);
    })

}