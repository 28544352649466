var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.getClass("image") +
        " " +
        (_vm.isLoaded != null && _vm.isLoaded ? _vm.getClass("loaded") : ""),
      on: { click: _vm.onElClick },
    },
    [
      _c(
        "transition",
        { attrs: { name: "satisfi_fade2" } },
        [
          !_vm.isLoaded
            ? _c("ImageSpinner", { class: _vm.getClass("image__spinner") })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("img", {
        class: _vm.isFirefoxSVGClass ? _vm.getClass("firefox-svg") : "",
        staticStyle: { height: "auto", width: "100%" },
        attrs: {
          src: _vm.component.imageUrl,
          alt: _vm.component.alt || "Image description missing",
          tabIndex: _vm.enableMessageNavigation ? 0 : -1,
          loading: "lazy",
          role: _vm.enableMessageNavigation ? "img" : null,
        },
        on: { load: _vm.onImgLoad, error: _vm.onImgLoad },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }