import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { MessageContentSettings, MessageStructureComponent } from "../types";
import Button from "../components/button.vue";
import { ActionHandler } from "../helpers";
import Img from "../components/image.vue";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";

declare var marked;
@Component({
    components: { Button, Img },
    props: {
        components: Array,
        structure: Object,
		messageContentsettings: Object
    },
    computed: {
        ...mapState(['prefixClassName'])
    }
})
export default class Bubble extends Vue {
    //property
    components!: MessageStructureComponent[];
	messageContentsettings!: MessageContentSettings

	// WEBAPPS-917
	// This is used to determine if the bubble
	// has any button or links, based on that we will add class to it.
	isBubbleHasButton = !!this.components
		&& this.components.filter(c => c.type=='button' || c.type=='link').length > 0;

	// SD-1220
	// This is used to determine if the bubble
	// has any links (<a> tag with class "satisfi_link_button"), based on that we will add class to it.
	isBubbleHasLinkButton = this.components && this.components.length > 0
		&& this.components.filter(c => c.type != 'text' && c.content && c.content.indexOf("satisfi_link_button") >= 0).length > 0;

	// SD-1706
	// This is used to determine if the bubble has any images
	isBubbleHasImage = !!this.components && this.components.filter(c => c.type && c.type=='image').length > 0;
	isImageLoaded = false;

	mounted() {
        this.listenEvents();
    }

    marked(value) {
        return marked(value);
    }

    decodeTags(content: string = ''){
        return content.replace(/&amp;/g, '&').replace(/&amp;/g, '&');
    }

	/**
	 * SD-1706
	 * This is used to listen to needed events
	 */
	listenEvents() {
		try {
			__vueEvent
				.$once(EVENTS.ChatBubbleImageLoaded, () => {
					this.isImageLoaded = true;
				})

		} catch (ex) {
            //var errorObj = { Page: 'quick_reply.ts', MethodName: 'listenEvents', Message: ex.message };
            //console.log(errorObj);
        }
	}
}
