import { IMessage } from "../interfaces";
import { MESSAGE_TYPE } from "../enums";
import { MessageContent } from "../types";
import { getMessageType } from "./get_msg_type";

export const getMsgFromContent = function (msg: MessageContent) {
    msg.recordInfo.messageDateTime = new Date(msg.recordInfo.messageDateTime + ' UTC');
    return {
        contents: msg,
        type: getMessageType(msg.recordInfo.senderType),
        id: (msg as any).recordInfo.messageCacheId,
        status: "ok"
    } as IMessage;
}