import { MESSAGE_TYPE } from "../enums";

export const getMessageType = (type: string) => {
    switch (type) {
        case 'customer':
            return MESSAGE_TYPE.User;
        case 'agent':
            return MESSAGE_TYPE.Agent;
        default:
            return MESSAGE_TYPE.Bot;
    }
}