import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums";
import { isMobile } from "../helpers/is_mobile";
import { store } from "../store/store";

@Component({
    computed: mapState(['shouldShowBanner', 'bannerText'])
})
export default class Banner extends Vue {

    isMobileActive = isMobile.any() || this.$store.state.isIFrameLoaded;

    tryAgain() {
        if (store.state.bannerText === "Connecting...") {
            __vueEvent.$emit(EVENTS.ConnectSignalr);
        }
    }
}
