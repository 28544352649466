import Vue from "vue";
export const __vueEvent = new Vue();
export const __oneMinute = 60000;
export const __onTransitionEnd = "animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd";
export const _convId = 'conv_id';
export const __maintainState = "maintain_state";
export const __maintainStateTime = "maintain_state_time";
export let WebChatConnectionRetryWarningCount = '';
export let WebChatConnectionRetryInterval = '';
export const __hideInputContainerAlways = "hide_input_container_always";
export const __locationCheckTimeout = 10000;
export const __locationCheckInterval = 100;
