import { IMessage } from "../interfaces";
import { MESSAGE_COMPONENT_TYPE } from "../enums";
import { urlify } from ".";

export const validateAndModify = function (value: IMessage) {
    value.contents.structures.forEach(structure => {
        structure.components.forEach(component => {
            if (component.type === MESSAGE_COMPONENT_TYPE.Text) {
                component.content = urlify(component.content);
            }
            if (component.style == null) {
                component.style = {};
            }
        })
    });
    return value;
}