var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.getClass("input-container") }, [
    _c(
      "form",
      {
        class: _vm.getClass("form-inline"),
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _vm.rows && _vm.rows > 1
          ? _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.fieldValue,
                  expression: "fieldValue",
                  modifiers: { trim: true },
                },
              ],
              class: _vm.getClass("modal-input "),
              attrs: {
                rows: _vm.rows,
                cols: "50",
                name: _vm.component.name ? _vm.component.name : "field_name",
                maxlength: _vm.component.maxLength
                  ? _vm.component.maxLength
                  : 1000,
                placeholder: _vm.component.placeholder
                  ? _vm.component.placeholder
                  : "Enter text here",
                "aria-label": _vm.component.placeholder
                  ? _vm.component.placeholder
                  : "Enter text here",
                spellcheck: "false",
              },
              domProps: { value: _vm.fieldValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.fieldValue = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.fieldValue,
                  expression: "fieldValue",
                  modifiers: { trim: true },
                },
              ],
              class: _vm.getClass("modal-input "),
              attrs: {
                type: "text",
                name: _vm.component.name ? _vm.component.name : "field_name",
                maxlength: _vm.component.maxLength
                  ? _vm.component.maxLength
                  : 1000,
                placeholder: _vm.component.placeholder
                  ? _vm.component.placeholder
                  : "Enter text here",
                "aria-label": _vm.component.placeholder
                  ? _vm.component.placeholder
                  : "Enter text here",
                spellcheck: "false",
                autocomplete: "off",
              },
              domProps: { value: _vm.fieldValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.fieldValue = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }