import { MessageStructureComponent, MessageContentSettings, MessageOptions } from "../types";
import { ChatService } from "../service/chat_service";
import { StorageHelper, addUserMessage } from ".";
import { Util } from "../util";
import { DEVICE_TYPE, STORE_MUTATION, EVENTS, CLICK_ACTION_POSTBACK } from "../enums";
import { store } from "../store/store";
import { __locationCheckInterval, __locationCheckTimeout, __vueEvent } from "../constant";
import { isLoadInIFrame } from "./is_load_in_iframe";
import { getUserLocation } from "./get_user_location";

const createAndRedirectToLink = function (
  url: string,
  target: string,
  type: string,
  hideInChatBackButton: boolean = false,
  iframeBackButtonLabel: string = undefined
) {
  const mapBrowserTarget = function () {
    switch (target) {
      case "newtab":
        return "_blank";
      default:
        return "";
    }
  };

  const getHref = function () {
    switch (type) {
      case "phone":
        return `tel:${url}`;
      case "sms":
        return `sms:${url}`;
      case "email":
        return `mailto:${url}`;
      default:
        return url;
    }
  };

  if (!Util.isNullOrEmpty(url)) {
    if (isLoadInIFrame()) {
      //Temporary fix to open the button url as a separate tab
      try{
        console.log(window.self);
        if(window.self.location.href.indexOf("url_target=new_window") != -1){
        window.open(url);
        return false;
      }
    } catch(ex){
      console.error(ex);
    }
    
      if (target !== "iframe") {

        window.parent.postMessage(
          {
            event_id: "onRedirectURL",
            data: {
              href: getHref(),
              target: target === "inchat" ? target : mapBrowserTarget(),
              hideInChatBackButton: hideInChatBackButton
            }
          },
          "*"
        );
      } else {
        // If the settings.browserTarget is "iframe"
        // the open the url in a dynamically created iframe
        makeIFrame(getHref(), iframeBackButtonLabel);

        // Send a message to the parent window
        window.parent.postMessage(
          {
            event_id: "onRemoteURLOpenedInIFrame",
            data: {
              href: getHref(),
              hideIFrameHeader: false,
			  iframeBackButtonLabel: iframeBackButtonLabel
            }
          },
          "*"
        );
      }
    } else {

      // If the target it anything other than "iframe"
      if (target !== "iframe") {
        if (target === "sametab") {
          store.commit(STORE_MUTATION.SetIsBrowseUrlOnSametab, true);
        }

        if (document.getElementById("anchorTagOnButtonClick") !== null) {
          document.getElementById("anchorTagOnButtonClick").remove();
        }

        const a = document.createElement("a");
        a.href = getHref();
        a.rel = "noreferrer noopener";
        a.target = mapBrowserTarget();
        document.body.appendChild(a);
        a.id = "anchorTagOnButtonClick";
        store.commit(STORE_MUTATION.SetIsAnchorTagClickedSametabNewtab, true);
        // a.className = `satisfi_btn satisfi_btnLink`;
        // a.innerHTML = 'View Link';
        // a.style.position = 'absolute';
        // a.style.top = '0';
        // a.style.left = '0';
        // a.style.zIndex = '2147483648';
        // a.style.width = 'fit-content';

        a.click();
        a.remove();
      } else {

        // If the settings.browserTarget is "iframe"
        // the open the url in a dynamically created iframe
        makeIFrame(getHref(), iframeBackButtonLabel);
      }
    }
  }
};

/**
 * Make a new iframe to open an external URL.
 * This happens only if the settings.browserTarget is "iframe"
 */
function makeIFrame(url, iframeBackButtonLabel) {
  var ifrm = document.getElementById("remote_browser_target");
  if (!ifrm) {
    ifrm = document.createElement("IFRAME");
    ifrm.id = "remote_browser_target";
    ifrm.className = `${store.state.prefixClassName}remote_browser_target`;
    ifrm.setAttribute("src", url);
    var chatContainer = document.querySelector(`.${store.state.prefixClassName}chatContainer`);
    if (chatContainer) {
      chatContainer.appendChild(ifrm);
      if (!isLoadInIFrame()) {
        __vueEvent.$emit(EVENTS.UrlOpenedInIFrame, iframeBackButtonLabel);
      } else {

        // Set the headerHeight to 0, so that iframe has no gap at the top
        // As when the chat is opened inside an popup, then we remove the main chat header
        try {
          let compStyles = window.getComputedStyle(document.documentElement);
          if (compStyles) {
            let headerHeight = compStyles.getPropertyValue('--headerHeight');
            if (!!headerHeight) {
              document.documentElement.style.setProperty('--headerHeight', '0px');
            }
          }
        } catch (ex) { }
      }
    }
  } else {
    ifrm.setAttribute("src", url);
    ifrm.style.display = "";
  }
  return false;
}

export class ActionHandler {
  static getUserLocationInterval;
  static timeCheckedUserLocation = 0;

  /**
   * The function checks if a click action has a specific type,
   * property, and property value, and if so, it sends a message and clears the consent status options.
   * @param clickAction - The clickAction parameter is an object that contains information about the
   * action that was performed when a consent modal was clicked.
   */
  static handleConsentModalClickAction(clickAction) {
    try {
      if (Util.propertyHasValue(clickAction, "type") && Util.propertyHasValue(clickAction, "property")
        && Util.propertyHasValue(clickAction, "propertyValue")) {
        const type = clickAction.type;
        const property = clickAction.property;
        const propertyValue = clickAction.propertyValue;

        // Check the Company Settings Payload with new click action properties
        if (type === "set_metadata_property" && property === "acceptTermsOfService" && propertyValue == 1) {
          // Send the last message before the consent modal was opened
          const options = store.state.consentStatusOptions;
          if (options !== null) {
            store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
            store.commit(STORE_MUTATION.SetIsTermsOfServiceAccepted, true);
            addUserMessage(options.msg, options.shouldSend, options.hideInChat, options.messageStructure);

            // Save the consent expiration date in local storage
            const expirationInSecs = store.state.termsConsentExpiration;
            const expirationDate = Util.addSecondsToDate(expirationInSecs);
            const expirationDateStr = window.btoa(expirationDate.toString());
            StorageHelper.set(`swc_cnst_dt_${store.state.pageId}`, expirationDateStr);

            // Finally, clear the consent status options
            store.commit(STORE_MUTATION.SetConsentStatusOptions, null);
          }
        }
      }
    } catch (ex2) {
      console.log("[action_handler.ts] An error has occurred \n-> Inside handleBtnClickAction -> Error: ", ex2);
    }
  }

  static handleBtnClickAction(data: MessageStructureComponent, messageContentsettings: MessageContentSettings = {}) {
    __vueEvent.$emit(EVENTS.ButtonClick);
    const removeAllButtons = function () {
      store.commit(STORE_MUTATION.RemoveAllButtons);
    };

	// SD-1837: Add Ability to set back button label when showing IFrame
	let iframeBackButtonLabel;
	try {
		// Check if messageContentsettings is not empty
		if (messageContentsettings != null && Object.keys(messageContentsettings).length !== 0
			&& typeof messageContentsettings.iframeBackButtonLabel !== "undefined") {
			iframeBackButtonLabel = messageContentsettings.iframeBackButtonLabel;
	    }
	} catch (ex) {
		//console.log(ex.message);
	}

    const clickAction = data.clickAction;

    // perform action
    if (clickAction) {


      // send click action
      if (clickAction.postback) {
        const service = new ChatService();
        const msg = clickAction.replyText || data.label;

        if (clickAction.settings && clickAction.settings.passUserLocation == true) {
          getUserLocation();
          store.commit(STORE_MUTATION.SetLocationBlocked, false);
          if (store.state.userLat != "" || store.state.userLng != "" || store.state.locationBlocked == true) {
            addUserMessage(clickAction.replyText || data.label, false, false, data);
          }
          else {
            this.getUserLocationInterval = setInterval(() => {
              if (this.timeCheckedUserLocation >= __locationCheckTimeout) {
                clearInterval(this.getUserLocationInterval);
                this.timeCheckedUserLocation = 0;
                store.commit(STORE_MUTATION.SetLocationBlocked, true);
                addUserMessage(clickAction.replyText || data.label, false, false, data);
              } else {
                if (store.state.userLat != "" || store.state.userLng != "" || store.state.locationBlocked == true) {
                  clearInterval(this.getUserLocationInterval);
                  this.timeCheckedUserLocation = 0;
                  addUserMessage(clickAction.replyText || data.label, false, false, data);
                } else {
                  this.timeCheckedUserLocation += __locationCheckInterval;
                }
              }
            }, __locationCheckInterval);
          }
        } else if (clickAction.postback === CLICK_ACTION_POSTBACK.ClearScreen) {
          if (!store.state.requestResponseReceived) return;
          store.commit(STORE_MUTATION.SetRequestResponseReceived, false);

          store.commit(STORE_MUTATION.SetShowTypingIndicator, true);
          service.sendMessage(data);

        } else if ((clickAction.settings == null || clickAction.settings.showReplyText != false) && !Util.isNullOrEmpty(msg)) {
          store.commit(STORE_MUTATION.SetRequestResponseReceived, true);
          addUserMessage(msg, false, false, data);
        } else {

          if (!store.state.requestResponseReceived) return;
          store.commit(STORE_MUTATION.SetRequestResponseReceived, false);
          service.sendMessage(data);
        }

      }


      // Check click action has url or not.
      if (clickAction.url) {

        if (clickAction.settings == null) {
          clickAction.settings = {
            browserTarget: "sametab"
          };
        }
        // redirect action
        let target = clickAction!.settings.browserTarget;
        const deviceType = Util.getDeviceType();
        if (deviceType == DEVICE_TYPE.Pc || deviceType == DEVICE_TYPE.PcLow) {
          if (clickAction!.settings.browserTargetDesktop != null) {
            target = clickAction!.settings.browserTargetDesktop;
          }
        } else {
          if (clickAction!.settings.browserTargetMobile != null) {
            target = clickAction!.settings.browserTargetMobile;
          }
        }

        createAndRedirectToLink(
          clickAction.url,
          target as any,
          clickAction.type,
          clickAction.settings.hideInChatBackButton as any,
		  iframeBackButtonLabel
        );

        // hide action
        removeAllButtons();
      } else {
        removeAllButtons();
      }

      // SD-3681: Webchat Consent Modal
      // Check if the user has clicked on the accept button inside the consent modal
      this.handleConsentModalClickAction(clickAction);

    } else {
      removeAllButtons();
    }

  }
}
