var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    {
      class: [
        _vm.getClass("inputContainer"),
        _vm.disableInput ? _vm.getClass("hide") : "",
      ],
    },
    [
      _c(
        "transition",
        { attrs: { name: "satisfi_info-panel" } },
        [
          _c("InfoPanel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showInfoPanel,
                expression: "showInfoPanel",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("form", { attrs: { onSubmit: "return false;" } }, [
        _c("span", { class: _vm.getClass("btnGroup") }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showInfoBtn,
                  expression: "showInfoBtn",
                },
              ],
              class:
                _vm.getClass("btn ") +
                _vm.getClass("info ") +
                _vm.getClass("tooltip"),
              attrs: {
                "data-tooltip": "Chat options and preferences",
                "aria-label": "Chat options and preferences",
                tabindex: "0",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.showInfo.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "30",
                    height: "30",
                    xmlns: "http://www.w3.org/2000/svg",
                    alt: "Chat options and preferences",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M14.842 10.561c-.96 0-1.737-.736-1.737-1.645s.778-1.645 1.737-1.645c.96 0 1.737.736 1.737 1.645s-.778 1.645-1.737 1.645zm-2.369 1.496h3.79v9.574h1.264v.598h-5.054v-.598h1.264v-8.975h-1.264v-.599zM15 30C6.716 30 0 23.284 0 15 0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15z",
                      fill: "#B4B4B4",
                      "fill-rule": "evenodd",
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCameraBtn,
                  expression: "showCameraBtn",
                },
              ],
              class:
                _vm.getClass("btn ") +
                _vm.getClass("camera ") +
                _vm.getClass("tooltip"),
              attrs: {
                "data-tooltip": "Upload or take a photo",
                "aria-label": "Upload or take a photo",
                tabindex: "0",
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    height: "30",
                    width: "30",
                    xmlns: "http://www.w3.org/2000/svg",
                    alt: "Upload or take a photo",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "m15 30c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm-7.703-20.26c-.717 0-1.297.542-1.297 1.214v9.83c0 .672.58 1.216 1.297 1.216h15.406c.717 0 1.297-.544 1.297-1.216v-9.83c0-.672-.58-1.215-1.297-1.215h-3.468l-1.65-2.259c-.22-.265-.688-.48-1.045-.48h-3.08c-.355 0-.824.215-1.044.48l-1.65 2.26h-3.47zm12.703 6.26a5 5 0 1 1 -10 0 5 5 0 0 1 10 0zm-2 0a3 3 0 1 0 -6 0 3 3 0 0 0 6 0zm4-3a1 1 0 1 1 0-2 1 1 0 0 1 0 2z",
                      fill: "#b4b4b4",
                      "fill-rule": "evenodd",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.getClass("textareaContainer") }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputData,
                expression: "inputData",
              },
            ],
            ref: "input",
            class: _vm.getClass("textInput ") + _vm.getClass("textAreaInput"),
            style: _vm.inputStyle,
            attrs: {
              "aria-controls": "divChatBody",
              placeholder: _vm.inputPlaceHolder,
              "aria-label": "Ask a question",
              tabindex: "0",
              spellcheck: "false",
              rows: "1",
              autocomplete: "off",
            },
            domProps: { value: _vm.inputData },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                )
                  return null
                $event.preventDefault()
                return _vm.onEnterPressHandler.apply(null, arguments)
              },
              keyup: _vm.sendTypingIndicator,
              focus: _vm.autoResize,
              blur: _vm.onChatTextBoxBlur,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.inputData = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputData,
                expression: "inputData",
              },
            ],
            ref: "shadow",
            class: _vm.getClass("textAreaShadow"),
            attrs: {
              tabindex: "-1",
              "aria-hidden": "true",
              autocomplete: "off",
              "aria-label": "hidden",
            },
            domProps: { value: _vm.inputData },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.inputData = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "sendButton",
            class: _vm.getClass("btn ") + _vm.getClass("send"),
            attrs: {
              type: "button",
              "aria-label": "Send Message",
              tabindex: "0",
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.sendMessage.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "30",
                  height: "35",
                  xmlns: "http://www.w3.org/2000/svg",
                  "aria-hidden": "true",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M15 30C6.716 30 0 23.284 0 15 0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm1.038-19.192l2.213 2.637a.75.75 0 1 0 1.149-.964l-3.535-4.213A.748.748 0 0 0 15.288 8a.748.748 0 0 0-.577.268l-3.536 4.213a.75.75 0 0 0 1.15.964l2.213-2.637v10.445a.75.75 0 1 0 1.5 0V10.808z",
                    fill: "#B4B4B4",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }