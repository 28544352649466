import { MESSAGE_COMPONENT_TYPE } from "../enums";
import { MessageContent } from "../types";
import { isFirefox } from './get-browser-type';
import { isSvgImage } from './get-image-type-from-url';

export const preFetchImage = function (message: MessageContent): Promise<MessageContent> {
    return new Promise(function (res, rej) {
        var imageFound = 0;
        try {


            message.structures.forEach(structure => {
                structure.components.forEach(component => {
                    if (component.type == MESSAGE_COMPONENT_TYPE.Image) {
                        var img = document.createElement('img');
                        img.src = component.imageUrl;
                        ++imageFound;

                        var poll = null;

                        // SD-5252: Check if the current browser is Firefox
                        // and if the image is an SVG image
                        if (isFirefox() && isSvgImage(component.imageUrl)) {
                            // Firefox does not support naturalWidth and naturalHeight for SVG images
                            // so we need to set the dimensions manually
                            img.onload = function () {
                                onImgDimensionComputed();
                                component.style = {
                                    width: img.naturalWidth ? img.naturalWidth : 300,
                                    height: img.naturalHeight ? img.naturalHeight : 150
                                }
                            }
                            // TODO: Remove the fallback size (300x150) when https://bugzilla.mozilla.org/show_bug.cgi?id=1328124 is fixed
                            // Also https://github.com/whatwg/html/issues/3510
                        } else {
                            poll = setInterval(function () {
                                if (img.naturalWidth) {
                                    onImgDimensionComputed();
                                    component.style = {
                                        width: img.naturalWidth,
                                        height: img.naturalHeight
                                    }
                                }
                            }, 10);
                        }

                        var onImgDimensionComputed = function () {
                            clearInterval(poll);
                            --imageFound;
                            if (imageFound == 0) {
                                res(message);
                            }
                        }

                        img.onerror = onImgDimensionComputed;
                    }
                });
            });
            if (imageFound == 0) {
                res(message);
            }
        }
        catch (ex) {
            //@ts-ignore
            res();
        }
    });
}
