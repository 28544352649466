import { store } from "../store/store";
import { Util } from "../util";
import { getQueryStringValue } from "./get_querystring_value";
import { ChatService } from "../service/chat_service";
import { __vueEvent } from "../constant";
import { EVENTS } from "../enums/events";
import { STORE_ACTION } from "../enums/store_action";
import { onExceptionOccured } from "./on_exception_occured";
import { IConfig } from "../interfaces/config";
import { STORE_MUTATION } from "../enums/store_mutation";
import { HttpRequest } from "./http_request";
import { ErrorHelper } from "./error_helper";
import { Errors } from "../enums/errors";
import { getAppUrl } from ".";
import cssVars from "css-vars-ponyfill";
import { deleteCookie } from "./cookie_handler";

//declare var SatisfiApp;
export class ConfigLoader {

    async load() {
        try {
            const service = new ChatService();
            let pageId = getQueryStringValue('pageID');
            let satisfiForceNewSession = getQueryStringValue('satisfiForceNewSession');
            let isHideTitleBar = getQueryStringValue('HideTitleBar');

            if(!Util.isNullOrEmpty(satisfiForceNewSession) && satisfiForceNewSession == "1")
            {
                try{
                    try {
                        localStorage.clear();
                    }
                    catch(ex) {
                        var cookies = document.cookie.split(";");

                        for (var i = 0; i < cookies.length; i++) {
                            var cookie = cookies[i];
                            var eqPos = cookie.indexOf("=");
                            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                        }
                    }
                }
                catch(ex){

                }
            }

            if (!Util.isNullOrEmpty(isHideTitleBar)) {
                store.commit(STORE_MUTATION.SetIsHideTitleBar, (Number(isHideTitleBar) == 1));
            }
            if (Util.isNullOrEmpty(pageId)) {
                const err = new ErrorHelper(Errors.NoPageIdSupplied).errMessage;
                store.commit(STORE_MUTATION.SetErrorMessage, err);
                return;
            }
            store.commit(STORE_MUTATION.SetPageId, pageId);
            const configs: IConfig = await service.getPageConfig(pageId);

            //Send header details to parent of Iframe
            if (store.state.isIFrameLoaded) {
                //Send details to show header
                window.parent.postMessage(
                    {
                        event_id: "onHeaderConfigLoaded",
                        data: {
                            headerImage: configs.headerImage,
                            pageTitle: configs.pageTitle,
                            hidePageTitle: configs.hidePageTitle,
                            pageName: configs.pageName,
                            headerThemeUrl: configs.headerThemeUrl,
                            headerImageDestinationURL: configs.headerImageDestinationURL
                        }
                    },
                    "*"
                );
            }
            store.commit(STORE_MUTATION.SetSatisfiURL, configs.pageURL);
            store.commit(STORE_MUTATION.SetHeaderImage, configs.headerImage);
            store.commit(STORE_MUTATION.SetBackgroundImage, configs.backgroundImage);
            store.commit(STORE_MUTATION.SetHeaderImageDestinationURL, configs.headerImageDestinationURL);

            // SD-3460: New settings for the Admin Login and MFA
            store.commit(STORE_MUTATION.SetWebApiAddress, configs.webApiAddress);
            store.commit(STORE_MUTATION.SetDashboardAddress, configs.dashboardAddress);
            store.commit(STORE_MUTATION.SetAttemptNoofInvalidCode, configs.attemptNoofInvalidCode);
            store.commit(STORE_MUTATION.SetAttemptNoofResendCode, configs.attemptNoofResendCode);
            store.commit(STORE_MUTATION.SetMFAExpiration, configs.mfaExpiration);
            store.commit(STORE_MUTATION.SetMFAEnableDateTime, configs.mfaEnableDateTime);
            store.commit(STORE_MUTATION.SetIsMFAEnabled, configs.mfaEnabled);

            // SD-3681: New store props for WebChat Consent Modal (Terms and Conditions)
            store.commit(STORE_MUTATION.SetIsTermsConsentEnabled, configs.enableTermsConsent);
            store.commit(STORE_MUTATION.SetTermsConsentPayload, configs.termsConsentPayload);
            store.commit(STORE_MUTATION.SetTermsConsentExpiration, configs.termsConsentExpiration);

			// Set all config values from the server
            store.commit(STORE_MUTATION.SetClientVersion, configs.clientVersion || "");
            store.commit(STORE_MUTATION.SetMinimumClientVersion, configs.minimumClientVersion || "");

			// If HideTitleBar was not passed from the querystring,
			// Check in the config if hidePageTitle is true
			if (Util.isNullOrEmpty(isHideTitleBar) && !!configs.hidePageTitle) {
                store.commit(STORE_MUTATION.SetIsHideTitleBar, !!configs.hidePageTitle);
            }

            if (configs.errorMessage.length == 0) {
                await this.setTheme(configs);
                store.dispatch(STORE_ACTION.InitConfig, configs);
                cssVars({ onlyLegacy: true });
                if (document.title.length == 0) {
                    document.title = configs.pageName;
                }
            }
            else {
                await this.setErrorTheme();
                console.log("error");
                const err = configs.errorMessage;
                store.commit(STORE_MUTATION.SetErrorMessage, err);
            }
        }
        catch (ex) {
            store.commit(STORE_MUTATION.SetErrorMessage, "Unable to load page.");
            onExceptionOccured("getWelcomeMsg", ex);
        }
    }

    private async setErrorTheme() {
        const style = document.createElement('style');
        style.innerHTML = `\n:root{--chatFontFamily: 'Open Sans';
        --errorContainerBgColor: #f7f7f7;
        --chatWidth: 375px;
        --errorTextColor: #333;}`;
        style.id = "satisfi_theme";
        document.head.appendChild(style);
    }

    private async setTheme(config: IConfig) {
        const themeUrl = config.themeUrl;
        if (themeUrl && themeUrl.length > 0) {
            let url;
            const appUrl = getAppUrl();
            if (Util.isNullOrEmpty(getQueryStringValue('dev'))) {
                // url = `https://chat.satisfi4.com/Resources/ThemeFiles/${themeUrl}`;
                url = `${store.state.AWSServerURL}${store.state.AWSThemeFilePath}${themeUrl}`;
            }
            else {
                url = "./css/variables.css";
            }

            const request = new HttpRequest(url);
            let themeStyles: string = await request.sendGet({}, {
                Accept: 'text/css'
            });

            // Set the aws service full url here
            const awsFullURL = `${store.state.AWSServerURL}${store.state.AWSImageLogoPath}`;
            const bgImage = config.backgroundImage || '';

            // Set :root level css variables
            const newText = `\n:root {
                --botAvatar: url(${awsFullURL}${config.avatarBotImage});
                --associateAvatar: url(${awsFullURL}${config.avatarHumanImage});
                --headerLogoImage: url(${awsFullURL}${config.headerImage});
                --chatContainerBgImage: ${bgImage ? `url(${awsFullURL}${bgImage})` : `none`}
            }`;

            const style = document.createElement('style');
            style.innerHTML = themeStyles.concat(newText);
            style.id = "satisfi_theme";
            document.head.appendChild(style);
            console.log("SetIsConfigLoaded:true");
            store.commit(STORE_MUTATION.SetIsConfigLoaded, true);
        }
    }
}
