import { Util } from "../util";
import { store } from "../store/store";

/**
 * The function returns an object with an 'Authorization' header containing a bearer token if the
 * authToken is not empty, otherwise it returns an empty object.
 * @returns an object with an 'Authorization' property. If the 'authToken' in the store state is not
 * null or empty, the value of the 'Authorization' property will be 'Bearer ' concatenated with the
 * value of the 'authToken'. Otherwise, an empty object will be returned.
 */
export const getAuthHeader = function () {
  if (!Util.isNullOrEmpty(store.state.authToken)) {
      return { 'Authorization': 'Bearer ' + store.state.authToken };
  } else {
      return {};
  }
};
