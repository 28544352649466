var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { class: _vm.buttonWrapperClass }, [
    _vm.component.style.type == "link"
      ? _c(
          "button",
          {
            class: _vm.getClass("btn ") + _vm.getClass("btnLink "),
            attrs: { type: "button", tabindex: "0" },
            on: { click: _vm.onElClick },
          },
          [_vm._v(_vm._s(_vm.component.label))]
        )
      : _vm.component.style.type == "secondary"
      ? _c(
          "button",
          {
            class:
              _vm.getClass("btn ") +
              _vm.getClass("btnSecondary ") +
              _vm.getClass(_vm.size),
            attrs: { type: "button", tabindex: "0" },
            on: { click: _vm.onElClick },
          },
          [_vm._v(_vm._s(_vm.component.label))]
        )
      : _c(
          "button",
          {
            class:
              _vm.getClass("btn ") +
              _vm.getClass("btnPrimary ") +
              _vm.getClass(_vm.size),
            attrs: { type: "button", tabindex: "0" },
            on: { click: _vm.onElClick },
          },
          [_vm._v(_vm._s(_vm.component.label))]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }